import { Theme } from '@mui/material';
import { styled } from '@mui/system';

import { Container, Item } from 'Components/Grid';
import { border, compactHeight, ellipsis, table, titleCell, valueCell } from './common/commonStyles';

import { TTitle, TValue } from './definitions';
import { take } from 'Utility/ramdaReplacement';

const BigHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDiagnosis' && prop !== 'active',
})(({ theme, isDiagnosis, active }: { theme?: Theme; isDiagnosis: boolean; active: boolean }) => ({
  fontSize: isDiagnosis
    ? theme?.customComponents.tile.fonts.diagnosisText.size
    : theme?.customComponents.tile.fonts.bigText.size,
  color: active
    ? theme?.customComponents.tile.fonts.bigText.color
    : theme?.customComponents.tile.fonts.bigText.secondaryColor,
  fontWeight: 600,
  height: '4.2rem',
  padding: theme?.customComponents.tile.bodyPadding,
}));

const SubheaderContainer = styled(Container)({ marginBottom: '-1rem' });

const Subheader = styled('div')(({ theme }) => ({
  fontSize: theme.customComponents.tile.fonts.smallestText.size,
  color: theme.customComponents.tile.fonts.smallestText.color,
  fontWeight: '400',
}));

const DataTable = styled('table')(table);

const DataRow = styled('tr')({
  height: compactHeight,
  verticalAlign: 'baseline',
});

const DataCellTitle = styled('td')(({ theme }) => ({
  ...titleCell(theme as Theme, false, true),
  borderTop: border(theme as Theme),
}));

const DataCellValue = styled('td', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }: { theme?: Theme; active: boolean }) => ({
  ...valueCell(theme),
  ...ellipsis(20),
  borderTop: border(theme),
  color: active
    ? theme?.customComponents.tile.fonts.normalText.color
    : theme?.customComponents.tile.fonts.normalText.secondaryColor,
}));

const BigHeaderInfo = ({
  isDiagnosis = false,
  active = true,
  bigHeaderText,
  bigHeaderTextSubheader,
  bigHeaderValue,
  bigHeaderValueSubheader,
  data,
}: IBigHeaderInfo) => {
  const itemsCanFitAmount = isDiagnosis ? 5 : 6;
  const shownItems = take(itemsCanFitAmount, data);
  return (
    <>
      {bigHeaderText && (
        <>
          <BigHeader isDiagnosis={isDiagnosis} active={active}>
            {bigHeaderTextSubheader && (
              <SubheaderContainer>
                <Item xs={true}>
                  <Subheader>{bigHeaderTextSubheader}</Subheader>
                </Item>
                {bigHeaderValue && bigHeaderValueSubheader ? (
                  <Item>
                    <Container justifyContent={'flex-end'}>
                      <Item>
                        <Subheader>{bigHeaderValueSubheader}</Subheader>
                      </Item>
                    </Container>
                  </Item>
                ) : null}
              </SubheaderContainer>
            )}
            <Container style={{ height: '100%' }} alignItems="center">
              <Item xs={true}>{bigHeaderText}</Item>
              {bigHeaderValue && (
                <Item>
                  <Container justifyContent={'flex-end'}>
                    <Item>{bigHeaderValue}</Item>
                  </Container>
                </Item>
              )}
            </Container>
          </BigHeader>
        </>
      )}
      <DataTable>
        <tbody>
          {shownItems.map((d) => (
            <DataRow key={d.key}>
              <DataCellTitle>{d.title}</DataCellTitle>
              <DataCellValue active={active}>{d.value || d.value === 0 ? d.value : '-'}</DataCellValue>
            </DataRow>
          ))}
        </tbody>
      </DataTable>
    </>
  );
};

export interface IBigHeaderInfo {
  type: 'bigHeaderInfo';
  isDiagnosis?: boolean;
  active?: boolean;
  bigHeaderText: TTitle | null;
  bigHeaderTextSubheader?: TTitle;
  bigHeaderValue?: TValue;
  bigHeaderValueSubheader?: TTitle;
  data: Array<{ title: TTitle; value?: TValue; key: string }>;
}

export default BigHeaderInfo;
