import * as React from 'react';
import { formatTime, formatPartialDate, exists, sortPartialDate, partialDateToValue } from 'neuro-utils';
import { capitalize } from '../../../utility/string';
import { last } from 'ramda';
import { styled } from '@mui/system';
import colors from '../../../config/theme/colors';
import PlatformConditional from '../../PlatformConditional';
import { defaultUnits } from '../../../routes/Dbs/Document/config';
import { columnHasNumberField } from '../../../routes/Vns/utils';
import { fm } from 'Components/FormatMessage';
import { isRechargeable } from 'Routes/Dbs/utils';
import { path } from 'Utility/ramdaReplacement';

const StyledButton = styled('span')({
  padding: '0 0.5rem',
  cursor: 'pointer',
  color: colors.primary,
  textDecoration: 'underline',
});

const DBSSettingItem = ({ sKey, sValue }: { sKey: string; sValue: string }): React.JSX.Element => {
  return (
    <td style={{ alignItems: 'flex-start' }}>
      {sKey + ': '}
      {sValue === 'plus' ? '+' : sValue === 'minus' ? '-' : sValue === 'registering' ? 'R' : ''}
    </td>
  );
};

const DBSSettingsItems = ({ settings }: { settings: object }): React.JSX.Element => {
  return (
    <React.Fragment>
      {Object.entries(settings).map(([sKey, sValue]) => {
        return (
          <tr key={`${sKey}${sValue}`}>
            <DBSSettingItem sKey={sKey} sValue={sValue} />
          </tr>
        );
      })}
    </React.Fragment>
  );
};

const DBSSetting = ({
  setting,
  side,
  lead,
  selectedProgram,
}: {
  setting: IDBSSetting;
  side: string;
  lead: string;
  selectedProgram?: number;
}): React.JSX.Element => {
  const settings = path(['programs', selectedProgram ?? 0, side.toLowerCase() + 'Settings'], setting) || false;
  const generatorSetting: string | false =
    path(['programs', selectedProgram ?? 0, side.toLowerCase() + 'GeneratorSetting'], setting) || false;
  const amplitudeUnit = path(['programs', selectedProgram ?? 0, 'amplitudeUnit'], setting) || false;
  const amplitude = path(['programs', selectedProgram ?? 0, side.toLowerCase() + 'Amplitude'], setting) || false;
  const pulseWidth = path(['programs', selectedProgram ?? 0, side.toLowerCase() + 'PulseWidth'], setting) || false;
  const rate = path(['programs', selectedProgram ?? 0, side.toLowerCase() + 'Rate'], setting) || false;
  const leadFormatter = (name: string): string | React.JSX.Element =>
    name === 'Medtronic SenSight (0.5 mm Spaced Lead Assembly)'
      ? fm('dbs.medtronicSenSightLead0.5mm')
      : name === 'Medtronic SenSight (1.5 mm Spaced Lead Assembly)'
        ? fm('dbs.medtronicSenSightLead1.5mm')
        : name;
  return (
    <React.Fragment>
      {lead && (
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>
                {fm(`dbs.shellOfGenerator${side}`)}
                {': '}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{generatorSetting ? '+' : 'OFF'}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>
                {fm(`dbs.${side.toLowerCase()}Lead`)}
                {': '}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{lead ? leadFormatter(lead) : ''}</td>
            </tr>
            {settings && <DBSSettingsItems settings={settings as object} />}
            <tr>
              <td>
                <div>
                  {amplitude ? (
                    <React.Fragment>
                      {fm('dbs.amplitude')}
                      {`: ${amplitude} ${amplitudeUnit ? amplitudeUnit : ''}`}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {pulseWidth ? (
                    <React.Fragment>
                      {fm('dbs.pulseWidth')}
                      {`: ${pulseWidth} ${defaultUnits.pulseWidth}`}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {rate ? (
                    <React.Fragment>
                      {fm('dbs.rate')}
                      {`: ${rate} ${defaultUnits.rate}`}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

const Generator = ({
  generators,
  settingDate,
}: {
  generators: Array<IDBSGenerator>;
  settingDate: PartialDate | undefined;
}): React.JSX.Element | null => {
  if (!generators || generators.length === 0) return null;
  if (generators.length === 1) {
    const generator = generators[0];
    return (
      <React.Fragment>
        {generator.generator === 'Other rechargeable' ? (
          fm('dbs.otherRechargeable')
        ) : generator.generator === 'Other battery' ? (
          fm('dbs.otherPrimaryCell')
        ) : (
          <React.Fragment>
            {generator.generator}
            {isRechargeable(generator.generator, 'parkinson') ? (
              <React.Fragment>
                {': '}
                {fm('dbs.rechargeable')}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {': '}
                {fm('dbs.primaryCell')}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  const olderGenerators = generators.filter(
    (generator: IDBSGenerator) => partialDateToValue(generator.generatorDate) <= partialDateToValue(settingDate),
  );
  const generatorOfCurrentSettings = last(
    olderGenerators.sort((g1, g2) => sortPartialDate(g1.generatorDate, g2.generatorDate)),
  );
  const generator = (
    <React.Fragment>
      {generatorOfCurrentSettings?.generator}
      {isRechargeable(generatorOfCurrentSettings?.generator, 'parkinson') ? (
        <React.Fragment>
          {': '}
          {fm('dbs.rechargeable')}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {': '}
          {fm('dbs.primaryCell')}
        </React.Fragment>
      )}
      {generatorOfCurrentSettings?.generatorDate &&
        ` - ${formatPartialDate(generatorOfCurrentSettings?.generatorDate)}`}
    </React.Fragment>
  );
  return generator;
};

export const DBSSettings = ({
  setting,
  generators,
  leadRight,
  leadLeft,
  targetNucleus,
  targetNucleusLeft,
}: {
  setting: IDBSSetting;
  generators: Array<IDBSGenerator>;
  leadRight: any;
  leadLeft: any;
  targetNucleus: any;
  targetNucleusLeft: any;
}): React.JSX.Element => {
  const [selectedProgram, setSelectedProgram] = React.useState<number>(0);

  const interlacing = setting.programs?.[selectedProgram].interlacing;
  const cyclicONTime = setting.programs?.[selectedProgram].cyclicONTime;
  const cyclicOFFTime = setting.programs?.[selectedProgram].cyclicOFFTime;

  return (
    <React.Fragment>
      {targetNucleus && (
        <div>
          <div>
            {fm('dbs.targetNucleus')}
            {': '}
            {targetNucleus}
          </div>
        </div>
      )}
      {targetNucleusLeft && (
        <div>
          <div>
            {fm('dbs.targetNucleusLeft')}
            {': '}
            {targetNucleusLeft}
          </div>
        </div>
      )}
      {Array.isArray(generators) && (
        <div>
          <div style={{ fontWeight: 800 }}>
            {fm('dbs.generator')}
            {': '}
          </div>
          <div>
            <Generator generators={generators} settingDate={setting.generatorAdjustedDate} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: '1rem 0 1rem 0' }}>
            {setting.programs?.map((program: IProgram, index: number) => (
              <div
                key={(program?.programName ?? '') + index}
                style={{
                  display: 'inline-block',
                  width: `${100 / (path(['programs'], setting) as IProgram[]).length}%`,
                }}
              >
                <StyledButton
                  onClick={(): void => setSelectedProgram(index)}
                  style={{ fontWeight: selectedProgram === index ? 600 : 400 }}
                >
                  <span>{program.programName}</span>
                </StyledButton>
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        <table>
          <tbody style={{ verticalAlign: 'top' }}>
            <tr>
              <td>
                <DBSSetting setting={setting} side="Right" lead={leadRight} selectedProgram={selectedProgram} />
              </td>
              <td>
                <DBSSetting setting={setting} side="Left" lead={leadLeft} selectedProgram={selectedProgram} />
              </td>
            </tr>
            <PlatformConditional platform="parkinson">
              <tr>
                <td>
                  {interlacing || interlacing === false ? (
                    <React.Fragment>
                      {fm('dbs.interlacing')}
                      {': '}
                      {fm(`general.${interlacing === true ? 'yes' : 'no'}`)}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            </PlatformConditional>
            <PlatformConditional platform="epilepsy">
              <React.Fragment>
                <tr>
                  <td>
                    {exists(cyclicONTime) ? (
                      <React.Fragment>
                        {fm('dbs.cyclicONTime')}
                        {`: ${cyclicONTime} ${defaultUnits.cyclicONTime}`}
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {exists(cyclicOFFTime) ? (
                      <React.Fragment>
                        {fm('dbs.cyclicOFFTime')}
                        {`: ${cyclicOFFTime} ${defaultUnits.cyclicOFFTime}`}
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              </React.Fragment>
            </PlatformConditional>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const StyledTable = styled('table')({});

const StyledTbody = styled('tbody')({});

const StyledTr = styled('tr')({});

const StyledTd = styled('td')({
  width: 'max-content',
  lineHeight: '2rem',
  paddingBottom: '1rem',
  '&:first-of-type': {
    textAlign: 'left',
  },
});

export const VNSSettings = ({
  data,
  leadType,
  setting,
}: {
  data: IVNS;
  leadType: string | null;
  setting: IVNSSetting;
}): React.JSX.Element => {
  const separateNightSettings = path(['separateNightSettings'], setting);
  const tachycardiaDetection = path(['tachycardiaDetection'], setting);
  const scheduledProgramming = path(['scheduledProgramming'], setting);

  const columns = ['normalMode', 'autoStimMode', 'magnetMode', 'initialization'].filter((column) =>
    column === 'autoStimMode' && tachycardiaDetection !== 'ON'
      ? false
      : column === 'initialization' && scheduledProgramming !== 'yes'
        ? false
        : true,
  );

  const rows = [
    'outputCurrent',
    'rate',
    'pulseWidth',
    'onTime',
    'offTime',
    'efficiencyCycle',
    'autoStimThreshold',
  ].filter((row) => (row === 'autoStimThreshold' && tachycardiaDetection !== 'ON' ? false : true));

  return (
    <React.Fragment>
      <div>
        {setting.generator && (
          <React.Fragment>
            <div style={{ fontWeight: 800 }}>
              {fm('vns.leadType')}
              {': '}
            </div>
            <div>
              {typeof leadType === 'string' &&
              ['unspecifiedLeadWithOneConnector', 'unspecifiedLeadWithTwoConnectors'].includes(leadType)
                ? fm(`vns.opts.leadType.${leadType}`)
                : leadType ?? '-'}
            </div>
            <div style={{ fontWeight: 800 }}>
              {fm('vns.generator')}
              {': '}
            </div>
            <div>
              {data.generators
                ?.map((generator: IVNSGenerator) => {
                  if (generator.id === setting.generator) {
                    return `${generator?.generator} - ${formatPartialDate(generator?.date)}`;
                  }
                  return undefined;
                })
                .filter((g: string | undefined) => g)
                .join('')}
            </div>
            {separateNightSettings === 'yes' && (setting.nightTimeStart || setting.nightTimeEnd) && (
              <React.Fragment>
                <div style={{ fontWeight: 800 }}>
                  {fm('vns.nightTime')}
                  {': '}
                </div>
                <div>{`${setting.nightTimeStart ? formatTime(setting.nightTimeStart) : ' '} - ${
                  setting.nightTimeEnd ? formatTime(setting.nightTimeEnd) : ' '
                }`}</div>
              </React.Fragment>
            )}
            {tachycardiaDetection === 'ON' && setting.heartRateDetectionSetting && (
              <React.Fragment>
                <div style={{ fontWeight: 800 }}>
                  {fm('vns.heartRateDetectionSettingShort')}
                  {': '}
                </div>
                <div>{setting.heartRateDetectionSetting}</div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: '1rem 0 1rem 0' }}>
          <StyledTable>
            <StyledTbody>
              <StyledTr style={{ fontWeight: 800 }}>
                <StyledTd style={{ opacity: '0%', userSelect: 'none' }}>{fm('vns.autoStimThreshold')}</StyledTd>
                {columns?.map((column: string, index: number) => (
                  <StyledTd key={column + index}>{fm(`vns.${column}Short`)}</StyledTd>
                ))}
              </StyledTr>
              {rows.map(
                (row: string, index: number): React.JSX.Element => (
                  <React.Fragment key={`${row}${index}`}>
                    <StyledTr>
                      <StyledTd style={{ paddingBottom: '0rem' }}>{fm(`vns.${row}Unit`)}</StyledTd>
                      {columns.map((column) =>
                        columnHasNumberField(column, row) ? (
                          <StyledTd key={`${row}${capitalize(column)}`} style={{ paddingBottom: '0rem' }}>
                            {path([row, 'default', column], setting) ?? '-'}
                          </StyledTd>
                        ) : (
                          <StyledTd key={`${row}${capitalize(column)}`} style={{ paddingBottom: '0rem' }} />
                        ),
                      )}
                    </StyledTr>
                    {!(scheduledProgramming === 'yes' && row === 'outputCurrent') &&
                      separateNightSettings === 'yes' && (
                        <StyledTr key={`night${row}${index}`}>
                          <StyledTd>- {fm('vns.night')}</StyledTd>
                          {columns.map((column) =>
                            columnHasNumberField(column, row, true) ? (
                              <StyledTd key={`${row}${capitalize(column)}Night`}>
                                {path([row, 'night', column], setting) ?? '-'}
                              </StyledTd>
                            ) : (
                              <StyledTd key={`${row}${capitalize(column)}Night`} />
                            ),
                          )}
                        </StyledTr>
                      )}
                    {row === 'outputCurrent' && scheduledProgramming === 'yes' && (
                      <React.Fragment>
                        {((path([row, 'scheduled'], setting) as []) ?? [{}]).map((_: TAnyObject, i: number) => (
                          <StyledTr key={`${row}Step${i}`}>
                            <StyledTd style={{ paddingBottom: '1rem' }}>
                              - {fm('vns.step')}
                              {` ${i + 1}`}
                            </StyledTd>
                            {columns.map((column) =>
                              columnHasNumberField(column, row) ? (
                                <StyledTd key={`${row}${capitalize(column)}Scheduled`}>
                                  {path([row, 'scheduled', i, column], setting) ?? '-'}
                                </StyledTd>
                              ) : column === 'initialization' ? (
                                <StyledTd key={`${row}${capitalize(column)}Scheduled`}>
                                  <StyledTable>
                                    <StyledTbody>
                                      <StyledTr>
                                        <StyledTd style={{ paddingBottom: '0rem' }}>
                                          {path([row, 'scheduled', i, 'initializationDate'], setting)
                                            ? formatPartialDate(
                                                path([row, 'scheduled', i, 'initializationDate'], setting),
                                              )
                                            : '-'}
                                        </StyledTd>
                                        <StyledTd style={{ paddingBottom: '0rem' }}>
                                          &nbsp;
                                          {path([row, 'scheduled', i, 'initializationTime'], setting)
                                            ? formatTime(path([row, 'scheduled', i, 'initializationTime'], setting))
                                            : ''}
                                        </StyledTd>
                                      </StyledTr>
                                    </StyledTbody>
                                  </StyledTable>
                                </StyledTd>
                              ) : column ? (
                                <StyledTd key={`${row}${capitalize(column)}Scheduled`} />
                              ) : undefined,
                            )}
                          </StyledTr>
                        ))}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ),
              )}
            </StyledTbody>
          </StyledTable>
        </div>
      </div>
    </React.Fragment>
  );
};
