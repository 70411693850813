import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate } from 'neuro-utils';

import { sortDocuments } from '../../../utility/randomUtil';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const edssStepFormatter = (value: number): string => {
  return value !== 0 && value !== 10 ? value.toFixed(1) : value.toString();
};

const NeurologicalStatusAndEDSSDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const lastNeurologicalStatusAndEDSS = (): INeurologicalStatusAndEDSS =>
    documents?.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2))[0];

  const edssStep = lastNeurologicalStatusAndEDSS().edssStep;

  return (
    <>
      <TileContentMaker
        type="bigHeaderInfo"
        bigHeaderText={edssStep || edssStep === 0 ? `${edssStep}` : null}
        bigHeaderTextSubheader={fm('neurologicalStatusAndEDSS.edssStep.title')}
        data={[
          {
            title: fm('general.date'),
            value: isPartialDate(lastNeurologicalStatusAndEDSS().date)
              ? formatPartialDate(lastNeurologicalStatusAndEDSS().date)
              : '',
            key: 'edssdate',
          },
        ]}
      />
      <DescriptionText>
        {typeof edssStep === 'number'
          ? fm(`neurologicalStatusAndEDSS.edssStep.opts.${edssStepFormatter(edssStep)}`)
          : ''}
      </DescriptionText>
    </>
  );
};

interface IOwnProps {
  documents: INeurologicalStatusAndEDSS[];
}

export default NeurologicalStatusAndEDSSDash;
