import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { updrsScoreSum, calculateLevodopaDBS, TDocument, sortAndTakeFirst } from '../utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { sortDocuments } from 'Utility/randomUtil';
import { path } from 'Utility/ramdaReplacement';

const fm = (id: string): React.JSX.Element => <FormattedMessage id={id} />;

const updrsIIIValue = (d: IUPDRSIII) => {
  if (d.testType === 'UPDRS' && path(['retrospectiveData', 0], d) === true) {
    const manualScore = path(['manualScore'], d);
    return manualScore || manualScore === 0 ? `${manualScore}` : '';
  }
  const score = updrsScoreSum(d);
  return score || score === 0 ? `${score}` : '';
};

const UpdrsDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const latestDocuments = [
    sortAndTakeFirst('updrs_v', documents),
    sortAndTakeFirst('updrs_iii', documents),
    sortAndTakeFirst('levodopatest', documents),
    sortAndTakeFirst('dbsEffect', documents),
  ];

  const sortedDocs = (latestDocuments.filter((d) => d) as TDocument[]).sort((n1, n2) =>
    sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2),
  );
  return (
    <TileContentMaker
      type="specificList"
      data={sortedDocs.map((d) => {
        switch (d._type) {
          case 'updrs_v':
            return {
              date: isPartialDate(d?.date) ? formatPartialDate(d.date) : '',
              title: fm('updrs.updrsV'),
              value: (d as IUPDRSV).value,
              key: 'updrs_v',
            };

          case 'updrs_iii':
            return {
              date: isPartialDate(d?.date) ? formatPartialDate(d.date) : '',
              title: (d as IUPDRSIII).testType === 'UPDRS' ? fm('updrs.updrsIII') : fm('updrs.mds'),
              value: updrsIIIValue(d),
              key: 'updrsIII',
            };

          case 'levodopatest':
            return {
              date: isPartialDate(d?.date) ? formatPartialDate(d.date) : '',
              title: fm('updrs.levodopatest'),
              value:
                ((d as ILevodopatest).manualScore ||
                  Math.round(calculateLevodopaDBS(documents, d as ILevodopatest) || 0)) + '%',
              key: 'levodopatest',
            };

          case 'dbsEffect':
            return {
              date: isPartialDate(d?.date) ? formatPartialDate(d.date) : '',
              title: fm('updrs.dbs'),
              value:
                ((d as IDBSEffect).manualScore || Math.round(calculateLevodopaDBS(documents, d as IDBSEffect) || 0)) +
                '%',
              key: 'dbsEffect',
            };

          default:
            return {
              date: isPartialDate(d?.date) ? formatPartialDate(d.date) : '',
              title: fm('updrs.default'),
              key: 'default',
            };
        }
      })}
    />
  );
};

interface IOwnProps {
  documents: TDocument[];
}

export default UpdrsDash;
