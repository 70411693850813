/**
 * Use Reacts own error catching to catch and log errors with components
 */

import * as React from 'react';
import { makeLog } from '../../utility/logger';
import { useIntl } from 'react-intl';

const ErrorScreen = () => {
  const { formatMessage } = useIntl();
  return (
    <div style={{ paddingLeft: '2rem' }}>
      <h2>{formatMessage({ id: 'general.error' })}</h2>
      <div>{formatMessage({ id: 'general.errorInfo' })}</div>
      <div>
        {formatMessage(
          { id: 'general.errorClickToFrontpage' },
          {
            a: (chunks) => {
              return chunks.map((c, i) => (
                <a href={'/'} key={`${c}${i}`}>
                  {c}
                </a>
              )) as unknown as string;
            },
          },
        )}
      </div>
    </div>
  );
};

class ErrorContainer extends React.Component<IOwnProps, IOwnState> {
  state = {
    error: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ error: true });
    makeLog('Error', error, info);
  }

  public render(): React.JSX.Element | React.JSX.Element[] {
    return !this.state.error ? this.props.children : <ErrorScreen />;
  }
}

interface IOwnState {
  error: boolean;
}

interface IOwnProps {
  children: React.JSX.Element | React.JSX.Element[];
}

export default ErrorContainer;
