import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { diagnosticExamCodes, filterDocs, TDocument } from '../utils';
import PlatformConditional from 'Components/PlatformConditional';
import { platforms } from '../../../config/platformSettings';
import { equals } from 'ramda';

const getLatestDocs = (docs: Array<TDocument>): Array<TDocument> | undefined => {
  const respiratoryFailureDiagnosis = filterDocs('diagnosis', docs).filter(
    (d: IDiagnosis) =>
      d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9' || d.diagnosis === 'respiratoryFailureSuspicion',
  );

  const carbonDioxideMeasurementDocs =
    respiratoryFailureDiagnosis && respiratoryFailureDiagnosis.length > 0
      ? filterDocs('carbonDioxideMeasurement', docs)
      : [];

  const sortedArrOfLatest = [
    ...filterDocs('sleepPolygraphy', docs),
    ...filterDocs('sleepStudy', docs),
    ...filterDocs('spirometry', docs),
    ...filterDocs('nightOximetry', docs),
    ...carbonDioxideMeasurementDocs,
  ]
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse();
  return sortedArrOfLatest;
};

const getTitle = (type: string): React.JSX.Element => <FormattedMessage id={`clpAndCnpTests.${type}`} />;

const ClpAndCnpTestsDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const sortedDocs = getLatestDocs(documents);
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const diagnosticExamDocs = documents.filter((d) => d._type === 'diagnosticExamination') as IDiagnosticExamination[];
  const cnpTileDiagnosticExams = diagnosticExamDocs.filter((d) =>
    Object.values(diagnosticExamCodes).some((ec) => equals(ec, d.code)),
  );

  return sortedDocs ? (
    <>
      <PlatformConditional platform={platforms.filter((p) => p !== 'mgravis')}>
        <TileContentMaker
          type="recentList"
          data={sortedDocs.map((d) => ({
            date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
            title: getTitle(d._type),
            key: d._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform={'mgravis'}>
        <TileContentMaker
          type="recentList"
          data={cnpTileDiagnosticExams.map((d) => ({
            date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
            title: fm(`clpAndCnpTests.mgravis.${d?.code?.code}`),
            value: fm(`clpAndCnpTests.mgravis.opts.${d?.finding?.type}`),
            key: d._id,
          }))}
        />
      </PlatformConditional>
    </>
  ) : (
    <></>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ClpAndCnpTestsDash;
