import * as React from 'react';
import { sortPartialDate, formatPartialDate, isPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { fm } from 'Components/FormatMessage';
import { headerText } from 'Utility/ninmtUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { FormattedMessage } from 'react-intl';

const TDCSData = ({ document }: { document: ITDCS }): React.JSX.Element => {
  const { sessions, unusedSessions, otherEvents } = document;

  const allEvents = [...(sessions ?? []), ...(unusedSessions ?? []), ...(otherEvents ?? [])];

  const getEventType = (
    e: (typeof allEvents)[0],
  ): 'session' | 'unusedSession' | ITDCSOtherEvent['type'] | undefined => {
    if (sessions?.some((s) => s.id === e.id)) return 'session';
    if (unusedSessions?.some((s) => s.id === e.id)) return 'unusedSession';
    if (otherEvents?.some((s) => s.id === e.id)) return (e as ITDCSOtherEvent).type;
    return undefined;
  };

  const sessionCount = Array.isArray(sessions) ? sessions.length : '-';

  return (
    <React.Fragment>
      <TileContentMaker
        type="bigHeaderInfo"
        bigHeaderText={
          document?.type
            ? fm(`tdcs.opts.${document?.type}`)
            : !document.hasEnded
              ? fm('tdcs.tdcsOngoing')
              : fm('tdcs.tdcsHasEnded')
        }
        bigHeaderValue={headerText(document)}
        data={
          document.sessions && Array.isArray(sessions) && document?.hasEnded
            ? [
                {
                  title: fm('tdcs.sessionsAndProtocols'),
                  value: <div style={{ whiteSpace: 'pre-line' }}>{sessionCount}</div>,
                  key: 'sessions',
                },
              ]
            : []
        }
      />
      {!document?.hasEnded && (
        <TileContentMaker
          type="specificList"
          itemsCanFitAmount={5}
          data={allEvents.map((e) => ({
            date: isPartialDate(e.date) ? formatPartialDate(e.date) : '',
            title: getEventType(e) ? fm(`tdcs.opts.eventTypes.${getEventType(e)}`) : fm('tdcs.session'),
            value: null,
            key: e.id,
          }))}
        />
      )}
    </React.Fragment>
  );
};

const TDCSDash = ({ documents }: IOwnProps): React.JSX.Element | undefined => {
  const sortedArr = documents
    ?.filter((d) => d._type === 'tdcs')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const sortedNinmtTreatmentPeriod = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const latestTDCS = sortedArr.find((d) => !d.hasEnded) ?? sortedArr?.[0];

  return (
    <React.Fragment>
      {sortedArr?.length > 0 && sortPartialDate(sortedNinmtTreatmentPeriod?.[0]?.date, latestTDCS.date) === 1 ? (
        <DescriptionText>
          <FormattedMessage id="tdcs.noActiveTreatmentPeriod" />
        </DescriptionText>
      ) : (
        <TDCSData document={latestTDCS} />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<ITDCS>;
}

export default TDCSDash;
