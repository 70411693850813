import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { ellipsis } from 'Components/DashboardTile/TileContentMaker/components/common/commonStyles';

const Treatment = ({ d }: { d: ISurgicalTreatment }): React.JSX.Element => (
  <React.Fragment>
    <div>
      {d?.operations?.[0] ? (
        <>
          {d.operations?.[0]?.typeOfOperation ? (
            <FormattedMessage
              id={`surgicalTreatment.operation.typeOfOperation.opts.${d.operations[0].typeOfOperation}`}
            />
          ) : (
            '-'
          )}
        </>
      ) : (
        '-'
      )}
      {d?.operations?.[1] ? (
        <>
          {' / '}
          {d.operations?.[1]?.typeOfOperation ? (
            <FormattedMessage
              id={`surgicalTreatment.operation.typeOfOperation.opts.${d.operations[1].typeOfOperation}`}
            />
          ) : (
            '-'
          )}
        </>
      ) : (
        ''
      )}
    </div>
  </React.Fragment>
);

const EngelClassification = ({ d }: { d?: IEngelClassification }): React.JSX.Element => (
  <React.Fragment>
    <div>
      {d ? (
        <>
          <div>
            {d.date ? <div>{formatPartialDate(d.date)}</div> : ''}
            {d.classification ? (
              <div style={ellipsis(25) as React.CSSProperties}>
                <FormattedMessage
                  id={`surgicalTreatment.engelClassification.class${d.classification.slice(1)}.opts.${
                    d.classification
                  }`}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        '-'
      )}
    </div>
  </React.Fragment>
);

const SurgicalTreatmentDash = ({ documents }: ISurgicalTreatmentDashProps): React.JSX.Element => {
  const lastSurgicalTreatment = (documents ?? [])
    .filter((d) => d._type === 'surgicalTreatment' && d?.date)
    .sort((a, b) => sortPartialDate(a.date, b.date))
    .reverse()?.[0];

  return (
    <TileContentMaker
      type="bigHeaderInfo"
      bigHeaderText={<Treatment d={lastSurgicalTreatment} />}
      bigHeaderTextSubheader={<FormattedMessage id={'surgicalTreatment.operation.operation'} />}
      data={[
        {
          title: <FormattedMessage id="general.date" />,
          value: isPartialDate(lastSurgicalTreatment?.date) ? formatPartialDate(lastSurgicalTreatment?.date) : null,
          key: 'date',
        },
        {
          title: <FormattedMessage id={'surgicalTreatment.engelClassification.title'} />,
          value: <EngelClassification d={lastSurgicalTreatment?.engelClassification?.[0]} />,
          key: 'engel',
        },
      ]}
    />
  );
};

interface ISurgicalTreatmentDashProps {
  documents: Array<ISurgicalTreatment>;
}

export default SurgicalTreatmentDash;
