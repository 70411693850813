import * as React from 'react';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import RenderPromise from './RenderAsync';
import { formatDate } from 'Components/DashboardGraph/Utils/';
import { GraphTooltipDescription, GraphTooltipTitle } from './components';
import { sortDate } from 'neuro-utils';
import { styled } from '@mui/material';

const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />,
  {
    shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'width',
  },
)(({ maxWidth, width }: { maxWidth: string; width?: string }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    fontSize: '1.4rem',
    color: 'black',
    border: '1px solid',
    borderColor: 'gray',
    padding: '1.2rem',
    width: width ?? 'auto',
    maxWidth: maxWidth,
    boxShadow: '1px 1px 2px -1px rgba(13, 18, 76, 0.55)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    '&:before': {
      border: '1px solid',
      borderColor: 'gray',
      boxShadow: '2px 2px 3px -2px rgba(13, 18, 76, 0.3)',
    },
  },
}));

function HtmlTooltip(props: TooltipProps & { maxwidth: string; width?: string }): React.JSX.Element {
  const { width, maxwidth, ...toolTipProps } = props;
  return (
    <StyledTooltip
      arrow={true}
      style={{ cursor: 'default' }}
      PopperProps={{
        onClick: (e: Event) => {
          e.stopPropagation();
        },
        onMouseDown: (e: Event) => {
          e.stopPropagation();
        },
      }}
      width={width}
      maxWidth={maxwidth}
      {...toolTipProps}
    />
  );
}

const dateToString = (date?: Date, start?: Date, end?: Date): string => {
  if (date) {
    const formattedDate = formatDate(date);
    return formattedDate || '';
  }
  if (start || end) {
    if (start && end)
      return `${formatDate(start.getTime() <= end.getTime() ? start : end)} – ${formatDate(
        end.getTime() >= start.getTime() ? end : start,
      )}`;
    if (start) return `${formatDate(start)} –> `;
    if (end) return `–> ${formatDate(end)}`;
  }
  return '';
};
const descriptionIsTable = (description: IGraphTooltipProps['description']): boolean =>
  Array.isArray(description) && description.length > 0 ? true : false;

export const MultipleGraphTooltip = ({
  data,
  content,
  alternativeDateString,
}: IMultipleGraphTooltipProps): React.JSX.Element => {
  const sortedData = data.sort((n1, n2) => sortDate(n1.date, n2.date) || sortDate(n1.start, n2.start));
  const promises = sortedData.map((d) => Promise.all([d.title, d.description]));

  return (
    <RenderPromise promiseValue={Promise.all(promises)}>
      {(values): React.JSX.Element => {
        const resolvedValues = !values.pending && !values.error && Array.isArray(values.value) ? values.value : [];
        return (
          <HtmlTooltip
            style={{ cursor: 'pointer' }}
            title={
              <div
                style={{
                  maxHeight: '30vh',
                  overflowY: 'auto',
                }}
              >
                {resolvedValues.map((v, i) => {
                  const singleData: undefined | TMultipleData = sortedData?.[i] as undefined | TMultipleData;
                  return (
                    <div
                      key={i}
                      style={{
                        paddingBottom: singleData?.description ? '0.1rem' : '0.2rem',
                      }}
                    >
                      {singleData?.name && <div style={{ fontSize: '1.6rem' }}>{singleData.name}</div>}
                      {((Array.isArray(v) && v[0]) ||
                        dateToString(singleData?.date, singleData?.start, singleData?.end).length > 0) && (
                        <GraphTooltipTitle
                          title={v[0]}
                          date={
                            alternativeDateString
                              ? alternativeDateString
                              : dateToString(singleData?.date, singleData?.start, singleData?.end)
                          }
                          name={singleData?.name}
                          descriptionIsTable={descriptionIsTable(v[1])}
                        />
                      )}
                      {
                        Array.isArray(v) && v[1] && (
                          <GraphTooltipDescription convertedData={v[1]} />
                        ) /* Dont render empty div */
                      }
                      {i < data.length - 1 && <hr />}
                    </div>
                  );
                })}
              </div>
            }
            placement="top"
            maxwidth="60rem"
          >
            {content}
          </HtmlTooltip>
        );
      }}
    </RenderPromise>
  );
};

export const GraphTooltip = ({
  title,
  date,
  start,
  end,
  description,
  name,
  content,
  alternativeDateString,
  hideValue,
}: IGraphTooltipProps): React.JSX.Element => {
  return (
    <RenderPromise promiseValue={Promise.all([title, description])}>
      {(values): React.JSX.Element => (
        <HtmlTooltip
          style={{ cursor: 'pointer' }}
          title={
            <div
              style={{
                maxHeight: '30vh',
                overflowY: 'auto',
                paddingBottom: description ? '0.1rem' : '0.2rem',
              }}
            >
              {name && <div style={{ fontSize: '1.6rem' }}>{name}</div>}
              {((Array.isArray(values.value) && values.value?.[0]) || dateToString(date, start, end).length > 0) &&
                !hideValue && (
                  <GraphTooltipTitle
                    title={values.value?.[0]}
                    date={alternativeDateString ? alternativeDateString : dateToString(date, start, end)}
                    name={name}
                    descriptionIsTable={descriptionIsTable(values.value?.[1])}
                  />
                )}
              {
                Array.isArray(values.value) && values.value[1] && (
                  <GraphTooltipDescription convertedData={values.value[1]} />
                ) /* Dont render empty div */
              }
              {hideValue && <div>{alternativeDateString ? alternativeDateString : dateToString(date, start, end)}</div>}
            </div>
          }
          placement="top"
          maxwidth="60rem"
        >
          {content}
        </HtmlTooltip>
      )}
    </RenderPromise>
  );
};

type TMultipleData = Omit<IGraphTooltipProps, 'content' | 'alternativeDateString'>;

interface IMultipleGraphTooltipProps {
  data: Array<TMultipleData>;
  content: React.JSX.Element;
  alternativeDateString?: string;
}
interface IGraphTooltipProps {
  title: string;
  date?: Date;
  start?: Date;
  end?: Date;
  description: any;
  name?: string;
  content: React.JSX.Element;
  alternativeDateString?: string;
  hideValue?: boolean;
}
