import * as React from 'react';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { fm } from 'Components/FormatMessage';
import { formatPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { ISpecificNoDateData } from 'Components/DashboardTile/TileContentMaker/components/SpecificListNoDate';
import { getPreviousDocWithTreatments } from '../utils';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';

const DoctorsOrdersDash = ({ documents }: IDoctorsOrdersDash): React.JSX.Element => {
  const d: IDoctorsOrder | undefined = (
    (documents ?? []).filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[]
  ).sort((a, b) => sortPartialDate(b.date, a.date))?.[0];
  const a: IDoctorsOrderAdditional | undefined = (
    (documents ?? []).filter((d) => d._type === 'doctorsOrdersAdditional') as IDoctorsOrderAdditional[]
  )?.[0];

  const doctorsOrders = ((documents ?? []).filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[]).sort((a, b) =>
    sortPartialDate(b.date, a.date),
  );

  const latestTreatmentPeriodDoc: ININMTTreatmentPeriod | undefined = (
    (documents ?? []).filter((d) => d._type === 'ninmtTreatmentPeriod') as ININMTTreatmentPeriod[]
  ).sort((a, b) => sortPartialDate(b.date, a.date))?.[0];

  const doctorsOrdersInCurrentTreatmentPeriod = doctorsOrders.filter(
    (d) => sortPartialDate(d.date, latestTreatmentPeriodDoc?.date) !== -1,
  );

  const docDateCheck = !!(d && d.date && latestTreatmentPeriodDoc && latestTreatmentPeriodDoc.date);
  const additionalDocsCheck =
    a && a.additionalDocuments && Array.isArray(a.additionalDocuments) && a.additionalDocuments.length > 0;
  const additionalQuestionnairesCheck =
    a &&
    a.additionalQuestionnaires &&
    Array.isArray(a.additionalQuestionnaires) &&
    a.additionalQuestionnaires.length > 0;

  // Orders that has not been "completed", meaning that orders that have not been signed as rea
  // Used to show amount of orders that have not been signed
  const uncompletedOrders = doctorsOrdersInCurrentTreatmentPeriod.filter((d) => !d.completed);

  // Get previous document that has treatement protocol defined, rtns
  const previousDocWithSOTrtms = getPreviousDocWithTreatments(documents, 'rtms', 'subjectOfTreatment');

  // Get previous document that has treatement protocol defined, tdcs
  const previousDocWithSOTtdcs = getPreviousDocWithTreatments(documents, 'tdcs', 'subjectOfTreatment');

  // Object for additional documents
  const additionalDocuments = {
    title: fm('doctorsOrders.usedAdditionalDocuments'),
    value: additionalDocsCheck ? (
      <React.Fragment>
        {a.additionalDocuments?.map((t, i, arr) => (
          <span key={t}>
            {fm(`doctorsOrders.additionalDocuments.documentsUsed.opts.${t}`)}
            {i < arr.length - 1 ? ', ' : ''}
          </span>
        ))}
      </React.Fragment>
    ) : (
      '-'
    ),
    key: 'additionalDocuments',
  };
  // Object for additional questionnaires
  const additionalQuestionnaires = {
    title: fm('doctorsOrders.additionalDocuments.questionnairesUsed.title'),
    value: additionalQuestionnairesCheck ? (
      <>
        {a.additionalQuestionnaires?.map((t, i, arr) => (
          <span key={t}>
            {fm(`doctorsOrders.additionalDocuments.questionnairesUsed.opts.${t}`)}
            {i < arr.length - 1 ? ', ' : ''}
          </span>
        ))}
      </>
    ) : (
      '-'
    ),
    key: 'additionalQuestionnaires',
  };

  let tileData: ISpecificNoDateData[] = [];

  // If doctor's orders document exists
  if (docDateCheck && partialDateToValue(d.date) >= partialDateToValue(latestTreatmentPeriodDoc.date)) {
    tileData = [
      {
        title: fm('doctorsOrders.latestOrder'),
        value: d.date ? formatPartialDate(d.date) : '-',
        key: 'date',
      },
      ...(Array.isArray(d.treatmentType) && d.treatmentType.length > 0
        ? [
            {
              title: fm('doctorsOrders.treatmentProtocol'),
              value:
                sortPartialDate(previousDocWithSOTrtms?.date, previousDocWithSOTtdcs?.date) === 1
                  ? previousDocWithSOTrtms?.date
                    ? formatPartialDate(previousDocWithSOTrtms?.date)
                    : '-'
                  : previousDocWithSOTtdcs?.date
                    ? formatPartialDate(previousDocWithSOTtdcs?.date)
                    : '-',
              key: 'treatmentProtocol',
            },
            {
              title: fm('doctorsOrders.uncompletedOrders'),
              value: uncompletedOrders ? uncompletedOrders.length : '-',
              key: 'uncompletedOrders',
            },
          ]
        : []),
      additionalDocuments,
      additionalQuestionnaires,
    ];
  } else if (additionalDocsCheck || additionalQuestionnairesCheck) {
    // If there is no doctor's order document but additional documents have been defined
    tileData = [additionalDocuments, additionalQuestionnaires];
  }

  return (
    <>
      <TileContentMaker type="specificListNoDate" compact data={tileData} />
      {docDateCheck && partialDateToValue(d.date) < partialDateToValue(latestTreatmentPeriodDoc.date) ? (
        <DescriptionText>{fm('doctorsOrders.noOrdersCurrentPeriod')}</DescriptionText>
      ) : undefined}
    </>
  );
};

interface IDoctorsOrdersDash {
  documents: Array<IDoctorsOrder | IDoctorsOrderAdditional | ININMTTreatmentPeriod>;
}

export default DoctorsOrdersDash;
