import { useState, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PlatformConditional from '../../../components/PlatformConditional';

import { formatPartialDate } from 'neuro-utils';
import { getDescription } from '../../../utility/randomUtil';

import { sortAndTakeMany, filterEnded, TDocument } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { isPartialDate } from 'neuro-utils';
import { TileSubheader } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { patientHasRespiratoryFailureDiagnosis, patientHasSleepApneaDiagnosis } from 'Routes/Treatment/utils';

const fm = (id: string, values?: { [key: string]: string | number | null }) => (
  <FormattedMessage id={id} values={values || undefined} />
);

const ComorbData = ({ doc }: { doc: IComorbidity }) => {
  const [icd10description, setIcd10description] = useState<string | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;
    if (doc.classification?.icd10code) {
      getDescription(doc.classification.icd10code)
        .then((d) => {
          if (isMounted) {
            setIcd10description(d);
          }
        })
        .catch(() => {
          return;
        });
    }
    return (): void => {
      isMounted = false;
    };
  }, [doc.classification?.icd10code]);

  return (
    <div
      style={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '29rem',
      }}
    >
      <span style={{ fontWeight: 600 }}>{doc.classification?.icd10code}</span>
      {icd10description ? <span style={{ fontWeight: 400, fontSize: '1.3rem' }}>{` - ${icd10description}`}</span> : ''}
    </div>
  );
};

const Hospitalization = ({ doc }: { doc: IHospitalization }) => {
  const [icd10description, setIcd10description] = useState<string | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;
    if (doc.classification?.icd10code) {
      getDescription(doc.classification?.icd10code)
        .then((d: string) => {
          if (isMounted) {
            setIcd10description(d);
          }
        })
        .catch(() => {
          return;
        });
    }
    return (): void => {
      isMounted = false;
    };
  }, [doc.classification]);

  return (doc.hospitalizationType === 'planned' || doc.hospitalizationType === 'acute') && doc.classification ? (
    <div
      style={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '18rem',
      }}
    >
      <span style={{ fontWeight: 600 }}>{doc.classification?.icd10code}</span>
      {icd10description ? (
        <span style={{ fontWeight: 400, fontSize: '1.3rem', overflow: 'hidden' }}>{` - ${icd10description}`}</span>
      ) : (
        ''
      )}
    </div>
  ) : (
    <></>
  );
};

const ComorbidityDash = ({ documents }: IOwnProps) => {
  const diagnosis =
    documents && documents.length > 0 && documents.filter((d): d is IDiagnosis => d._type === 'diagnosis');
  const respiratoryFailureDiagnosis = patientHasRespiratoryFailureDiagnosis(diagnosis || []);
  const sleepApneaDiagnosis = patientHasSleepApneaDiagnosis(diagnosis || []);

  const latestSickLeave =
    sortAndTakeMany('sickLeave', documents)?.length > 0 && (sortAndTakeMany('sickLeave', documents)[0] as ISickLeave);

  const allComorbs =
    sortAndTakeMany('comorbidity', documents)?.length > 0 &&
    (sortAndTakeMany('comorbidity', documents) as IComorbidity[]);

  // SMA
  const comorbiditiesSMA = sortAndTakeMany('comorbidity', filterEnded(documents), 2) as IComorbidity[];
  const comorbiditiesSMALength = sortAndTakeMany('comorbidity', filterEnded(documents)).length;

  // Epilepsy
  const comorbiditiesEpilepsy = sortAndTakeMany('comorbidity', filterEnded(documents), 1) as IComorbidity[];
  const comorbiditiesEpilepsyLength = sortAndTakeMany('comorbidity', filterEnded(documents)).length;
  const latestOngoingPregnancy =
    sortAndTakeMany('pregnancy', filterEnded(documents))?.length > 0 &&
    (sortAndTakeMany('pregnancy', filterEnded(documents))[0] as IPregnancy);
  const latestOngoingContraception =
    sortAndTakeMany('contraception', filterEnded(documents))?.length > 0 &&
    (sortAndTakeMany('contraception', filterEnded(documents))[0] as IContraception);

  // MS
  const comorbiditiesMS = sortAndTakeMany('comorbidity', filterEnded(documents)) as IComorbidity[];

  // NINMT
  const comorbiditiesNINMT = sortAndTakeMany('comorbidity', filterEnded(documents)) as IComorbidity[];
  const comorbiditiesTreatedWithNinmt = comorbiditiesNINMT.filter((comorbity) => comorbity.ninmtSymptom);
  // If there are more than diagnoses treated with ninmt, cap shown to 3
  const comorbiditiesTreatedWithNinmtMaxShown = comorbiditiesTreatedWithNinmt.slice(0, 3);
  const comorbiditiesTreatedWithoutNinmt = comorbiditiesNINMT.filter((comorbity) => !comorbity.ninmtSymptom);

  // S&R
  const comorbiditiesRespFailure =
    allComorbs &&
    allComorbs.filter((c) => c.sicknessEffectOnRespiratoryFailure === 'respiratoryFailureReason').slice(0, 3);

  const comorbiditiesSleepApnea = sortAndTakeMany('comorbidity', filterEnded(documents), 3) as IComorbidity[];
  const comorbiditiesSleepApneaLength = sortAndTakeMany('comorbidity', filterEnded(documents)).length;

  // Mgravis
  const hospitalWardDocs = (sortAndTakeMany('hospitalization', documents) as IHospitalization[])?.filter(
    (h) =>
      h?.classification?.icd10code === 'G70' && h?.hospitalizationType === 'acute' && h?.department === 'hospitalWard',
  );
  const intensiveCareDocs = (sortAndTakeMany('hospitalization', documents) as IHospitalization[])?.filter(
    (h) =>
      h?.classification?.icd10code === 'G70' && h?.hospitalizationType === 'acute' && h?.department === 'intensiveCare',
  );

  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <PlatformConditional platform="ms">
        <TileContentMaker
          type="recentList"
          data={comorbiditiesMS.map((c) => ({
            date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
            title: <ComorbData doc={c} />,
            key: c._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform={['sma', 'dmd']}>
        <TileContentMaker
          type="listWithSubheaders"
          data={{
            [formatMessage({ id: 'comorbidity.comorbidities' })]: {
              type: 'recentList',
              documentsLength: comorbiditiesSMALength > 0 ? comorbiditiesSMALength : null,
              data: comorbiditiesSMA.map((c) => ({
                date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                title: <ComorbData doc={c} />,
                key: c._id,
              })),
            },
            [formatMessage({ id: 'comorbidity.latestHospitalization' })]: {
              type: 'specificList',
              data: (sortAndTakeMany('hospitalization', documents, 1) as IHospitalization[])?.map((h) => ({
                date: isPartialDate(h.date) ? formatPartialDate(h.date) : '',
                title: <Hospitalization doc={h} />,
                endDate: isPartialDate(h.endDate) ? formatPartialDate(h.endDate) : null,
                key: h._id,
              })),
            },
          }}
        />
      </PlatformConditional>
      <PlatformConditional platform="epilepsy">
        <>
          <TileContentMaker
            type="listWithSubheaders"
            data={{
              [formatMessage({ id: 'comorbidity.comorbidities' })]: {
                type: 'recentList',
                documentsLength: comorbiditiesEpilepsyLength > 0 ? comorbiditiesEpilepsyLength : null,
                data: comorbiditiesEpilepsy.map((c) => ({
                  date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                  title: <ComorbData doc={c} />,
                  key: c._id,
                })),
              },
              ...(latestOngoingPregnancy && {
                [formatMessage({ id: 'comorbidity.activePregnancy' })]: {
                  type: 'specificList',
                  data: [
                    {
                      date: isPartialDate(latestOngoingPregnancy.date)
                        ? formatPartialDate(latestOngoingPregnancy.date)
                        : '',
                      title: formatMessage({ id: 'comorbidity.pregnancy' }),
                      key: 'pregnancy',
                    },
                  ],
                },
              }),
            }}
          />
          {latestOngoingContraception && (
            <>
              <TileSubheader title={formatMessage({ id: 'comorbidity.latestMethodOfContraception' })} />
              <TileContentMaker
                type="specificListNoDate"
                data={[
                  {
                    title: formatMessage({ id: 'comorbidity.methodOfContraception' }),
                    value: latestOngoingContraception.contraceptiveClassification && (
                      <Fragment>
                        <span>
                          {fm(
                            `comorbidity.opts.contraceptiveClassification.${latestOngoingContraception.contraceptiveClassification}`,
                          )}
                        </span>
                        {latestOngoingContraception.contraceptive && (
                          <span>
                            : {fm(`comorbidity.opts.contraceptive.${latestOngoingContraception.contraceptive}`)}
                          </span>
                        )}
                      </Fragment>
                    ),
                    key: 'methodOfContraception',
                  },
                ]}
              />
            </>
          )}
        </>
      </PlatformConditional>
      <PlatformConditional platform="sleepApnea">
        <TileContentMaker
          type="listWithSubheaders"
          data={{
            ...(respiratoryFailureDiagnosis && comorbiditiesRespFailure
              ? {
                  [formatMessage({ id: 'comorbidity.comorbiditiesThatCauseRespiratoryFailure' })]: {
                    type: 'recentList',
                    documentsLength: comorbiditiesRespFailure.length > 0 ? comorbiditiesRespFailure.length : null,
                    data: comorbiditiesRespFailure.map((c) => ({
                      date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                      title: <ComorbData doc={c} />,
                      key: c._id,
                    })),
                  },
                }
              : {}),
            ...(sleepApneaDiagnosis && !respiratoryFailureDiagnosis && comorbiditiesSleepApnea
              ? {
                  [formatMessage({ id: 'comorbidity.comorbidities' })]: {
                    type: 'recentList',
                    documentsLength: comorbiditiesSleepApneaLength > 0 ? comorbiditiesSleepApneaLength : null,
                    data: comorbiditiesSleepApnea.map((c) => ({
                      date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                      title: <ComorbData doc={c} />,
                      key: c._id,
                    })),
                  },
                }
              : {}),
            ...(latestSickLeave
              ? {
                  [formatMessage({ id: 'comorbidity.latestSickLeave' })]: {
                    type: 'specificList',
                    data: [
                      {
                        date: isPartialDate(latestSickLeave.date) ? formatPartialDate(latestSickLeave.date) : '',
                        endDate: isPartialDate(latestSickLeave.endDate)
                          ? formatPartialDate(latestSickLeave.endDate)
                          : null,
                        title: fm(`comorbidity.opts.reasonForSickLeave.${latestSickLeave.reasonForSickLeave}`),
                        key: latestSickLeave._id,
                      },
                    ],
                  },
                }
              : {}),
          }}
        />
      </PlatformConditional>
      <PlatformConditional platform={'ninmt'}>
        <TileContentMaker
          type="listWithSubheaders"
          data={{
            [formatMessage({ id: 'comorbidity.diagnosesTreatedWithNinmt' })]: {
              type: 'recentList',
              documentsLength: comorbiditiesTreatedWithNinmt.length > 0 ? comorbiditiesTreatedWithNinmt.length : null,
              data:
                comorbiditiesTreatedWithNinmt.length > 3 && comorbiditiesTreatedWithoutNinmt.length > 0
                  ? comorbiditiesTreatedWithNinmtMaxShown.map((c) => ({
                      date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                      title: <ComorbData doc={c} />,
                      key: c._id,
                    }))
                  : comorbiditiesTreatedWithNinmt.map((c) => ({
                      date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                      title: <ComorbData doc={c} />,
                      key: c._id,
                    })),
            },
            [formatMessage({ id: 'comorbidity.otherDiagnoses' })]: {
              type: 'recentList',
              documentsLength:
                comorbiditiesTreatedWithoutNinmt.length > 0 ? comorbiditiesTreatedWithoutNinmt.length : null,
              data: comorbiditiesTreatedWithoutNinmt.map((c) => ({
                date: isPartialDate(c.date) ? formatPartialDate(c.date) : '',
                title: <ComorbData doc={c} />,
                key: c._id,
              })),
            },
          }}
        />
      </PlatformConditional>
      <PlatformConditional platform={'mgravis'}>
        <TileContentMaker
          type="specificListMultipleRows"
          itemsCanFitAmount={4}
          data={[
            ...(hospitalWardDocs && hospitalWardDocs.length > 0
              ? [
                  {
                    date: isPartialDate(hospitalWardDocs?.[0]?.date)
                      ? formatPartialDate(hospitalWardDocs?.[0]?.date)
                      : '',
                    endDate: isPartialDate(hospitalWardDocs?.[0]?.endDate)
                      ? formatPartialDate(hospitalWardDocs?.[0]?.endDate)
                      : '',
                    title: fm('comorbidity.mgravis.latestHospitalWard'),
                    key: 'latestHospitalWard',
                  },
                  {
                    title: fm('comorbidity.mgravis.totalHospitalWards'),
                    value: hospitalWardDocs.length,
                    key: 'hospitalWardTotal',
                  },
                ]
              : []),
            ...(intensiveCareDocs && intensiveCareDocs.length > 0
              ? [
                  {
                    date: isPartialDate(intensiveCareDocs?.[0]?.date)
                      ? formatPartialDate(intensiveCareDocs?.[0]?.date)
                      : '',
                    endDate: isPartialDate(intensiveCareDocs?.[0]?.endDate)
                      ? formatPartialDate(intensiveCareDocs?.[0]?.endDate)
                      : '',
                    title: fm('comorbidity.mgravis.latestIntensiveCare'),
                    key: 'latestIntensiveCare',
                  },
                  {
                    title: fm('comorbidity.mgravis.totalIntensiveCares'),
                    value: intensiveCareDocs.length,
                    key: 'intensiveCareTotal',
                  },
                ]
              : []),
          ]}
        />
      </PlatformConditional>
    </Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ComorbidityDash;
