import * as React from 'react';
import { isPartialDate, formatPartialDate, sortPartialDate } from 'neuro-utils';
import { useIntl } from 'react-intl';
import { takeLast } from 'ramda';

import { getLatestChanges, filterEmptyFields, TDocument, IFieldWithDates } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const MotorFunctionDash = ({ documents }: IOwnProps): React.JSX.Element | undefined => {
  // First and only doc
  const motorFunctionDoc = filterEmptyFields(documents)?.[0] as IMotorFunction;

  const aidDocs = documents ? (documents.filter((d) => d._type === 'mobilityAid') as IAid[]) : undefined;
  const sortedAidDocs = aidDocs ? aidDocs.sort((d1, d2) => sortPartialDate(d1.startDate, d2.startDate)) : undefined;
  const latestAid = sortedAidDocs ? takeLast(1, sortedAidDocs)[0] : undefined;

  const getAidUsageMessageId = (aid: IAid): string => {
    return aid.use ? 'motorFunctionAndMobilityAid.aids.opts.' + aid.use : 'general.empty';
  };

  const { formatMessage } = useIntl();

  let dashData = {};
  if (motorFunctionDoc) {
    const changes = getLatestChanges(motorFunctionDoc)[0] as IFieldWithDates | null;
    dashData = changes
      ? {
          [formatMessage({ id: 'motorFunctionAndMobilityAid.latestChange' })]: {
            type: 'specificListMultipleRows',
            data: [
              {
                date: isPartialDate(changes.endDate)
                  ? formatPartialDate(changes.endDate)
                  : isPartialDate(changes.startDate)
                    ? formatPartialDate(changes.startDate)
                    : '',
                title: (
                  <div>
                    <div style={{ fontSize: '1.3rem' }}>
                      {isPartialDate(changes.endDate)
                        ? formatMessage({ id: 'motorFunctionAndMobilityAid.motorfunction.abilityLostDate' })
                        : isPartialDate(changes.startDate)
                          ? formatMessage({ id: 'motorFunctionAndMobilityAid.motorfunction.abilityGainedDate' })
                          : ''}
                    </div>
                    <div style={{ fontSize: '1.6rem', fontWeight: 600 }}>
                      {formatMessage({ id: 'motorFunctionAndMobilityAid.motorfunction.' + changes.name })}
                    </div>
                  </div>
                ),
                key: 'motorFunctionDate',
              },
            ],
          },
        }
      : {};
  }

  if (latestAid) {
    dashData = {
      ...dashData,
      [formatMessage({ id: 'motorFunctionAndMobilityAid.aids.latestAidShort' })]: {
        type: 'specificListMultipleRows',
        data: [
          {
            date: isPartialDate(latestAid.startDate) ? formatPartialDate(latestAid.startDate) : '',
            title: (
              <div>
                <div
                  style={{
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: isPartialDate(latestAid.endDate) ? '18rem' : '27rem',
                  }}
                >
                  {latestAid.aidType &&
                    `${formatMessage({ id: 'motorFunctionAndMobilityAid.aids.opts.' + latestAid.aidType })}`}
                  {latestAid.type &&
                    `, ${formatMessage({ id: `motorFunctionAndMobilityAid.aids.opts.${latestAid.type}` })}`}
                </div>
                <div style={{ fontSize: '1.3rem' }}>{formatMessage({ id: getAidUsageMessageId(latestAid) })}</div>
              </div>
            ),
            endDate: isPartialDate(latestAid.endDate) ? formatPartialDate(latestAid.endDate) : null,
            key: 'motorFunctionDate',
          },
        ],
      },
    };
  }

  return <TileContentMaker type="listWithSubheaders" data={dashData} />;
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorFunctionDash;
