import * as React from 'react';
import { IData, IDataPoint, TGraphScale, TGraphSettings } from 'Components/sq-graphics/interfaces';
import { GraphTooltip } from '../../GraphTooltip';
import { GraphTooltipDescription } from '../../GraphTooltip/components';
import * as d3 from 'd3';
import { sortDate } from 'neuro-utils';

const heatGraphColors = [
  '#98CE00',
  '#EEF158',
  '#F4E74F',
  '#F9DE47',
  '#FFD43E',
  '#FAC334',
  '#F5B129',
  '#F0A01F',
  '#EA8F15',
  '#E57D0A',
  '#E06C00',
  '#D45A03',
  '#C84806',
  '#BC3609',
  '#AF240C',
  '#A3120F',
  '#970012',
];

//const getColor = (n: number): string => d3.interpolate('#98CE00', '#970012')(n);

const getColor = (n: number): string => d3.interpolateRgbBasis(heatGraphColors)(n);

const HeatGraph = ({ data, xPoint, yPoint, height, scale, timeframeWidth }: IOwnProps): React.JSX.Element => {
  const dataPoints: IPoint[] = [];
  data.forEach((d: IData) => {
    d.dataPoints.forEach((dp) => {
      const x = xPoint(dp.date);
      const y = yPoint(dp.value, scale, height);
      if (!(x || x === 0) || !(y || y === 0)) return;
      // Used to calculate the change relative to the previous value
      const previousValue = d.dataPoints
        .filter((p) => p.value === dp.value && p.date.valueOf() < dp.date.valueOf())
        .sort((p1, p2) => sortDate(p1.date, p2.date))
        .slice(-1)?.[0]?.additionalValue;
      const change =
        !dp.additionalValue ||
        !previousValue ||
        dp.additionalValue === '-' ||
        dp.additionalValue === 'N/A' ||
        previousValue === '-' ||
        previousValue === 'N/A'
          ? undefined
          : parseFloat(dp.additionalValue.toString()) - parseFloat(previousValue.toString());
      dataPoints.push({
        id: `${x}${y}`,
        x,
        y,
        value: dp.additionalValue ?? '-',
        date: dp.date,
        description: dp.description,
        change,
        alternativeDateString: dp.alternativeDateString,
      });
    });
  });
  const xValues = dataPoints.map((dp) => dp.x);
  return (
    <g>
      {dataPoints.map((dp) => {
        return (
          <GraphTooltip
            key={dp.id}
            description={dp.description ? <GraphTooltipDescription convertedData={dp.description} /> : undefined}
            date={dp.date}
            title={dp.value.toString()}
            content={
              <g
                transform={`translate(${Math.abs(timeframeWidth * 10 - dp.x) < 5 ? timeframeWidth * 10 - 5 : dp.x},${
                  dp.y
                })`}
              >
                <rect
                  x="0"
                  y="0"
                  width="5"
                  height="27"
                  fill={
                    dp.value === '-' || dp.value === 'N/A' ? '#E1E1E1' : getColor(parseFloat(dp.value.toString()) / 16)
                  }
                />
                {!xValues.find((x) => x - dp.x > 0 && x - dp.x < 30) && (
                  <g transform="translate(5,0)">
                    <rect x="0" y="0" width="30" height="27" fill="#FFFFFF" />
                    <text
                      textAnchor="middle"
                      dominantBaseline="middle"
                      x="15"
                      y="15"
                      style={{ userSelect: 'none' }}
                      fontSize={dp.value === 'N/A' ? 14 : 18}
                    >
                      {dp.value}
                    </text>
                  </g>
                )}
                {!xValues.find((x) => x - dp.x > 0 && x - dp.x < 35) && dp.change && dp.change !== 0 && (
                  <g transform="translate(37.5, 15)">
                    {dp.change > 0 ? (
                      <g>
                        <text textAnchor="middle" x="6" y="-1">
                          {'+' + dp.change}
                        </text>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.41 7.705L6 3.125L10.59 7.705L12 6.295L6 0.294998L0 6.295L1.41 7.705Z"
                            fill="#C20012"
                          />
                        </svg>
                      </g>
                    ) : dp.change < 0 ? (
                      <g>
                        <text textAnchor="middle" x="6" y="-1">
                          {dp.change}
                        </text>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.41 0.295013L6 4.87501L10.59 0.295013L12 1.70501L6 7.70501L0 1.70501L1.41 0.295013Z"
                            fill="#7BA015"
                          />
                        </svg>
                      </g>
                    ) : null}
                  </g>
                )}
              </g>
            }
            alternativeDateString={dp.alternativeDateString}
          />
        );
      })}
    </g>
  );
};

interface IPoint {
  id: string;
  x: number;
  y: number;
  value: string | number;
  change?: number; // This describes the value to the previous value of same class
  date: Date;
  description: IDataPoint['description'];
  alternativeDateString?: string;
}

interface IOwnProps {
  data: IData[];
  xPoint: (date: Date) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  height: number;
  scale: TGraphScale;
  additionalScale: TGraphScale | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  reference?: true;
  timeframeWidth: number;
}

export default HeatGraph;
