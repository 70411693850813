import * as React from 'react';
import { IAddon, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import { exists, formatPartialDate, isPartialDate, sortDate, sortPartialDate } from 'neuro-utils';
import { DBSSettings, VNSSettings } from 'Components/DashboardGraph/Components';
import { findNextDate, formatDate, roundValue } from 'Components/DashboardGraph/Utils/';
import { isRechargeable } from 'Routes/Dbs/utils';
import { path } from 'Utility/ramdaReplacement';

/**
 * Create tooltip for DBS/VNS implantation event
 * @param generators - Data
 */
const implantationEvents = (
  generators: Array<IDBSGenerator | IVNSGenerator> | undefined,
  type: 'dbs' | 'vns',
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const returnedEvents: IEvent[] = [];
  const sortedGenerators =
    generators &&
    generators.sort((generator1: IDBSGenerator | IVNSGenerator, generator2: IDBSGenerator | IVNSGenerator) => {
      if (type === 'dbs') {
        return sortPartialDate(
          (generator1 as IDBSGenerator).generatorDate,
          (generator2 as IDBSGenerator).generatorDate,
        );
      }
      if (type === 'vns') {
        return sortPartialDate((generator1 as IVNSGenerator).date, (generator2 as IVNSGenerator).date);
      }
      return 0;
    });
  const generator: IDBSGenerator | IVNSGenerator | undefined = sortedGenerators?.[0];
  const title = fm('graph.implantation');
  const date: Date =
    generator && 'generatorDate' in generator && generator?.generatorDate
      ? dateFromPartialUpdateTimeframe(generator?.generatorDate)
      : generator && 'date' in generator && generator?.date
        ? dateFromPartialUpdateTimeframe(generator?.date)
        : new Date();
  const description = [
    {
      title: fm('dbs.generator'),
      values: `${generator?.generator} ${
        isRechargeable(generator?.generator, type === 'dbs' ? 'parkinson' : type === 'vns' ? 'epilepsy' : null)
          ? `\n${fm('dbs.rechargeable')}`
          : ''
      }`,
      condition: exists(generator?.generator),
    },
    {
      title: fm('dbs.nextGeneratorChange'),
      values: formatPartialDate(generator?.generatorNextChangeDate),
      condition: isPartialDate(generator?.generatorNextChangeDate),
    },
  ];
  const event: IEvent = {
    title: title,
    eventType: 'implantation',
    date: date,
    description: description,
  };
  returnedEvents.push(event);
  return returnedEvents;
};

const settingsEvents = (
  data: IDBS | IVNS,
  type: 'dbs' | 'vns',
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const retEvents: IEvent[] = [];

  switch (type) {
    case 'dbs': {
      const title = fm('graph.adjustment');

      const dbsData = data as IDBS;

      const leadLeft = dbsData.leadLeft || false;
      const leadRight = dbsData.leadRight || false;
      const targetNucleus = dbsData.targetNucleus || false;
      const targetNucleusLeft = dbsData.targetNucleusLeft || false;
      dbsData.settings &&
        dbsData.settings.forEach((setting: IDBSSetting) => {
          const date: Date = setting.generatorAdjustedDate
            ? dateFromPartialUpdateTimeframe(setting.generatorAdjustedDate)
            : new Date();
          const generators = dbsData.generators as Array<IDBSGenerator>;
          retEvents.push({
            title: title,
            eventType: 'settings',
            date: date,
            description: (
              <DBSSettings
                setting={setting}
                generators={generators}
                leadRight={leadRight}
                leadLeft={leadLeft}
                targetNucleus={targetNucleus}
                targetNucleusLeft={targetNucleusLeft}
              />
            ),
          } as IEvent);
        });
      return retEvents;
    }
    case 'vns': {
      const vnsData = data as IVNS;

      const title = fm('graph.vnsAdjustment');
      const leadType = vnsData.leadType || null;
      const allDates: Array<Date> | undefined = vnsData?.settings?.map((setting: IVNSSetting) =>
        setting.date ? dateFromPartialUpdateTimeframe(setting.date) : new Date(),
      );
      vnsData.settings &&
        vnsData.settings.forEach((setting: IVNSSetting) => {
          const date: Date = setting.date ? dateFromPartialUpdateTimeframe(setting.date) : new Date();
          const normalModeCurrent: number | undefined = path(['outputCurrent', 'default', 'normalMode'], setting);
          retEvents.push({
            title: title,
            eventType: 'settings',
            date: date,
            description: <VNSSettings data={vnsData} leadType={leadType} setting={setting} />,
          } as IEvent);
          roundValue(normalModeCurrent ? normalModeCurrent.toString() : '') &&
            date &&
            retEvents.push({
              date: date,
              endDate: allDates && findNextDate(setting.date as PartialDate, allDates),
              eventType: 'dynamic',
              title: roundValue(normalModeCurrent ? normalModeCurrent.toString() : ''),
            });
        });
      return retEvents;
    }
    default:
      return retEvents;
  }
};

/**
 * Create tooltips for DBS/VNS events from given data
 * @param title - Title
 * @param type - Decides the type of icon for event
 * @param events - event data
 * @param dateFromPartialUpdateTimeframe - Function to convert PatialDate into Date
 */
const treatmentEvents = (
  title: string | undefined,
  type: 'dbs' | 'vns',
  eventType: 'generatorChange' | 'adverseEffect' | 'technicalIssue' | 'postoperativeComplication' | 'discontinuation',
  events: Array<
    | IDBSGenerator
    | IVNSGenerator
    //| IDBSSetting
    | IDBSPostoperativeComplicationEvent
    | IVNSPostoperativeComplicationEvent
    | IDBSEvent
    | IVNSAdverseEffectEvent
    | IDBSTechnicalIssue
    | IVNSTechnicalIssueEvent
    //| IDBSDiscontinuationEvent
  >,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const retEvents: IEvent[] = [];
  let careTitle: React.JSX.Element | string | undefined = undefined;
  if (events && events.length > 0) {
    events.forEach((event, index) => {
      switch (eventType) {
        // case 'adjustment': {
        //   careTitle = fm('graph.adjustment');
        //   const date =
        //     'generatorAdjustedDate' in event ? dateFromPartialUpdateTimeframe(event.generatorAdjustedDate as PartialDate) : new Date();
        //   const description = fm('dbs.adjustGenerator');
        //   retEvents.push({
        //     date: date,
        //     eventType: 'modification',
        //     title: careTitle ? careTitle : title,
        //     description: description,
        //   });
        //   break;
        // }
        case 'generatorChange': {
          const e = type === 'dbs' ? (event as IDBSGenerator) : (event as IVNSGenerator);
          if (index < events.length - 1) {
            careTitle = fm('graph.generatorChange');
            const dateName = type === 'dbs' ? 'generatorDate' : 'date';
            const date = isPartialDate((e as { [key: string]: any })[dateName])
              ? dateFromPartialUpdateTimeframe((e as { [key: string]: any })[dateName])
              : new Date();
            const description = [
              {
                title: fm('dbs.changeGenerator'),
              },
              {
                title: e.generator,
                condition: exists(e.generator),
              },
            ].concat(
              type === 'dbs'
                ? [
                    {
                      title: fm('dbs.rechargeable'),
                      condition: isRechargeable(e.generator, 'parkinson'),
                    },
                  ]
                : [],
            );
            retEvents.push({
              date: date,
              eventType: 'modification',
              title: careTitle ? careTitle : title,
              description: description,
            });
          }
          break;
        }
        case 'adverseEffect': {
          // Adverse effect
          careTitle = fm('graph.adverseEffect');
          const date = 'date' in event && event.date ? dateFromPartialUpdateTimeframe(event.date) : new Date();
          let description = [];
          switch (type) {
            case 'dbs': {
              const e = event as IDBSEvent;
              description = [
                {
                  title: (e.eventTypes ?? [])
                    ?.map(
                      (item: string, index: number) =>
                        `${fm(`dbs.opts.${item}`)}${e.eventTypes && index < e.eventTypes.length - 1 ? ', ' : ''}`,
                    )
                    .join(''),
                  condition: Array.isArray(e.eventTypes) && e.eventTypes.length > 0,
                },
                {
                  title: e.eventDetails,
                  condition: exists(e.eventDetails),
                },
              ];
              break;
            }
            case 'vns': {
              const e: IVNSAdverseEffectEvent = event;
              description = [
                {
                  title: (e.effects ?? [])
                    ?.map(
                      (effect: string, index: number) =>
                        `${fm(`vns.opts.adverseEffect.${effect}`)}${
                          e.effects && index < e.effects.length - 1 ? ', ' : ''
                        }`,
                    )
                    .join(''),
                  condition: Array.isArray(e.effects) && e.effects.length > 0,
                },
                {
                  title: e.additionalInformation,
                  condition: exists(e.additionalInformation),
                },
              ];
              break;
            }
          }
          retEvents.push({
            date: date,
            eventType: 'adverseEffect',
            title: careTitle ? careTitle : title,
            description: description,
          });
          break;
        }
        case 'technicalIssue': {
          careTitle = fm('graph.technicalIssue');
          const date = 'date' in event && event.date ? dateFromPartialUpdateTimeframe(event.date) : new Date();
          let description: IEvent['description'] = '';
          switch (type) {
            case 'dbs': {
              if ('issueType' in event && event.issueType && 'issueDetails' in event && event.issueDetails) {
                description = `${fm(`dbs.opts.${event.issueType}`)}: ${event.issueDetails}`;
              } else if ('issueType' in event && event.issueType) {
                description = fm(`dbs.opts.${event.issueType}`);
              } else if ('issueDetails' in event && event.issueDetails) {
                description = event.issueDetails;
              }
              break;
            }
            case 'vns': {
              const e = event as IVNSTechnicalIssueEvent;
              description = [
                {
                  title: fm(`vns.opts.technicalIssue.${e.issue}`),
                  condition: exists(e.issue),
                },
                {
                  title: e.additionalInformation,
                  condition: exists(e.additionalInformation),
                },
              ];
              break;
            }
          }
          retEvents.push({
            date: date,
            eventType: 'technicalIssue',
            title: careTitle ? careTitle : title,
            description: description,
          });
          break;
        }
        case 'postoperativeComplication': {
          const e: IDBSPostoperativeComplicationEvent | IVNSPostoperativeComplicationEvent = event;
          // Postoperative complication
          careTitle = fm('graph.postoperativeComplication');
          const date = e.date ? dateFromPartialUpdateTimeframe(e.date) : new Date();
          const description = [
            {
              title: (e.complications ?? [])
                ?.map(
                  (complication: string, index: number) =>
                    `${fm(`${type}.opts.${type === 'vns' ? 'postoperativeComplication.' : ''}${complication}`)}${
                      e.complications && index < e.complications.length - 1 ? ', ' : ''
                    }`,
                )
                .join(''),
              condition: Array.isArray(e.complications) && e.complications.length > 0,
            },
            {
              title: e.additionalInformation,
              condition: exists(e.additionalInformation),
            },
          ];
          retEvents.push({
            date: date,
            eventType: 'adverseEffect',
            title: careTitle ? careTitle : title,
            description: description,
          });
          break;
        }
        // case 'treatmentContinues': {
        //   careTitle = fm('graph.change');
        //   const description = fm('dbs.treatmentContinues');
        //   const endDate = 'endDate' in event && event.endDate ? dateFromPartialUpdateTimeframe(event.endDate) : new Date();
        //   retEvents.push({
        //     date: endDate,
        //     eventType: 'continuation',
        //     title: careTitle ? careTitle : title,
        //     description: description,
        //   });
        // }
      }
    });
  }
  return retEvents;
};

/**
 * Create tooltips for DBS/VNS discontinuation events from given data
 */
const discontinuationEvent = (
  item: IDBSDiscontinuationEvent | IVNSDiscontinuationEvent,
  type: 'dbs' | 'vns',
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent => {
  const title = fm('graph.discontinuation');
  const date: Date = item.date ? dateFromPartialUpdateTimeframe(item.date) : new Date();
  let description: IEvent['description'] = [];
  switch (type) {
    case 'dbs': {
      const e = item as IDBSDiscontinuationEvent;
      description = [
        {
          title: fm('dbs.dbsDiscontinuation'),
        },
        {
          title: fm('dbs.discontinuationType'),
          values: exists(e.discontinuationType) ? fm(`dbs.opts.${e.discontinuationType}`) : undefined,
          condition: exists(e.discontinuationType),
        },
        {
          title: fm('dbs.discontinuationReasonShort'),
          values: exists(e.discontinuationReason) ? fm(`dbs.opts.${e.discontinuationReason}`) : undefined,
          condition: exists(e.discontinuationReason),
        },
        {
          title: fm('dbs.discontinuationDetails'),
          values: e.discontinuationDetails,
          condition: exists(e.discontinuationDetails),
        },
      ];
      break;
    }
    case 'vns': {
      const e = item as IVNSDiscontinuationEvent;
      description = [
        {
          title: fm('vns.discontinuation'),
        },
        {
          title: fm('vns.discontinuationType'),
          values: fm(`vns.opts.discontinuationType.${e.type}`),
          condition: exists(e.type),
        },
        {
          title: fm('vns.discontinuationReason'),
          values: fm(`vns.opts.discontinuationReason.${e.reason}`),
          condition: exists(e.reason),
        },
        {
          title: fm('vns.additionalInformation'),
          values: e.additionalInformation,
          condition: exists(e.additionalInformation),
        },
      ];
      break;
    }
  }

  return {
    date: date,
    eventType: 'discontinuation',
    title: title,
    description: description,
  };
};

/**
 * Create tooltips for DBS/VNS ending events from given data
 * @param data - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PatialDate into Date
 */
const endEvents = (
  data: IDBS | IVNS,
  type: 'dbs' | 'vns',
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const retEvents: IEvent[] = [];
  const title = fm('graph.end');
  const date: Date | null = isPartialDate(data.endDate) ? dateFromPartialUpdateTimeframe(data.endDate) : null;
  if (!date) return [];
  let description: IEvent['description'] = [];
  switch (type) {
    case 'dbs': {
      const e = data as IDBS;
      description = [
        {
          title: fm('dbs.ended'),
        },
        {
          title: fm('dbs.endedReason'),
          values: exists(e.endReason) ? fm(`dbs.opts.${e.endReason}`) : undefined,
          condition: exists(e.endReason),
        },
        {
          title: fm('dbs.endedDetails'),
          values: e.endDetails,
          condition: e.endReason === 'other' && exists(e.endDetails),
        },
      ];
      break;
    }
    case 'vns': {
      const e = data as IVNS;
      description = [
        {
          title: fm('vns.endReason'),
          values: fm(`vns.opts.endReason.${e.endReason}`),
          condition: exists(e.endReason),
        },
        {
          title: fm('vns.endAdditionalInformation'),
          values: e.endAdditionalInformation,
          condition: e.endReason === 'other' && exists(e.endAdditionalInformation),
        },
      ];
      break;
    }
  }
  retEvents.push({
    date: date,
    eventType: 'end',
    title: title,
    description: description,
  });
  return retEvents;
};

/**
 * Create tooltips for DBS/VNS removal events from given data
 * @param data - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PatialDate into Date
 */
const removalEvents = (
  data: IDBS | IVNS,
  type: 'dbs' | 'vns',
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  if (!isPartialDate(data.removalDate)) return [];
  const retEvents: IEvent[] = [];
  const title = fm('graph.removal');
  const date: Date = dateFromPartialUpdateTimeframe(data.removalDate);
  const description = [
    {
      title: fm(`${type}.removed`),
    },
    {
      title: fm(`${type}.removalType`),
      values:
        type === 'dbs' && Array.isArray(data.removalType)
          ? data.removalType
              .map(
                (type: string, index: number) =>
                  `${fm(`dbs.opts.${type}`)}${data.removalType && index < data.removalType.length - 1 ? ', ' : ''}`,
              )
              .join('')
          : fm(`vns.opts.removalType.${data.removalType}`),
      condition:
        type === 'dbs' ? Array.isArray(data.removalType) && data.removalType.length > 0 : exists(data.removalType),
    },
    {
      title: fm(`${type}.removalReason`),
      values: fm(`${type}.opts.${type === 'vns' ? 'removalReason.' : ''}${data.removalReason}`),
      condition: exists(data.removalReason),
    },
    {
      title: fm(`${type}.removalAdditionalInformation`),
      values: data.removalAdditionalInformation,
      condition: exists(data.removalAdditionalInformation),
    },
  ];
  retEvents.push({
    date: date,
    eventType: 'removal',
    title: title,
    description: description,
  });
  return retEvents;
};

interface IListOfEvents {
  type: 'generator' | 'discontinuation' | 'generatorSwitchedOff';
  date: Date;
  endDate?: Date;
  title: string;
}

const getItemsFromListOfEvents = (
  data: IDBS | IVNS,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  listOfEvents: IListOfEvents[],
): IItem[] => {
  const items: IItem[] = [];
  if (listOfEvents.length > 1) {
    for (let i = 0; i < listOfEvents.length; i++) {
      // Käydään läpi itemit toiseksi viimeiseen asti -> checkataan else-lohkossa jos vika on generaattori, muuten huomioidaan ao. ehtolauseissa
      if (i < listOfEvents.length - 1) {
        // GEN-GEN
        if (listOfEvents[i].type === 'generator' && listOfEvents[i + 1].type === 'generator') {
          items.push({
            title: listOfEvents[i].title ? listOfEvents[i].title : '',
            start: listOfEvents[i].date,
            end: listOfEvents[i + 1].date,
          });
          // GEN-DISC
        } else if (listOfEvents[i].type === 'generator' && listOfEvents[i + 1].type === 'discontinuation') {
          items.push({
            title: listOfEvents?.[i]?.title ? listOfEvents[i].title : '',
            start: listOfEvents[i].date,
            end: listOfEvents[i + 1].date,
          });
          // GEN-SWITCHED OFF
        } else if (listOfEvents[i].type === 'generator' && listOfEvents[i + 1].type === 'generatorSwitchedOff') {
          // Asetetaan listaan generaattoria seuraavat päältäpoiskytkemiset, joiden mukaan tehdään palkit graafiin
          const discs: IListOfEvents[] = [];
          for (let j = i + 1; j < listOfEvents.length; j++) {
            if (listOfEvents[j].type === 'generatorSwitchedOff') {
              discs.push(listOfEvents[j]);
            } else {
              break;
            }
          }
          // Jos generaattorin ja poispäältäkytkemisten jälkeen ei tule keskeystystä tai generaattoria, asetetaan datestringiksi ->, muuten -
          // Huomioidaan myös mahdollinen hoidon lopetus
          let dateString = '';
          if (data.hasEnded?.[0] === true && i + discs.length === listOfEvents.length - 1) {
            dateString = `${formatDate(listOfEvents[i].date)} – ${
              data.endDate ? formatDate(dateFromPartialUpdateTimeframe(data.endDate)) : ''
            }`;
          } else if (i + discs.length === listOfEvents.length - 1) {
            dateString = `${formatDate(listOfEvents[i].date)} –> `;
          } else {
            dateString = `${formatDate(listOfEvents[i].date)} – ${formatDate(listOfEvents[i + discs.length + 1].date)}`;
          }
          // Pusketaan items-listaan generaattorin ja ekan poispäältäkytkemisen välinen aika
          items.push({
            title: listOfEvents[i].title ? listOfEvents[i].title : '',
            start: listOfEvents[i].date,
            end: discs[0].date,
            alternativeDateString: dateString,
          });
          // Pusketaan tässä tarvittaessa loput palkit; ts. loopataan discs läpi ja datejen ja enddatejen mukaan piirretään palkit
          // Jos poispäältäkytkemisiä enemmän kuin 1 samalle generaattorille niin loopataan läpi
          if (discs.length > 1) {
            for (let j = 0; j < discs.length; j++) {
              // Viimeinen poispäältäkytkentä samalle generaattorille. Huomioi jos viimeistä poispäältäkytkentää ei ole vielä lopetettu
              if (j === discs.length - 1) {
                const endDate = listOfEvents?.[i + discs.length + 1]?.date
                  ? listOfEvents[i + discs.length + 1].date
                  : undefined;
                discs[j].endDate &&
                  items.push({
                    title: listOfEvents[i].title,
                    start: discs[j].endDate as Date,
                    end: endDate,
                    alternativeDateString: dateString,
                  });
                // Ensimmäisestä toiseksi viimeiseen poispäältäkytkentään.
              } else {
                discs[j].endDate &&
                  items.push({
                    title: listOfEvents[i].title,
                    start: discs[j].endDate as Date,
                    end: discs[j + 1].date,
                    alternativeDateString: dateString,
                  });
              }
            }
            // Muuten checkataan suoraan, onko hoitoa jatkettu -> Jos on niin pusketaan itemsiin palkki.
          } else {
            if (discs[0].endDate) {
              // Palkki voi a) jatkua b) loppua seuraavaan generaattoriin tai keskeytykseen c) generaattorin ja poispäältäkytkennän ollessa viimeiset päättyä hoidon lopettamiseen
              // Siispä määritetään mahdollinen päättymispiste
              const end =
                i + discs.length < listOfEvents.length - 1
                  ? listOfEvents[i + discs.length + 1].date
                  : data.hasEnded?.[0] === true && data.endDate
                    ? dateFromPartialUpdateTimeframe(data.endDate)
                    : undefined;
              items.push({
                title: listOfEvents[i].title ? listOfEvents[i].title : '',
                start: discs[0].endDate,
                end: end,
                alternativeDateString: dateString,
              });
            }
          }
        }
        // Jos viimeisenä on generaattori, pusketaan generaattori itemeihin. Jos hoito lopetettu ja laite poistettu, lisätään päättymispvm.
      } else {
        if (listOfEvents[listOfEvents.length - 1]?.type === 'generator') {
          items.push({
            title: listOfEvents[listOfEvents.length - 1].title ? listOfEvents[listOfEvents.length - 1].title : '',
            start: listOfEvents[listOfEvents.length - 1].date,
            end:
              data.hasEnded?.[0] === true && isPartialDate(data.endDate)
                ? dateFromPartialUpdateTimeframe(data.endDate)
                : undefined,
          });
        }
      }
    }
  } else if (listOfEvents.length === 1 && listOfEvents[0]?.type === 'generator') {
    items.push({
      title: listOfEvents[0].title ? listOfEvents[0].title : '',
      start: listOfEvents[0].date,
      end:
        data.hasEnded?.[0] === true && isPartialDate(data.endDate)
          ? dateFromPartialUpdateTimeframe(data.endDate)
          : undefined,
    });
  }
  return items;
};

interface ICareGenerator {
  title: string | React.JSX.Element;
  date: Date;
  partialDate?: PartialDate;
}

export const convertDbsToTimeline = (
  docs: Array<IDBS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  platform: string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;

  const addons: IAddon[] = [];

  const type = 'dbs';

  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return undefined;

    const listOfEvents: IListOfEvents[] = [];
    const generators: ICareGenerator[] = [];
    let start: Date = dateFromPartialUpdateTimeframe(doc.date, undefined, true);
    let items: IItem[] = [];

    if (!doc.generators || doc.generators.length === 0) return undefined;

    doc.generators.forEach((generator: IDBSGenerator) => {
      generator.generatorDate &&
        listOfEvents.push({
          type: 'generator',
          date: dateFromPartialUpdateTimeframe(generator.generatorDate),
          endDate: undefined,
          title: generator.generator
            ? `${generator.generator}: ${
                isRechargeable(generator.generator, 'parkinson') ? fm('dbs.rechargeable') : fm('dbs.primaryCell')
              }`
            : `${fm('dbs.generatorNotDefined')}`,
        });
    });

    doc.discontinuationEvents &&
      doc.discontinuationEvents.forEach((discontinuation: IDBSDiscontinuationEvent) => {
        discontinuation.date &&
          listOfEvents.push({
            type:
              discontinuation.discontinuationType === 'generatorSwitchedOff'
                ? 'generatorSwitchedOff'
                : 'discontinuation',
            date: dateFromPartialUpdateTimeframe(discontinuation.date),
            endDate: discontinuation.endDate ? dateFromPartialUpdateTimeframe(discontinuation.endDate) : undefined,
            title: '',
          });
      });

    listOfEvents.sort((n1: IListOfEvents, n2: IListOfEvents) => sortDate(n1.date, n2.date));

    if (doc.generators) {
      doc.generators.forEach((generator: IDBSGenerator) => {
        const gStart: Date | undefined = generator.generatorDate
          ? dateFromPartialUpdateTimeframe(generator.generatorDate)
          : undefined;
        gStart &&
          generators.push({
            date: gStart,
            partialDate: generator.generatorDate,
            title: generator.generator
              ? generator.generator === 'Other rechargeable'
                ? fm('dbs.otherRechargeable')
                : generator.generator === 'Other battery'
                  ? fm('dbs.otherPrimaryCell')
                  : `${generator.generator}: ${
                      isRechargeable(generator.generator, 'parkinson') ? fm('dbs.rechargeable') : fm('dbs.primaryCell')
                    }`
              : fm('dbs.generatorNotDefined'),
          });
      });

      if (generators.length > 1) {
        generators.sort((n1, n2) => sortDate(n1.date, n2.date));
      }
      if (generators.length > 0) {
        if (generators[0].date.getTime() < start.getTime()) start = generators[0].date;
      }

      items = getItemsFromListOfEvents(doc, dateFromPartialUpdateTimeframe, listOfEvents);
    }

    // Create an array of implantations
    const implantations: IEvent[] = doc.generators
      ? implantationEvents(doc.generators as IDBSGenerator[], type, dateFromPartialUpdateTimeframe, fm)
      : [];

    // Create an array of generator changes
    const generatorChanges: IEvent[] = treatmentEvents(
      '',
      type,
      'generatorChange',
      doc.generators,
      dateFromPartialUpdateTimeframe,
      fm,
    );

    // Create an array of settings
    const settings: IEvent[] = doc.settings ? settingsEvents(doc, type, dateFromPartialUpdateTimeframe, fm) : [];

    // Create an array of postoperative complications
    const postoperativeComplications: IEvent[] =
      platform === 'epilepsy' && doc.postoperativeComplications
        ? treatmentEvents(
            '',
            type,
            'postoperativeComplication',
            doc.postoperativeComplications,
            dateFromPartialUpdateTimeframe,
            fm,
          )
        : [];

    // Create an array of adverse events
    const adverseEffect: IEvent[] = doc.dbsEvents
      ? treatmentEvents('', type, 'adverseEffect', doc.dbsEvents, dateFromPartialUpdateTimeframe, fm)
      : [];

    // Create an array of technical issues
    const technicalIssues: IEvent[] = doc.technicalIssues
      ? treatmentEvents('', type, 'technicalIssue', doc.technicalIssues, dateFromPartialUpdateTimeframe, fm)
      : [];

    // Create an array of discontinuations
    const dbsDiscontinuationEvents: IEvent[] =
      (doc?.discontinuationEvents || []).length > 0
        ? (doc.discontinuationEvents || []).map((item: IDBSDiscontinuationEvent) =>
            discontinuationEvent(item, type, dateFromPartialUpdateTimeframe, fm),
          )
        : [];

    // Create an array of dbsEndEvents if hasEnded field has a true value
    const dbsEndEvents: IEvent[] =
      doc.hasEnded?.[0] === true ? endEvents(doc, type, dateFromPartialUpdateTimeframe, fm) ?? [] : [];

    // Create an array of dbsRemovalEvents if both removed and hasEnded have true values
    const dbsRemovalEvents: IEvent[] =
      doc.removed === 'yes' && doc.hasEnded?.[0] === true
        ? removalEvents(doc, type, dateFromPartialUpdateTimeframe, fm)
        : [];

    // Merge/flatten arrays of events to one array
    const events: IEvent[] = [
      ...implantations,
      ...generatorChanges,
      ...settings,
      ...postoperativeComplications,
      ...adverseEffect,
      ...technicalIssues,
      ...dbsDiscontinuationEvents,
      ...dbsEndEvents,
      ...dbsRemovalEvents,
    ];
    events.sort((n1, n2) => sortDate(n2.date, n1.date));
    addons.push({
      id: 'dbs',
      title: fm('dbs.title'),
      titleDescription: undefined,
      events: events,
      items: items,
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertVnsToTimeline = (
  docs: Array<IVNS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;

  const addons: IAddon[] = [];

  const type = 'vns';

  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return undefined;

    const listOfEvents: IListOfEvents[] = [];
    const generators: ICareGenerator[] = [];
    let start: Date = dateFromPartialUpdateTimeframe(doc.date, undefined, true);
    let items: IItem[] = [];

    if (!doc.generators || doc.generators.length === 0) return undefined;

    doc.generators.forEach((generator: IVNSGenerator) => {
      generator.date &&
        listOfEvents.push({
          type: 'generator',
          date: dateFromPartialUpdateTimeframe(generator.date),
          endDate: undefined,
          title: generator.generator ?? fm('vns.generatorNotDefined'),
        });
    });

    doc.discontinuations &&
      doc.discontinuations.forEach((discontinuation: IVNSDiscontinuationEvent) => {
        discontinuation.date &&
          listOfEvents.push({
            type: discontinuation.type === 'generatorSwitchedOff' ? 'generatorSwitchedOff' : 'discontinuation',
            date: dateFromPartialUpdateTimeframe(discontinuation.date),
            endDate: discontinuation.endDate ? dateFromPartialUpdateTimeframe(discontinuation.endDate) : undefined,
            title: '',
          });
      });

    listOfEvents.sort((n1: IListOfEvents, n2: IListOfEvents) => sortDate(n1.date, n2.date));

    if (doc.generators) {
      doc.generators.forEach((generator: IVNSGenerator) => {
        const gStart: Date | undefined = generator.date ? dateFromPartialUpdateTimeframe(generator.date) : undefined;
        gStart &&
          generators.push({
            date: gStart,
            partialDate: generator.date,
            title: generator.generator ?? fm('vns.generatorNotDefined'),
          });
      });

      if (generators.length > 1) {
        generators.sort((n1, n2) => sortDate(n1.date, n2.date));
      }
      if (generators.length > 0) {
        if (generators[0].date.getTime() < start.getTime()) start = generators[0].date;
      }

      items = getItemsFromListOfEvents(doc, dateFromPartialUpdateTimeframe, listOfEvents);
    }

    // Create an array of implantations
    const implantations: IEvent[] = doc.generators
      ? implantationEvents(doc.generators as IVNSGenerator[], type, dateFromPartialUpdateTimeframe, fm)
      : [];

    // Create an array of generator changes
    const generatorChanges: IEvent[] = treatmentEvents(
      '',
      type,
      'generatorChange',
      doc.generators,
      dateFromPartialUpdateTimeframe,
      fm,
    );

    // Create an array of settings
    const settings: IEvent[] = doc.settings ? settingsEvents(doc, type, dateFromPartialUpdateTimeframe, fm) : [];

    // Create an array of postoperative complications
    const postoperativeComplications: IEvent[] = treatmentEvents(
      '',
      type,
      'postoperativeComplication',
      doc.postoperativeComplications ?? [],
      dateFromPartialUpdateTimeframe,
      fm,
    );

    // Create an array of adverse events
    const adverseEffect: IEvent[] = treatmentEvents(
      '',
      type,
      'adverseEffect',
      doc.adverseEffects ?? [],
      dateFromPartialUpdateTimeframe,
      fm,
    );

    // Create an array of technical issues
    const technicalIssues: IEvent[] = treatmentEvents(
      '',
      type,
      'technicalIssue',
      doc.technicalIssues ?? [],
      dateFromPartialUpdateTimeframe,
      fm,
    );

    // Create an array of discontinuations
    const vnsDiscontinuationEvents: IEvent[] =
      Array.isArray(doc.discontinuations) && doc.discontinuations?.length > 0
        ? doc.discontinuations.map((item: IVNSDiscontinuationEvent) =>
            discontinuationEvent(item, type, dateFromPartialUpdateTimeframe, fm),
          )
        : [];

    // Create an array of vnsEndEvents if hasEnded field has a true value
    const vnsEndEvents: IEvent[] =
      path(['hasEnded', 0], doc) === true ? endEvents(doc, type, dateFromPartialUpdateTimeframe, fm) : [];

    // Create an array of vnsRemovalEvents if both removed and hasEnded have true values
    const vnsRemovalEvents: IEvent[] =
      doc.removed === 'yes' && path(['hasEnded', 0], doc) === true
        ? removalEvents(doc, type, dateFromPartialUpdateTimeframe, fm)
        : [];

    // Merge/flatten arrays of events to one array
    const events: IEvent[] = [
      ...implantations,
      ...generatorChanges,
      ...settings,
      ...postoperativeComplications,
      ...adverseEffect,
      ...technicalIssues,
      ...vnsDiscontinuationEvents,
      ...vnsEndEvents,
      ...vnsRemovalEvents,
    ];
    events.sort((n1, n2) => sortDate(n1.date, n2.date));
    addons.push({
      id: 'vns',
      title: fm('vns.title'),
      titleDescription: undefined,
      events: events,
      items: items,
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};
