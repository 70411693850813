import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { TDocument, uhdrsMotorScore } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const sort = (type: string, documents?: IOwnProps['documents']): TDocument[] | undefined => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .filter((d) => d._type === type, documents);
  } else return undefined;
};

const MotorFuncHuntDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const uhdrsDoc = sort('uhdrsMotorRatingScale', documents) as IUHDRSMotorRatingScale[];
  return (
    <TileContentMaker
      type="recentList"
      data={uhdrsDoc.map((u) => ({
        date: isPartialDate(u.date) ? formatPartialDate(u.date) : '',
        title: <FormattedMessage id="motorFunctionHuntington.uhdrs" />,
        value:
          uhdrsMotorScore(u) === 'notCounted' || uhdrsMotorScore(u) === 'cantCount' ? '-' : `${uhdrsMotorScore(u)}`,
        key: u._id,
      }))}
    />
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorFuncHuntDash;
