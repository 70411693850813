import { CircularProgressProps } from '@mui/material';
import CircularProgressLocal from 'Components/CircularProgress';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';

const CenteredLoadingIndicator = ({
  descriptionText,
  areaHeight = '80rem',
  size = '18rem',
  ...other
}: ICircularProgress): React.JSX.Element => {
  return (
    <Container style={{ height: areaHeight, textAlign: 'center' }} alignItems="center" justifyContent="center">
      <Item>
        <div>
          <CircularProgressLocal size={size} {...other} />
        </div>
        {descriptionText && <div style={{ margin: '2rem 0' }}>{descriptionText}</div>}
      </Item>
    </Container>
  );
};

interface ICircularProgress extends CircularProgressProps {
  descriptionText?: string | React.JSX.Element;
  areaHeight?: string;
  size?: string | number;
}

export default CenteredLoadingIndicator;
