import * as React from 'react';
import CollapseElem from '.';

const ConditionalCollapse = ({
  condition,
  children,
  localeIDs,
  amount,
}: {
  condition: boolean;
  children: React.JSX.Element;
  localeIDs: { showMessage: string; hideMessage: string };
  amount: number;
}): React.JSX.Element =>
  condition ? (
    <CollapseElem localeIDs={localeIDs} amount={amount}>
      <div style={{ marginBottom: '1rem' }}>{children}</div>
    </CollapseElem>
  ) : (
    children
  );

export default ConditionalCollapse;
