import * as React from 'react';
import FormRow from 'Components/FormRow';
import ConditionalCollapse from 'Components/Collapse/ConditionalCollapse';
import { exists } from 'neuro-utils';
import { Connector, HeaderControls, IconChooser, StyledLabelText, StyledStepContent, StyledStepLabel } from '.';
import { Step, Stepper } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import { IEventStepper } from '../definitions';
import DeleteDialog from 'Components/ConfirmationDialog';
import FullDeleteDialog, { IDeleteDetails } from 'Components/HistoryRowControls/DeleteDialog';
import EditingEvent from './EditingEvent';
import { fm } from 'Components/FormatMessage';
import { omit } from 'ramda';
import { getJWTData } from 'Utility/jwtAuthTools';
import { controlProps } from 'Utility/documentHandling';
import { onChangeEvent } from '../utils';
import { EventStepperContext, IEventStepperContext } from '..';

const fullDeleteDialogDeleteHandler = (
  deleteConfirmCallback: IEventStepperContext['deleteConfirmCallback'],
): ((deleteDetails?: IDeleteDetails) => void) => {
  return (details?: IDeleteDetails) => {
    const orgId = getJWTData()?.orgid;
    const userId = getJWTData()?.useruuid;
    if (orgId && userId) {
      const removeTS = new Date().getTime();
      const removeInfo = {
        removeDate: new Date().toISOString(),
        remover: userId,
        removerOrg: orgId,
        removeReason: details,
      };
      deleteConfirmCallback({ removeTS: removeTS, removeInfo: removeInfo });
    }
  };
};

const ListView = ({ normalizedProps, propsByType, types }: IOwnProps): React.JSX.Element => {
  const eventStepperContext = React.useContext(EventStepperContext);
  const {
    thisEvents,
    _thisEvents,
    anchor,
    editingEvent,
    thisEventsLength,
    setEditing,
    editingIndex,
    setEditingIndex,
    editingEvents,
    editingEventType,
    save,
    activeStep,
    activateStep,
    toggleMenu,
    deleteIndex,
    setDeleteIndex,
    deleteConfirmCallback,
    deleteCancelCallback,
    openDeleteDialog,
  } = eventStepperContext;

  const isGroup = propsByType && Array.isArray(types) && types.length > 0;

  const propsToUse = (type?: string) => {
    if (!isGroup) return normalizedProps;
    return type ? propsByType[type] : propsByType[types[0]];
  };

  const {
    viewing,
    tableContent,
    separateLatest,
    allClosed,
    previousEventsCollapseLimit,
    previousEventsCollapseMessage,
    previousEventsTextHeader,
    deleteMessage,
    readOnlyEvents = [],
    theme,
  } = propsToUse();

  const collapseLimit = previousEventsCollapseLimit ? previousEventsCollapseLimit + (editingEvent === true ? 1 : 0) : 0;

  const editing = !!(editingEvent || editingEvent === 0);
  const addingNewEvent = editingEvent === true;

  const allEvents = [...thisEvents, ...readOnlyEvents];

  return (
    <FormRow
      title={tableContent && typeof editingEvent !== 'number' && !addingNewEvent ? undefined : previousEventsTextHeader}
    >
      <React.Fragment>
        {thisEventsLength === thisEvents.length &&
          (collapseLimit && thisEvents.slice(collapseLimit).length - (editingEvent ? 1 : 0) > 0
            ? ['visible', 'collapse']
            : ['visible']
          ).map((display) => {
            return (
              <ConditionalCollapse
                key={display}
                condition={display === 'collapse'}
                localeIDs={previousEventsCollapseMessage as { showMessage: string; hideMessage: string }}
                amount={thisEvents.slice(collapseLimit).length - 1}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  style={{ margin: '0', padding: '0' }}
                  connector={<Connector completed={false} />}
                >
                  {allEvents?.map((d: any, i: number) => {
                    const currentIndex = propsToUse(d._type).formData.document[d._type]?.findIndex(
                      (e: any) => thisEvents[i].id === e.id,
                    );
                    if (
                      // Show event in list if...
                      // ...it is NOT the event being created OR
                      (addingNewEvent && i !== 0) ||
                      // ...an event is NOT being created
                      !addingNewEvent
                    ) {
                      if ((separateLatest && i > 0) || !separateLatest) {
                        // Used to update step label while editing
                        const stepLabelTextDoc = exists(editingIndex) ? _thisEvents.find((e) => e.id === d.id) : d;
                        return (
                          stepLabelTextDoc && (
                            <Step
                              key={d._type ? d._type + i : i}
                              style={{
                                marginBottom: '0',
                                display:
                                  collapseLimit &&
                                  ((i > collapseLimit - 1 && display === 'visible') ||
                                    (i < collapseLimit && display === 'collapse'))
                                    ? 'none'
                                    : undefined,
                              }}
                            >
                              <StyledStepLabel
                                StepIconComponent={IconChooser}
                                style={{
                                  padding: '0',
                                  backgroundColor: isGroup
                                    ? propsByType[d._type].theme?.highlightColor
                                    : theme?.highlightColor,
                                }}
                                // Don't allow opening other steps/events while editing is active
                                onClick={!editing ? activateStep(i, allClosed) : undefined}
                              >
                                <Container alignItems="center">
                                  <Item xs={6}>
                                    <StyledLabelText>
                                      {propsToUse(d._type).stepLabelText(stepLabelTextDoc)}
                                    </StyledLabelText>
                                  </Item>
                                  <Item xs={6}>
                                    {!editing && !viewing && (
                                      <HeaderControls
                                        index={i}
                                        type={d._type}
                                        setEditing={setEditing}
                                        toggleMenu={toggleMenu(i)}
                                        anchor={anchor}
                                        openDeleteDialog={openDeleteDialog}
                                      />
                                    )}
                                  </Item>
                                </Container>
                              </StyledStepLabel>
                              <StyledStepContent editing={editing && editingEvent === i}>
                                {editingEvent !== i ? (
                                  <div style={{ margin: '' }}>
                                    {propsToUse(d._type).stepContent(omit(['_type'], d))}
                                  </div>
                                ) : null}
                                {editing && editingEvent === i ? (
                                  <EditingEvent
                                    thisIndex={editingIndex ?? (isGroup ? currentIndex : i)}
                                    editingElements={propsToUse(d._type).editingElements}
                                    thisOnChange={onChangeEvent(
                                      editingIndex ?? (isGroup ? currentIndex : i),
                                      editingEventType ?? propsToUse(d._type).name,
                                      propsToUse(d._type).dateFieldName,
                                      propsToUse(d._type).formData,
                                      editingEvents.current,
                                      setEditingIndex,
                                      propsToUse(d._type).mutators?.onChangeMutator,
                                    )}
                                    save={save}
                                    saveDisabled={propsToUse(d._type).saveDisabled}
                                    saveDisabledMessage={propsToUse(d._type).saveDisabledMessage}
                                  />
                                ) : null}
                              </StyledStepContent>
                            </Step>
                          )
                        );
                      }
                      return undefined;
                    } else return undefined;
                  })}
                </Stepper>
              </ConditionalCollapse>
            );
          })}
      </React.Fragment>
      {
        {
          default: (
            <DeleteDialog
              open={exists(deleteIndex)}
              text={fm(deleteMessage ? deleteMessage : 'general.reallyWantToDelete')}
              confirm={{ callback: () => deleteConfirmCallback() }}
              cancel={{ callback: deleteCancelCallback }}
            />
          ),
          full: (
            <FullDeleteDialog
              document={controlProps.reduce((current, key) => {
                (current as { [key: string]: any })[key] = null;
                return current;
              }, {} as IControlProps)}
              open={exists(deleteIndex)}
              setOpen={() => setDeleteIndex(null)}
              customDeleteHandler={fullDeleteDialogDeleteHandler(deleteConfirmCallback)}
            />
          ),
        }[propsToUse(editingEventType).deleteDialogMode ?? 'default']
      }
    </FormRow>
  );
};

interface IOwnProps {
  normalizedProps: IEventStepper;
  propsByType?: {
    [key: string]: IEventStepper;
  };
  types?: string[];
}

export default ListView;
