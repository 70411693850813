import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { isEnded } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string | number | null }, key?: string): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} key={key || undefined} />
);

const OtherSymptomsDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const sortedArr = documents
    ?.sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse();
  const ongoingSymptoms = sortedArr ? sortedArr.filter((at: IOtherSymptoms) => !isEnded(at?.endDate)) : [];
  const endedSymptoms = sortedArr ? sortedArr.filter((at: IOtherSymptoms) => isEnded(at?.endDate)) : [];

  return (
    <TileContentMaker
      type="recentList"
      data={[...ongoingSymptoms, ...endedSymptoms].map((s) => ({
        date: isPartialDate(s.date) ? formatPartialDate(s.date) : '',
        title: s?.classification ? (
          <React.Fragment>
            <span>{fm(`otherSymptoms.${s?.classification}.title`)}</span>
            {s.symptoms && <span>: {fm(`otherSymptoms.${s?.classification}.opts.${s.symptoms}`)}</span>}
            {s.symptoms === 'other' && s.freeText && <span> ({s.freeText})</span>}
          </React.Fragment>
        ) : (
          '-'
        ),
        endDate: isPartialDate(s.endDate) ? formatPartialDate(s.endDate) : '',
        key: s._id,
      }))}
    />
  );
};

interface IOwnProps {
  documents: IOtherSymptoms[];
}

export default OtherSymptomsDash;
