import * as React from 'react';
import { GraphColors, IData, IDataPoint, TGraphScale, TGraphSettings } from 'Components/sq-graphics/interfaces';
import { GraphTooltip } from '../../GraphTooltip';
import { invertColor } from '../../../util/calculators';
import { GraphTooltipDescription } from '../../GraphTooltip/components';

const getGraphIds = (data: Array<IData>): string[] => {
  const ids: string[] = [];
  data.forEach((d) => {
    if (ids.includes(d.id)) return;
    ids.push(d.id);
  });
  return ids;
};

const StackedBarChart = ({
  data,
  xPoint,
  yPoint,
  height,
  scale,
  additionalScale,
  graphSettings,
  reference,
  interval = 'monthly',
  numberOfOtherGraphs,
  graphTypeIndex,
}: IOwnProps): React.JSX.Element => {
  const ids: string[] = getGraphIds(data);
  const colors: { [key: string]: string } = {};
  ids.forEach((key, i) => {
    if (graphSettings?.colors?.[key]) colors[key] = GraphColors[graphSettings?.colors[key]];
    else
      colors[key] =
        Object.values(GraphColors)[(numberOfOtherGraphs * graphTypeIndex + i) % Object.values(GraphColors).length];
  });
  const dataPoints: { [key: string]: IPoint[] } = {};
  ids.forEach((id) => {
    data
      .filter((d) => d.id === id)
      .forEach((d) => {
        let sc: TGraphScale = d.useAdditionalScale && additionalScale ? additionalScale : scale;
        if (reference && additionalScale) sc = additionalScale;
        d.dataPoints.forEach((dp) => {
          switch (interval) {
            case 'monthly': {
              const date = dp.date;
              const dateId = `${date.getFullYear()}/${date.getMonth() + 1}`;
              // Jos idllä ja samalla kuukaudella löydetään jo datapisteet, ei tehdä duplikaatteja
              if (dateId in dataPoints && dataPoints[dateId].find((point) => point.id === id)) return;
              const dpsWithinMonth = d.dataPoints.filter(
                (dwm) => dwm.date.getFullYear() === date.getFullYear() && dwm.date.getMonth() === date.getMonth(),
              );
              const value = dpsWithinMonth.reduce((prev, curr) => {
                if (typeof curr.value === 'number') return prev + curr.value;
                return prev;
              }, 0);
              const firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
              const lastOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
              const xStart = xPoint(firstOfMonth);
              const xEnd = xPoint(lastOfMonth);
              const yStart = yPoint(0, sc, height);
              const yEnd = yPoint(value, sc, height);
              if (
                !(xStart || xStart === 0) ||
                !(xEnd || xEnd === 0) ||
                !(yStart || yStart === 0) ||
                !(yEnd || yEnd === 0)
              ) {
                return;
              }
              const dataPoint: IPoint = {
                id: id,
                xStart: xStart,
                xEnd: xEnd,
                yStart: yStart,
                yEnd: yEnd,
                value: value,
                date: firstOfMonth,
                legend: d.legend,
                title: dp.title,
                description: dp.description,
                alternativeDateString: dp.alternativeDateString,
                useAdditionalScale: d.useAdditionalScale,
              };
              // Työnnetään parsitulla dateId:llä objectiin datapiste
              dataPoints[dateId] = [...(dataPoints?.[dateId] || []), dataPoint];
              break;
            }
            case 'weekly': {
              const date = dp.date;
              const dateId = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
              // Jos idllä ja samalla kuukaudella löydetään jo datapisteet, ei tehdä duplikaatteja
              if (dateId in dataPoints && dataPoints[dateId].find((point) => point.id === id)) return;
              const dpsWithinWeek = d.dataPoints.filter(
                (dp) =>
                  dp.date.getFullYear() === date.getFullYear() &&
                  dp.date.getMonth() === date.getMonth() &&
                  dp.date.getDate() === date.getDate(),
              );
              const value = dpsWithinWeek.reduce((prev, curr) => {
                if (typeof curr.value === 'number') return prev + curr.value;
                return prev;
              }, 0);
              const firstOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const lastOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
              const xStart = xPoint(firstOfWeek);
              const xEnd = xPoint(lastOfWeek);
              const yStart = yPoint(0, sc, height);
              const yEnd = yPoint(value, sc, height);
              if (
                !(xStart || xStart === 0) ||
                !(xEnd || xEnd === 0) ||
                !(yStart || yStart === 0) ||
                !(yEnd || yEnd === 0)
              ) {
                return;
              }
              const dataPoint: IPoint = {
                id: id,
                xStart: xStart,
                xEnd: xEnd,
                yStart: yStart,
                yEnd: yEnd,
                value: value,
                date: firstOfWeek,
                legend: d.legend,
                title: dp.title,
                description: dp.description,
                alternativeDateString: dp.alternativeDateString,
                useAdditionalScale: d.useAdditionalScale,
              };
              // Työnnetään parsitulla dateId:llä objectiin datapiste
              dataPoints[dateId] = [...(dataPoints?.[dateId] || []), dataPoint];
              break;
            }
            case 'daily': {
              const date = dp.date;
              const dateId = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
              // Jos idllä ja samalla päivällä löydetään jo datapisteet, ei tehdä duplikaatteja
              if (dateId in dataPoints && dataPoints[dateId].find((point) => point.id === id)) return;
              const dateStart = date;
              const dateEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
              const xStart = xPoint(dateStart);
              const xEnd = xPoint(dateEnd);
              const yStart = yPoint(sc.customScale?.[0] ?? 0, sc, height);
              const yEnd = yPoint(dp.value, sc, height);
              if (
                !(xStart || xStart === 0) ||
                !(xEnd || xEnd === 0) ||
                !(yStart || yStart === 0) ||
                !(yEnd || yEnd === 0)
              ) {
                return;
              }
              const dataPoint: IPoint = {
                id: id,
                xStart: xStart,
                xEnd: xEnd,
                yStart: yStart,
                // Make bars with value 0 slightly visible
                yEnd: dp.value === 0 ? yEnd + 1 : yEnd,
                value: dp.value,
                date: date,
                legend: d.legend,
                title: dp.title,
                description: dp.description,
                alternativeDateString: dp.alternativeDateString,
                useAdditionalScale: d.useAdditionalScale,
              };
              // Työnnetään parsitulla dateId:llä objectiin datapiste
              dataPoints[dateId] = [...(dataPoints?.[dateId] || []), dataPoint];
              break;
            }
          }
        });
      });
  });

  const [showLine, setShowLine] = React.useState<number | null>(null);

  return (
    <g>
      {Object.entries(dataPoints).map(([key, points], index) => {
        let y = height;
        return (
          <g key={key}>
            {points.map((p) => {
              let color = colors[p.id] ?? 'black';
              if (reference) color = invertColor(color);
              y = y - Math.abs(p.yStart - p.yEnd);
              const x = Math.min(p.xStart, p.xEnd);
              const w = Math.abs(p.xStart - p.xEnd);
              const h = Math.abs(p.yStart - p.yEnd);
              return (
                <g key={key + p.id}>
                  {((additionalScale && !additionalScale.dontDrawHelperLine) || reference) && (
                    <line
                      x1={x}
                      x2={p.useAdditionalScale || reference ? '100%' : 0}
                      y1={y}
                      y2={y}
                      stroke={'black'}
                      strokeWidth="0.5"
                      strokeDasharray={5}
                      opacity={showLine === index ? 1 : 0}
                    />
                  )}
                  <GraphTooltip
                    date={p.date}
                    name={p.legend}
                    title={p.title ?? p.value.toString()}
                    description={p.description ? <GraphTooltipDescription convertedData={p.description} /> : undefined}
                    content={
                      <rect
                        id={p.id}
                        x={x}
                        y={y}
                        width={w}
                        height={h}
                        fill={color}
                        stroke={color}
                        fillOpacity={0.9}
                        strokeOpacity={1}
                        onMouseEnter={
                          additionalScale && !additionalScale.dontDrawHelperLine ? () => setShowLine(index) : () => null
                        }
                        onMouseLeave={() => setShowLine(null)}
                      />
                    }
                    alternativeDateString={p.alternativeDateString}
                  />
                </g>
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

interface IPoint {
  id: string;
  yStart: number;
  yEnd: number;
  xStart: number;
  xEnd: number;
  value: number | string;
  date?: Date;
  legend?: string;
  title?: string;
  description?: IDataPoint['description'];
  alternativeDateString?: string;
  useAdditionalScale?: boolean;
}

interface IOwnProps {
  data: IData[];
  xPoint: (date: Date) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  height: number;
  scale: TGraphScale;
  additionalScale: TGraphScale | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  reference?: true;
  interval?: 'daily' | 'weekly' | 'monthly' | 'yearly';
  numberOfOtherGraphs: number;
  graphTypeIndex: number;
}

export default StackedBarChart;
