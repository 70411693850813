import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import PlatformConditional from '../../../components/PlatformConditional';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import {
  countFunctionalAssessmentScore,
  countFunctionalCapacityScore,
  filterDocs,
  isFunctionalAssessmentComplete,
  isFunctionalCapacityComplete,
  TDocument,
} from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const getLatestFunctionalAssessment = (
  docs: Array<TDocument>,
): { date: PartialDate | undefined; value: React.JSX.Element | string | undefined } | undefined => {
  const latest = filterDocs('uhdrsFunctionalAssessment', docs)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse()[0] as IUHDRSFunctionalAssessment;
  if (!latest) return undefined;
  const dateOfLatest = latest.date;
  const valueOfLatest = isFunctionalAssessmentComplete(latest)
    ? countFunctionalAssessmentScore(latest).toString()
    : '-';
  return {
    date: dateOfLatest,
    value: valueOfLatest,
  };
};

const getLatestIndependenceScale = (
  docs: Array<TDocument>,
): { date: PartialDate | undefined; value: React.JSX.Element | string | undefined } | undefined => {
  const latest = filterDocs('uhdrsIndependenceScale', docs)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse()[0] as IUHDRSIndependenceScale;
  if (!latest) return undefined;
  const dateOfLatest = latest.date;
  const valueOfLatest = latest.value;
  return {
    date: dateOfLatest,
    value: valueOfLatest,
  };
};

const getLatestFunctionalCapacity = (
  docs: Array<TDocument>,
): { date: PartialDate | undefined; value: React.JSX.Element | string | undefined } | undefined => {
  const latest = filterDocs('uhdrsFunctionalCapacity', docs)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse()[0] as IUHDRSFunctionalCapacity;
  if (!latest) return undefined;
  const dateOfLatest = latest.date;
  const valueOfLatest = isFunctionalCapacityComplete(latest) ? countFunctionalCapacityScore(latest).toString() : '-';
  return {
    date: dateOfLatest,
    value: valueOfLatest,
  };
};

const getLatestDocsEpilepsy = (
  docs: Array<TDocument>,
): Array<IModifiedRankinScale | IModifiedChildrensGlobalAssessmentScale> => {
  const sortedArrOfLatest = [
    ...filterDocs('modifiedRankinScale', docs),
    ...filterDocs('modifiedChildrensGlobalAssessmentScale', docs),
  ]
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse();
  return sortedArrOfLatest && sortedArrOfLatest.length > 0 ? sortedArrOfLatest : [];
};

const FunctionalPerformanceDash = ({ documents }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <PlatformConditional platform={['huntington']}>
      <TileContentMaker
        type="specificList"
        data={[
          ...(getLatestFunctionalAssessment(documents)
            ? [
                {
                  date: isPartialDate(getLatestFunctionalAssessment(documents)?.date)
                    ? formatPartialDate(getLatestFunctionalAssessment(documents)?.date)
                    : '',
                  title: fm('functionalPerformance.uhdrsFunctionalAssessment'),
                  value: getLatestFunctionalAssessment(documents)?.value,
                  key: 'uhdrsFunctionalAssessment',
                },
              ]
            : []),
          ...(getLatestIndependenceScale(documents)
            ? [
                {
                  date: isPartialDate(getLatestIndependenceScale(documents)?.date)
                    ? formatPartialDate(getLatestIndependenceScale(documents)?.date)
                    : '',
                  title: fm('functionalPerformance.uhdrsIndependenceScale'),
                  value: getLatestIndependenceScale(documents)?.value,
                  key: 'uhdrsIndependenceScale',
                },
              ]
            : []),
          ...(getLatestFunctionalCapacity(documents)
            ? [
                {
                  date: isPartialDate(getLatestFunctionalCapacity(documents)?.date)
                    ? formatPartialDate(getLatestFunctionalCapacity(documents)?.date)
                    : '',
                  title: fm('functionalPerformance.uhdrsFunctionalCapacity'),
                  value: getLatestFunctionalCapacity(documents)?.value,
                  key: 'uhdrsFunctionalCapacity',
                },
              ]
            : []),
        ]}
      />
    </PlatformConditional>
    <PlatformConditional platform="epilepsy">
      <TileContentMaker
        type="recentList"
        data={getLatestDocsEpilepsy(documents).map((d) => ({
          date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
          title:
            d._type === 'modifiedRankinScale'
              ? fm('functionalPerformance.modifiedRankinScale')
              : fm('functionalPerformance.modifiedChildrensGlobalAssessmentScaleShort'),
          value: d?.rating || d?.rating === 0 ? d?.rating || '0' : null,
          key: d._id,
        }))}
      />
    </PlatformConditional>
  </React.Fragment>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default FunctionalPerformanceDash;
