import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/system';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const StyledTitle = styled('div')({
  paddingLeft: '0.6rem',
});
const StyledBottomTitle = styled('div')(({ theme }) => ({
  marginTop: '0.6rem',
  paddingLeft: '0.6rem',
  paddingTop: '0.3rem',
  borderTop: '1px solid ' + theme.palette.grey[500],
}));

const StyledValue = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  padding: '0 0.6rem 0 0.6rem',
});

const StyledBottomValue = styled('div')(({ theme }) => ({
  color: theme.customComponents.tile.fonts.normalText.secondaryColor,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  marginTop: '0.6rem',
  padding: '0.3rem 0.6rem 0 0.6rem',
  borderTop: '1px solid ' + theme.palette.grey[500],
}));

const GeneralHealthDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const sortedArr = documents?.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

  const { formatMessage } = useIntl();

  return (
    <TileContentMaker
      type="recentListMultipleRows"
      itemsCanFitAmount={1}
      data={sortedArr.map((d) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: (
          <>
            <StyledTitle>{formatMessage({ id: 'generalhealth.clinicianRatingTitle' })}</StyledTitle>
            <StyledBottomTitle>{formatMessage({ id: 'generalhealth.patientRatingTitle' })}</StyledBottomTitle>
          </>
        ),
        value: (
          <div style={{ width: '14rem' }}>
            <StyledValue>
              {d.clinicianRating
                ? `${d.clinicianRating} - ${formatMessage({
                    id: `generalhealth.opts.clinicianRating.${d.clinicianRating}`,
                  })}`
                : '-'}
            </StyledValue>
            <StyledBottomValue>
              {d.patientRating
                ? `${d.patientRating} - ${formatMessage({ id: `generalhealth.opts.patientRating.${d.patientRating}` })}`
                : '-'}
            </StyledBottomValue>
          </div>
        ),
        key: d._id,
      }))}
    />
  );
};

interface IOwnProps {
  documents: IGeneralHealth[];
}

export default GeneralHealthDash;
