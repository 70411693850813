import * as React from 'react';
import { IEvent, IItem } from 'Components/sq-graphics/interfaces';
import { Container, Item } from 'Components/Grid';
import { exists } from 'neuro-utils';
import { styled } from '@mui/system';
import colors from '../../../../config/theme/colors';

const StyledContainer = styled(Container)({
  alignItems: 'baseline',
  minHeight: '2.5rem',
  paddingRight: '0.5rem',
  borderBottom: `1px ${colors.tooltipBorderColor} solid`,
});

const StyledItem = styled(Item)({
  textAlign: 'right',
  whiteSpace: 'pre-line',
});

const StyledValue = styled('div')({
  fontWeight: 'bold',
  '& > &:not(:last-of-type)': {
    marginBottom: '0.5rem',
  },
  whiteSpace: 'pre-line',
});

export const GraphTooltipTitle = ({
  title,
  date,
  name,
  descriptionIsTable,
}: {
  title?: string;
  date?: string;
  name?: string;
  descriptionIsTable?: boolean;
}): React.JSX.Element => {
  return descriptionIsTable ? (
    <StyledContainer
      style={{
        borderBottom: `1px ${colors.tooltipBorderColor} solid`,
        minWidth: '35rem',
      }}
    >
      <Item xs={6}>
        <StyledValue style={{ fontSize: name ? '1.8rem' : '1.6rem' }}>{title}</StyledValue>
      </Item>
      <Item xs={6} style={{ textAlign: 'right', fontWeight: 600 }}>
        {date}
      </Item>
    </StyledContainer>
  ) : (
    <React.Fragment>
      <div>
        <StyledValue style={{ fontSize: name ? '1.8rem' : '1.6rem' }}>{title}</StyledValue>
      </div>
      <div>{date}</div>
    </React.Fragment>
  );
};

export const GraphTooltipDescription = ({
  convertedData,
}: {
  convertedData: IItem['description'] | IEvent['description'];
}): React.JSX.Element => {
  const getTypeOfConvertedData = (data: IItem['description'] | IEvent['description']): string =>
    React.isValidElement(data) ? 'element' : Array.isArray(data) ? 'table' : 'single';

  const dataType = getTypeOfConvertedData(convertedData);

  // Check if the value is an object that has the condition if it to be displayed
  const valueIsObject = (value: unknown): boolean =>
    typeof value === 'object' && value !== null && Object.prototype.hasOwnProperty.call(value, 'value');
  // Check if the conditional value is to be rendered
  const valueIsRendered = (value: Record<string, React.ReactNode | boolean>): boolean => value.condition !== false;

  switch (dataType) {
    case 'single':
      if (valueIsObject(convertedData)) {
        const data = convertedData as Record<string, React.ReactNode | boolean>;
        if (valueIsRendered(data)) {
          return <div style={{ whiteSpace: 'pre-line' }}>{exists(data?.value) ? data?.value : '-'}</div>;
        }
        return <></>;
      }
      return (
        <div style={{ whiteSpace: 'pre-line' }}>
          {exists(convertedData) && typeof convertedData !== 'object' ? convertedData : '-'}
        </div>
      );
    case 'table': {
      const grayLastValue: boolean =
        Array.isArray(convertedData) &&
        convertedData.some((d) => typeof d.title === 'string' && d.title.startsWith('*'));
      return (
        <React.Fragment>
          {Array.isArray(convertedData) &&
            convertedData
              ?.filter((row) => row.condition !== false)
              .map((row: any, index: number, arr: typeof convertedData) => (
                <StyledContainer
                  key={index}
                  style={{
                    borderBottom: index === arr.length - 1 ? 'none' : undefined,
                    ...(row.style ?? {}),
                    color: !(typeof row === 'object' && 'title' in row) ? colors.tertiaryText : undefined,
                  }}
                >
                  <StyledItem
                    xs={true}
                    style={{
                      textAlign: 'left',
                      color:
                        !('values' in row) || (Array.isArray(row.values) && row.values.length === 0)
                          ? undefined
                          : colors.tertiaryText,
                    }}
                  >
                    {exists(row.title) ? row.title : typeof row === 'object' && 'title' in row ? '-' : ''}
                  </StyledItem>
                  {'values' in row ? (
                    Array.isArray(row.values) ? (
                      row.values.map((column: any, i: number) =>
                        valueIsObject(column) ? (
                          valueIsRendered(column) ? (
                            <StyledItem key={i} xs={true}>
                              {exists(column.value) ? column.value : '-'}
                            </StyledItem>
                          ) : (
                            <StyledItem key={i} xs={true} />
                          )
                        ) : (
                          <StyledItem
                            key={i}
                            xs={true}
                            style={
                              grayLastValue && i === row.values.length - 1 ? { color: colors.tertiaryText } : undefined
                            }
                          >
                            {exists(column) ? column : '-'}
                          </StyledItem>
                        ),
                      )
                    ) : valueIsObject(row.values) ? (
                      valueIsRendered(row.values) ? (
                        <StyledItem xs={true}>{exists(row.values.value) ? row.values.value : '-'}</StyledItem>
                      ) : (
                        <StyledItem xs={true} />
                      )
                    ) : (
                      <StyledItem xs={true}>{exists(row.values) ? row.values : '-'}</StyledItem>
                    )
                  ) : (
                    <StyledItem />
                  )}
                </StyledContainer>
              ))}
        </React.Fragment>
      );
    }
    case 'element': {
      return convertedData as React.JSX.Element;
    }
    default:
      return <></>;
  }
};
