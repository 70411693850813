import { Container, Item } from 'Components/Grid';
import { Fragment } from 'react';

import TileContentMaker from '..';
import { DocumentsLengthIndicator, TileSubheader } from './common/commonComponents';
import { IData } from './definitions';
import { take } from 'Utility/ramdaReplacement';

const ListWithSubheaders = ({ data, props }: IListWithSubheaders) => {
  const itemsCanFitAmount = 6;

  const keys = Object.keys(data);
  let dataLenghtPossible = 0;
  let includedData: { [key: string | 'nokey']: IDataContents } = {};
  keys.forEach((k) => {
    if (dataLenghtPossible >= itemsCanFitAmount || data[k].data.length === 0) return;
    if (k === 'nokey' || dataLenghtPossible + 2 <= itemsCanFitAmount) {
      includedData = {
        ...includedData,
        [k]: {
          data: take(itemsCanFitAmount - (dataLenghtPossible + (k !== 'nokey' ? 1 : 0)), data[k].data),
          type: data[k].type,
          documentsLength: data[k].documentsLength,
          documentsLengthOfX: data[k].documentsLengthOfX,
        },
      };
      dataLenghtPossible += data[k].data.length;
    }
  });

  const includedKeys = Object.keys(includedData);

  return (
    <>
      {includedKeys.map((k) => (
        <Fragment key={k}>
          {k !== 'nokey' && (
            <TileSubheader
              title={
                <Container style={{ height: '100%' }}>
                  <Item xs={8}>{k}</Item>

                  {includedData[k].documentsLength && (
                    <Item xs={4}>
                      <Container justifyContent="flex-end">
                        <Item>
                          <DocumentsLengthIndicator
                            length={includedData[k].documentsLength || 0}
                            size={2.2}
                            fontSize={1.3}
                            ofX={includedData[k].documentsLengthOfX}
                          />
                        </Item>
                      </Container>
                    </Item>
                  )}
                </Container>
              }
            />
          )}
          <TileContentMaker type={includedData[k].type} data={includedData[k].data} {...props} />
        </Fragment>
      ))}
    </>
  );
};

type TPossibleTypes =
  | 'recentList'
  | 'specificList'
  | 'specificListMultipleRows'
  | 'recentListMultipleRows'
  | 'specificListNoDate';

type IDataContents = {
  data: Array<IData>;
  type: TPossibleTypes;
  documentsLength?: number | null;
  documentsLengthOfX?: number;
};

export interface IListWithSubheaders<TPropsType = any> {
  type: 'listWithSubheaders';
  data: {
    [key: string | 'nokey']: IDataContents; // 'nokey' should only be first in the list
  };

  props?: TPropsType;
}

export default ListWithSubheaders;
