import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';
import colors from '../../../config/theme/colors';

import { field, historyValue } from '../../../config/theme/componentTheme';
import { fm } from 'Components/FormatMessage';

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'fittedWidth',
})(({ width, fittedWidth }: { width?: number; fittedWidth?: boolean }) => ({
  maxWidth: fittedWidth ? 'none' : width ? `${width}rem` : field.width,
}));

const textElements = (
  value?: string | number,
  optionFormatter?: (name: string | number) => React.JSX.Element | string,
  width?: number,
): React.JSX.Element => {
  const stringValue = typeof value === 'number' ? `${value}` : value;
  return (
    <StyledDiv width={width}>
      {optionFormatter && stringValue && stringValue.length > 0 ? optionFormatter(stringValue) : stringValue}
    </StyledDiv>
  );
};

const SelectElements = ({
  name,
  value,
  options,
  onChange,
  optionFormatter,
  placeholder,
  width,
  minWidth,
  open,
  onOpen,
  onClose,
  autoSelect,
  autoSelectSingle,
  disabled,
  menuItemComponent,
  fittedWidth,
  clearable,
  tabulationTools,
}: Partial<IInputBasics & ISelect>): React.JSX.Element => {
  // Inject value into options if its not there
  const optionsToString = options?.map((o) => `${o}`);
  if (value && !optionsToString?.includes(value.toString())) {
    options = value || value === 0 ? [...(options || []), value] : options;
  }

  const selectOnChange = (event: { target: { name?: string | undefined; value: any } }): void | undefined => {
    event.target.name && onChange && onChange({ [event.target.name as string]: event.target.value });
  };

  React.useEffect(() => {
    // Automatically select the value if only one value, and prop is enabled
    if ((autoSelect || autoSelectSingle) && !value && options && (autoSelect || options.length === 1)) {
      selectOnChange({ target: { name, value: options[0] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const { tabIndex, inputRefs, identifier } = tabulationTools ?? {};

  React.useEffect(() => {
    if (inputRefs && typeof inputRefs === 'object' && identifier) {
      if (identifier && !inputRefs?.current[identifier]) {
        inputRefs.current[identifier] = React.createRef();
      }
    }
  });

  return (
    <StyledDiv width={width} fittedWidth={fittedWidth}>
      <Select
        inputRef={identifier ? inputRefs?.current[identifier] : undefined}
        name={name}
        value={value}
        onChange={disabled ? undefined : selectOnChange}
        fullWidth={!fittedWidth}
        displayEmpty={true}
        style={
          value === ''
            ? {
                color: colors.inputPlaceholder,
                minWidth: minWidth || minWidth === 0 ? `${minWidth}rem` : '15rem',
                maxWidth: width ? `${width}rem` : '100%',
              }
            : {
                minWidth: minWidth || minWidth === 0 ? `${minWidth}rem` : '15rem',
                maxWidth: width ? `${width}rem` : '100%',
              }
        }
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        sx={
          disabled
            ? {
                pointerEvents: 'none',
                ':before': { borderBottomColor: colors.lightGray },
                ':after': { borderBottomColor: colors.lightGray },
                '& .MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
              }
            : undefined
        }
        inputProps={{ tabIndex: disabled ? -1 : tabIndex, 'data-is-disabled': disabled }}
      >
        {placeholder && (
          <MenuItem key={name + 'placeholder'} value={''} disabled={true} style={{ fontStyle: 'italic' }}>
            {placeholder}
          </MenuItem>
        )}
        {options &&
          options.map((o) => {
            if (menuItemComponent) return menuItemComponent(o, optionFormatter);
            return (
              <MenuItem key={o} value={o}>
                {optionFormatter ? optionFormatter(o) : o}
              </MenuItem>
            );
          })}
        {clearable && (
          <MenuItem
            key={name + 'clear'}
            value={undefined}
            style={{ color: colors.error.default, justifyContent: 'center' }}
            onClick={() => {
              selectOnChange({ target: { name, value: undefined } });
            }}
          >
            {fm('general.clearSelection')}
          </MenuItem>
        )}
      </Select>
    </StyledDiv>
  );
};

const Selects = ({
  editing = false,
  value,
  optionFormatter,
  width,
  ...props
}: IInputBasics & ISelect): React.JSX.Element =>
  !editing ? (
    <div style={historyValue}>{textElements(value, optionFormatter, width)}</div>
  ) : (
    <SelectElements value={value} optionFormatter={optionFormatter} width={width} {...props} />
  );

export default Selects;
