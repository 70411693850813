import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isNumber, isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { sortDocuments } from 'Utility/randomUtil';
import { madrsTotalScore } from '../utils';

const InterviewSurveysDash = ({ documents }: { documents: Array<IMADRS & IGAF> }): React.JSX.Element => {
  const sortedDocs = documents
    ? documents.sort((a, b) => sortDocuments([{ type: 'date', sortField: 'date' }])(a, b))
    : [];

  const { formatMessage } = useIntl();

  return (
    <TileContentMaker
      type="recentList"
      data={sortedDocs.map((d) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: formatMessage({ id: `interviewSurveys.${d._type}.title` }),
        value:
          d._type === 'madrs'
            ? `${isNumber(madrsTotalScore(d)) ? madrsTotalScore(d) : ''}`
            : d._type === 'gaf'
              ? `${d.gafValue || ''}`
              : '',
        key: d._id,
      }))}
    />
  );
};

export default InterviewSurveysDash;
