import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  large?: boolean;
  active?: boolean;
}

export const IconAdministration = ({
  strokeColor,
  strokeWidth,
  fillColor,
  large = false,
  active = false,
}: IOwnProps): React.JSX.Element => {
  const [points, setPoints] = React.useState<string>(
    large ? '-8,-1 8,-1 1,7 8,14.5 -8,14.5 -1,7' : '-6,1.5 6,1.5 1,7 6,12.5 -6,12.5 -1,7',
  );

  const mouseEnter = (): void => {
    if (active) {
      setPoints('-9,-1.5 9,-1.5 1,7 9,15.5 -9,15.5 -1,7');
    }
  };

  const mouseLeave = (): void => {
    setPoints(large ? '-8,-1 8,-1 1,7 8,14.5 -8,14.5 -1,7' : '-6,1.5 6,1.5 1,7 6,12.5 -6,12.5 -1,7');
  };

  return (
    <g transform="translate(0,-6) scale(0.9)" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <polygon points={points} stroke={strokeColor} strokeWidth={strokeWidth} fill={fillColor} />
    </g>
  );
};
