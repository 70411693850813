import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import PlatformConditional from '../../../components/PlatformConditional';

import { sortAndTakeLatest, TDocument } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';

const fm = (id: string, values?: { [key: string]: string | number | undefined }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const formatDate = (d?: PartialDate) => (isPartialDate(d) ? formatPartialDate(d) : '');

const ClinicalStudyDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const smadmdDocTypes = ['fvc', 'enmg', 'nocturnalPolygraphy'];
  const msDocTypes = ['vep', 'sep', 'baep', 'octscan', 'ecg', 'echocardiography', 'bloodPressure', 'heartRate'];

  const msData: IData[] = [];

  sortAndTakeLatest(msDocTypes, documents)?.forEach((d, i) => {
    switch (d._type) {
      case 'vep':
        msData.push({ date: formatDate(d.date), title: fm('clinicalstudy.vep'), key: 'vep' + i });
        break;
      case 'sep':
        msData.push({ date: formatDate(d.date), title: fm('clinicalstudy.sep'), key: 'sep' + i });
        break;
      case 'baep':
        msData.push({ date: formatDate(d.date), title: fm('clinicalstudy.baep'), key: 'baep' + i });
        break;
      case 'octscan':
        msData.push({
          date: formatDate(d.date),
          title: fm('clinicalstudy.octscan'),
          key: 'octscan' + i,
          value: (d as IOCTSCAN)?.result
            ? fm(`clinicalstudy.opts.normalBorderlineAbnormal.${(d as IOCTSCAN)?.result}`)
            : '-',
        });
        break;
      case 'ecg':
        msData.push({
          date: formatDate(d.date),
          title: fm('clinicalstudy.ecg'),
          key: 'ecg' + i,
          value: (d as IECG)?.result ? fm(`clinicalstudy.opts.normalBorderlineAbnormal.${(d as IECG)?.result}`) : '-',
        });
        break;
      case 'echocardiography':
        msData.push({
          date: formatDate(d.date),
          title: fm('clinicalstudy.echocardiographyShort'),
          key: 'echocardiographyShort' + i,
          value: (d as IEchocardiography)?.result
            ? fm(`clinicalstudy.opts.normalBorderlineAbnormal.${(d as IEchocardiography)?.result}`)
            : '-',
        });
        break;
      case 'bloodPressure':
        msData.push({
          date: formatDate(d.date),
          title: fm('clinicalstudy.bloodPressure'),
          key: 'bloodPressure' + i,
          value: (
            <div>
              {fm('clinicalstudy.bloodPressureUnit', {
                n:
                  ((d as IBloodPressure)?.bloodPressureSystolic ? (d as IBloodPressure)?.bloodPressureSystolic : '-') +
                  ' / ' +
                  ((d as IBloodPressure)?.bloodPressureDiastolic ? (d as IBloodPressure)?.bloodPressureDiastolic : '-'),
              })}
            </div>
          ),
        });
        break;
      case 'heartRate':
        msData.push({
          date: formatDate(d.date),
          title: fm('clinicalstudy.heartRate'),
          key: 'heartRate' + i,
          value: (d as IHeartRate)?.heartRate
            ? fm('clinicalstudy.heartRateUnit', { n: (d as IHeartRate)?.heartRate })
            : '-',
        });
        break;
      default:
        break;
    }
  });

  return (
    <React.Fragment>
      <PlatformConditional platform={['sma', 'dmd']}>
        <TileContentMaker
          type="recentList"
          data={sortAndTakeLatest(smadmdDocTypes, documents).map((d, i) => {
            switch (d._type) {
              case 'fvc': {
                const doc = d as IFVC;
                return {
                  date: isPartialDate(doc.date) ? formatPartialDate(doc.date) : '',
                  title: fm('clinicalstudy.fvcShort'),
                  value: (
                    <div>
                      {doc.litres ? fm('clinicalstudy.litreUnit', { n: doc?.litres }) : '-'}
                      {' / '}
                      {doc?.predicted ? fm('clinicalstudy.percentageUnit', { n: doc?.predicted }) : '-'}
                    </div>
                  ),
                  key: 'fvc' + i,
                };
              }
              case 'enmg': {
                return {
                  date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                  title: fm('clinicalstudy.enmg'),
                  value: <>&nbsp;</>,
                  key: 'enmg' + i,
                };
              }
              default:
              case 'nocturnalPolygraphy': {
                return {
                  date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                  title: fm('clinicalstudy.nocturnalPolygraphy'),
                  value: <>&nbsp;</>,
                  key: 'nocturnalPolygraphy' + i,
                };
              }
            }
          })}
        />
      </PlatformConditional>
      <PlatformConditional platform={'ms'}>
        <TileContentMaker type="recentList" data={msData} />
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ClinicalStudyDash;
