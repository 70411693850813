import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  large?: boolean;
  active?: boolean;
  priority?: 'low' | 'normal' | 'high' | undefined;
  addon?: boolean;
}

export const IconChange = ({
  strokeColor,
  strokeWidth,
  fillColor,
  active = false,
  priority = 'low',
  addon = false,
}: IOwnProps): React.JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  return priority === 'low' ? (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.77734 0.707131L14.8484 7.7782L7.77734 14.8493L0.706276 7.7782L7.77734 0.707131Z"
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      </svg>
    </g>
  ) : priority === 'normal' ? (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="7.77734"
          y="0.707107"
          width="10"
          height="10"
          transform="rotate(45 7.77734 0.707107)"
          fill={fillColor}
          strokeWidth={strokeWidth}
          stroke={strokeColor}
          strokeDasharray="1 2 4 2 1 0 1 2 4 2 1 0 1 2 4 2 1 0 1 2 4 2"
        />
      </svg>
    </g>
  ) : (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.77734 0.707131L14.8484 7.7782L7.77734 14.8493L0.706276 7.7782L7.77734 0.707131Z"
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
        <rect
          x="7.5"
          y="5.5"
          width="3.3"
          height="3.3"
          transform="rotate(45 7.85352 5.70711)"
          fill={strokeColor}
          stroke={strokeColor}
          strokeWidth="1.5"
        />
      </svg>
    </g>
  );
};
