import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PlatformConditional from '../../../components/PlatformConditional';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { sortAndTakeLatest } from '../utils/index';
import { TDocument } from '../utils/index';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const BehaviourDash = ({ documents }: IOwnProps): React.JSX.Element => (
  <PlatformConditional platform={['huntington']}>
    <TileContentMaker
      type="recentList"
      data={sortAndTakeLatest(['pbaS'], documents).map((d) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: fm('behaviour.pbaS.pbaS'),
        key: d._id,
      }))}
    />
  </PlatformConditional>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default BehaviourDash;
