import * as React from 'react';
import { formatPartialDate, isPartialDate, sortPartialDate /* formatPartialDate, isPartialDate */ } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const _BPDash = ({ documents }: IOwnProps): React.JSX.Element | undefined => {
  const sortedArr = documents
    ?.sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse();
  return (
    <TileContentMaker
      type="recentList"
      data={sortedArr.map((d) => ({
        key: d._id,
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: '',
        value: '',
      }))}
    />
  );
};

interface IOwnProps {
  documents: Array<any>;
}

export default _BPDash;
