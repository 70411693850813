import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate } from 'neuro-utils';

import { sortDocuments } from '../../../utility/randomUtil';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string | number | null }, key?: string): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} key={key || undefined} />
);

const categories: Array<{ name: string; items: Array<keyof ISRChecklist> }> = [
  {
    name: 'medicationTile',
    items: [
      'medicationInhaler',
      'medicationInhalerTechniqueChecked',
      'medicationOralOpioidPrescription',
      'medicationOtherSymptomatic',
      'medicationOther',
    ],
  },
  {
    name: 'weightControlTile',
    items: ['weightControlOverOrUnderWeight', 'weightControlInstructionsGiven'],
  },
  {
    name: 'treatmentsTile',
    items: [
      'treatmentsLivingWillFormGiven',
      'treatmentsPolicyNeeded',
      'treatmentsPolicyDone',
      'treatmentsPalliativePolicyDiscussed',
      'treatmentsPalliativePolicyInformed',
    ],
  },
  {
    name: 'instructionalLeafletTile',
    items: ['instructionalLeafletGiven'],
  },
  {
    name: 'specialConsultationTile',
    items: ['specialConsultationNeeded', 'specialConsultationContactsGiven'],
  },
  {
    name: 'futureVisitsTile',
    items: ['futureVisitsNotNeededBadHealth', 'futureVisitsCancelled'],
  },
];

const getValue = (items: Array<keyof ISRChecklist>, document: ISRChecklist) => {
  const total = items.length;

  const filled = items.filter((i) =>
    i === 'treatmentsPolicyDone' || i === 'specialConsultationContactsGiven'
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document[i] && Array.isArray(document[i]) && document[i]!.length > 0
      : document[i],
  );
  return `${filled.length} / ${total}`;
};

const ChecklistDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const sortedDocs =
    documents && documents.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2));

  const recentDoc = sortedDocs && sortedDocs.length > 0 && sortedDocs[0];

  return (
    <>
      {recentDoc && (
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={isPartialDate(recentDoc.date) ? formatPartialDate(recentDoc.date) : ''}
          data={categories.map((c) => ({
            title: fm('checklist.' + c.name),
            value: getValue(c.items, recentDoc),
            key: c.name,
          }))}
        />
      )}
    </>
  );
};

interface IOwnProps {
  documents: ISRChecklist[];
}

export default ChecklistDash;
