import { IntlShape } from 'react-intl';

export type TDescriptionValue = string | number | boolean | Record<string, unknown> | React.JSX.Element | undefined;

type TDescriptionData = TDescriptionValue | { value: TDescriptionValue; condition?: boolean };

// Array element equals table row
export type TDescriptionTable = Array<{
  // Row title (leftmost column)
  title?: string | React.JSX.Element;
  // Values (columns) to follow the title
  values?: TDescriptionData | Array<TDescriptionData>;
  // Set condition to false to hide row
  condition?: boolean;
  // Row style to apply
  style?: { [key: string]: string | number };
}>;

export type TGraphData = {
  // Common menus of the graph menu
  common: {
    [key: string]: IGraphData;
  };
  // Additional menus of the graph menu. Potilaan omaraportoinnit for example belongs here
  [key: string]: {
    [key: string]: IGraphData;
  };
};

export interface IDataPoint {
  /** Date is used to place the datapoint in x-direction */
  date: Date;
  time?: Time;
  /** Value is used to place the datapoint in y-direction */
  value: string | number;
  /** Id is used to classify the datapoints. Every id should match a key in graphs of the graphData-object */
  id: string;

  // Optional parameters below
  /** Additional value to be used with the value eg. in box graph on heat graph */
  additionalValue?: string | number;
  /** End date is used eg. in constantValueChart */
  endDate?: Date;
  /** Tooltip title. Shown instead of value in the tooltip */
  title?: string;
  /** Tooltip description */
  description?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>;
  /** Alternative date style to be used for example in tooltips */
  alternativeDateString?: string;
  /** In which shape to show the datapoint. Default circle */
  pointType?: 'rect' | 'circle';
  /** Hide value (used to place the datapoint in y-direction) from tooltip */
  hideValue?: boolean;
  /** Text used to replace or modify associated legend */
  legendModifier?: string | { prefix?: string; suffix?: string };
}

export interface IData {
  id: string;
  dataPoints: Array<IDataPoint>;
  /** Lähtökohtana se, että eri graafeja voi miksata. Pitää kuitenkin kehitellä jonkinlainen validaatio, jotta esim hetaGraphin kanssa ei voi olla muita */
  type: 'lineGraph' | 'constantValueChart' | 'boxGraph' | 'stackedBarChart' | 'stackedAreaChart' | 'heatGraph';
  legend: string;
  legendDescription?: {
    title?: string;
    description?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>;
  };
  extraLegends?: string[];
  // If additional scale is wanted to be used, add this parameter to the idata object
  useAdditionalScale?: true;
  // Graph type specific props can be given here
  linegraphProps?: {
    defaultToZero?: boolean;
    stepLine?: boolean;
    doNotMergeOverlappingDataPoints?: true;
    xAxisLineBreaks?: Array<Date>;
    hidePoints?: boolean;
  };
  stackedBarChartProps?: {
    interval?: 'daily' | 'weekly' | 'monthly' | 'yearly';
  };
  label?: string;
}

export type TGraphScale = {
  // ID is used to connect the data points to the scale. This should match the scaleId of IData object
  id?: string;
  type: 'linear' | 'custom' | 'hybridCL' | 'hybridLC';
  // If scaleType is linear and no linearScale is provided, the scale is derived from the data points
  linearScale?: [number, number];
  customScale?: string[];

  dontDrawHelperLine?: true;
  tickAmount?: number;
  ticksToDraw?: number[];
  // Can be used to format scale titles
  formatter?: (title: string) => string;
  // Can be used to force linearscale to be something else than [0, max (rounded to upper tens)]
  linearScaleCalculator?: (max: number, min?: number) => [number, number];
};

export interface IGraphData {
  data: Array<IData | { data: IData[]; id: string; label?: string }>;
  scale: TGraphScale;
  additionalScale?: TGraphScale;

  // Metatiedot alla
  title: string;
  titleDescription?: {
    title?: string;
    description?: string;
  };

  alwaysShowLegends?: true;

  // Function for filtering out some dataPoints
  // Tekeeks tällä mitään
  // Kai kaikki datapisteet pitäis oletuksena piirtää
  renderDataPointOrNot?: (dataPoints: IDataPoint[], timeframe?: [Date, Date]) => boolean;
}

export type TAddonData = {
  [key: string]: IAddonData;
};

export type TAddonOption = {
  type: 'reload' | 'checkbox' | 'radio';
  title?: string;
  // onClick: () => void;
  options?: string[];
  selectedOption?: string;
  optionFormatter?: (o: string) => string;
  onOptionClick?: (o: string) => void;
};

export type TAddonTheme = {
  title: string;
  primary: string;
  priorityColors?: {
    low?: string;
    normal?: string;
    high?: string;
  };
};

export type TSingleAddonTheme = {
  divider: string;
  addon: TAddonTheme;
};

export type TExpansionPanelTheme = {
  // Color of the divider
  divider: string;
  // Color of the title
  title: string;
  // Default theme for a single addon inside the expansion panel
  addon: TAddonTheme;
};

export type TGraphMenuTheme = {
  color: { default: string; light: string; lightest: string };
  background: string;
  disabled: string;
};

type TSettings = {
  theme?: keyof typeof AddonColors | string;
  order?: string[];
};

enum AddonColors {
  stellarqMy = '2D7B83',
  mystic = '#D95084',
  dollarBill = '#8FC066',
  interdimensionalBlue1 = '#5720D0',
  aero = '#75B0E7',
  vividOrange = '#FF5C00',
  skyBlue = '#77D9E2',
  darkRed = '#8D0000',
  seaSerpent = '#57C9D0',
  interdimensionalBlue2 = '#5710D0',
  hotMagenta = '#EB17CB',
  mediumSlateBlue = '#7A5ADD',
  red = '#FF0000',
  caputMortuum = '#4F2420',
  bluePigment = '#4020A0',
  sunGlow = '#FFC433',
  darkCharcoal = '#303030',
  bitterLemon = '#CFD600',
  airForceBlue = '#103090',
  blueYonder = '#4074A9',
  egyptianBlue = '#2024A9',
  forestGreen = '#208419',
  blueBonnet = '#091EDE',
  darkCyan = '#0A828F',
  delightfulPeach = '#ffc384',
  uglyPurple = '#703877',
  darkCyan2 = '#048B82',
  cafeAuLait = '#AD8159',
  flirt = '#960063',
  warningDefault = '#DD8500',
  warningLight = '#FF9900',
}

enum GraphColors {
  // NOTE: Graph (linegraph, etc) colors are taken from these in order from top to bottom. (unless specified in config.ts/graphSettings)
  // This means that changing the order of these colors changes the experience of using the graph.
  deepKoamaru = '#2B3870', // dark blue
  warningDefault = '#DD8500', // yellow
  darkSkyBlue = '#86C8D2', // light blue
  flirt = '#960063', // burgundy
  darkCyan = '#048B82',
  yellowMunsell = '#F2C900',
  harvardCrimson = '#C20012',
  oliveDrap = '#7BA015',
  bloodRed = '#6D0000',
  pear = '#D7E81D',
  royalPurple = '#854EAF',
  metallicGreen = '#3C6B00',
  tulip = '#FF808C',
  cafeAuLait = '#AD8159',
  lightGreen = '#7AE197',
  warningLight = '#FF9900',
}

// TODO
export type TGraphSettings = {
  /** Addons that should be shown on the top of the graph should be listed here */
  topDataAddons?: string[];
  // If true, graph menus with no data are hidden
  graphMenusToBeHiddenIfEmpty?: string[];
  timeframeSelectorsHiddenByDefault?: boolean;
  // If true, instead of drawing reference graph in the same graph as the selected graph, the reference graph(s) are drawn below each other
  drawReferenceGraphsBelowEachOther?: boolean;

  layout?: 'tight' | 'normal' | 'loose';

  defaultTimeframeLength: TTimeframeLengthOption | 'all';
  defaultTimeframe: [Date, Date] | null;

  reduxStateSelectedGraph?: string | null;

  // All the timeframe lengths that can be selected
  timeframeLengthOptions?: TTimeframeLengthOption[];
  // Lists of ids of graphs and addons that should be hidden
  hiddenAddons?: string[];
  hiddenGraphs?: string[];

  expansionPanelsHiddenByDefault?: string[];

  // Addon specific settings
  // This can either be settings of a higher level group
  // or just settings of a single addon in an expansion panel
  addonSettings?: {
    [key: string]: TSettings;
  };

  // Graph specific settings
  // This can either be settings of a higher level group
  // or just settings of a single graph menu item
  graphSettings?: {
    colors?: {
      [key: string]: keyof typeof GraphColors;
    };
    helperLines?: THelperLine;
  };
  // Graph menu specific settings
  // This can either be setting of a higher level group
  // Or just settings of a single graph menu item
  graphMenuSettings?: {
    [key: string]: TSettings;
  };

  medicationSettings?: IOrganizationNeuroSettings['medicationSettings'];
};

export type THelperLine = {
  x?: Array<{
    id: string;
    lines: Array<{ value: number | string; color: string; drawArea: boolean; title?: { fi: string; en: string } }>;
  }>;
  y?: Array<{
    id: string;
    lines: Array<{ value: number | string; color: string; drawArea: boolean; title?: { fi: string; en: string } }>;
  }>;
};

export interface IDashboardGraphProps {
  // Some general settings
  width: number;
  paper: boolean;

  // Miscellanous stuff
  totalTimeframe: [Date, Date];
  reloadGraph: () => void;
  setGraphSelections: (selections: ISessionStore['graphSelections']) => void;

  // Data
  graphData: TGraphData;
  graphDataGroups:
    | {
        // Group name
        [key: string]: {
          /** Whether group is open when graph first opened */
          open?: boolean;
          /** Localized string shown as group header */
          groupHeader: string;
          /** Theme identifier, use default if undefined */
          theme?: string;
        };
      }
    | null
    | undefined;
  graphMenuSettings:
    | {
        onlyOneOpen?: true;
      }
    | null
    | undefined;
  addonData: TAddonData;

  metaLocalizations: {
    selectedTimeframe: string;
    hideTimeframeSelectors: string;
    hideRuler: string;
    zoomIn: string;
    zoomOut: string;
    default: string;
    timeframeLengthOptions: {
      all: string;
      custom: string;
      '5y': string;
      '3y': string;
      '2y': string;
      '1y': string;
      '9m': string;
      '6m': string;
      '3m': string;
      '2m': string;
      '1m': string;
      '14d': string;
      '7d': string;
      '3d': string;
    };
  };

  // Settings for the graph and an ability to modify them
  settings: TGraphSettings;
  editSettings?: (field: string, value: string | number | string[] | number[]) => void;
  intl: IntlShape;
  platform: Platform | null;
}

export type TXTick = { x: number; value: string; priority?: 'low' | 'normal' | 'high' };

type TPlatformKeys = { [key in Platform]?: number };
export interface IHeights extends TPlatformKeys {
  default: number;
}

export interface IGraphDimensions {
  paddingTop: number;
  paddingBottom: number;
  timeframeSelector: {
    selectors: {
      height: number;
    };
    labels: {
      height: number;
    };
  };
  leftColumn: {
    width: number;
    paddingLeft: number;
  };
  rightColumn: {
    width: number;
    paddingRight: number;
  };
  graphs: {
    marginTop: number;
    marginBottom: number;
    scaleArea: {
      width: number;
    };
  };
  addons: {
    marginTop: number;
    marginBottom: number;
    divider: {
      width: number;
    };
  };
  timeline: {
    height: number;
    marginTop: number;
    marginBottom: number;
  };
  legends: {
    paddingTop: number;
    paddingBottom: number;
    marginTop: number;
    marginBottom: number;
  };
  graphHeights: IHeights;
}

export interface IItem {
  start: Date;
  end?: Date;
  title: string;
  // Matrix that should consist of some kind of key-value pairs or so
  description?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>; // ??
  // With this can html-elements be passed to tooltip
  additionalInformation?: React.JSX.Element;
  // Used for example in dbs treatment when there are pausations
  alternativeDateString?: string;
  // Override default addon color
  themeId?: string;
}

export interface ILegend {
  onClick?: () => void;
  id: string;
  legend: string;
  legendDescription?: {
    title?: string;
    description?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>;
  };
  icon?: React.JSX.Element;
  color?: string;
  isExtra?: boolean;
}

type TEventType =
  | 'text' // Text with small indicator box
  | 'start' // Diamond
  | 'pause' // Diamond
  | 'discontinuation' // Diamond
  | 'continuation' // Diamond
  | 'controlVisit' // Diamond
  | 'end' // Diamond
  | 'appointment' // Diamond
  | 'regimen' // Diamond
  | 'adverseEffect' // Circle
  | 'administration' // Time glass
  | 'infusion' // Diamond
  | 'settings' // Diamond
  | 'modification' // Diamond
  | 'technicalIssue' // Triangle
  | 'removal' // Diamond
  | 'dynamic' // ?
  | 'study' // Diamond
  | 'treatment' // Diamond
  | 'implantation' // Diamond
  | 'criteria' // Diamond
  | 'symptom' // Diamond
  | 'selfReport' // Diamond
  | 'activity' // Filled rectangle
  | 'themedDiamondWithText'; // Themed diamond with optional text;

export type TEventPriority = 'low' | 'normal' | 'high';

export interface IEvent {
  date: Date;
  // Used with dynamic datapoints ?_?
  endDate?: Date;
  time?: Time;

  // Can be left undefined by default, but if we want to make a specific event type customisable, define this
  eventType: TEventType;
  // This is only taken into action if priority is something else than low
  eventTypeDescription?: string;
  // Can be left undefined by default.
  priority?: TEventPriority;

  title?: string;

  secondaryTitle?: string;
  // Based on this string matrix we will create a table in the tooltip component
  description?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>;
  // If there is a need to show any specific svg/html or so, use this
  additionalInformation?: React.JSX.Element;
  // Optional text for the icon
  iconText?: string;
}

export interface IAddon {
  id: string; // Used to combine duplicate addons
  themeId?: string;
  title: string;
  titleDescription?: string | React.JSX.Element;
  items?: Array<IItem>;
  events?: Array<IEvent>;
}

export interface IAddonData {
  id: string;
  type: 'single' | 'expansionPanel';
  name: string | React.JSX.Element; // Mitä tällä tehään? Piirretään expansion paneleitte title ainaki?
  expansionPanelTitleDescription?: TDescriptionData | TDescriptionTable | Promise<TDescriptionData | TDescriptionTable>;
  addons?: Array<IAddon>; // In case of a single-type addon, there should be only one item in the list
  addonOptions?: TAddonOption[];
  legend?: { type: string; titles: Array<string> };
  expansionPanelShowAlways?: boolean;
}

// Interface for defining configs for the disease history
export interface IDiseaseHistoryData {
  // Graph data rendered as graphs
  graphData: IDashboardGraphProps['graphData'];
  // Possible grouping settings for graph data
  graphDataGroups?: IDashboardGraphProps['graphDataGroups'];
  // Some additional settings for menu
  graphMenuSettings?: IDashboardGraphProps['graphMenuSettings'];
  // Addon data rendered under the graph
  addonData: IDashboardGraphProps['addonData'];
}

export { GraphColors, AddonColors };
