import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { sortPartialDate, formatPartialDate, isPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';

import PlatformConditional from '../../../components/PlatformConditional';

import { TDocument, convertTrendToIcon } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';

const Spacer = styled('span')({
  padding: '0 0.5rem',
});

const makeMRIData = (title: IData['title'], value: IData['value'], key: string, icon?: React.JSX.Element | null) => [
  {
    title,
    value: icon ? (
      <span>
        {value}
        <Spacer />
        {icon}
      </span>
    ) : (
      value
    ),
    key,
  },
];

const ImagingDash = ({ documents }: IOwnProps): React.JSX.Element | undefined => {
  const sortedArr = documents
    ?.sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
    .reverse();
  // SMA studies
  const smaMriArr = sortedArr?.filter((item: TDocument) => item._type === 'mri');
  const smaArr = sortedArr
    ?.filter((item: TDocument) => item._type !== 'echocardiography')
    .filter((item: TDocument) => item._type !== 'mri')
    .concat(smaMriArr);

  // DMD studies
  const dmdArr = sortedArr?.filter((item: TDocument) => item._type !== 'mri');

  // MS studies
  const msArr = sortedArr?.filter((item: TDocument) => item._type === 'mri');

  // Parkinson studies
  const parkinsonMriArr = sortedArr?.filter((item: TDocument) => item._type === 'mri');
  const parkinsonArr = sortedArr
    ?.filter((item: TDocument) => item._type === 'tt' || item._type === 'scintigraphy')
    .concat(parkinsonMriArr);

  const ninmtMriArr = sortedArr?.filter((item: TDocument) => item._type === 'mri');

  // MG studies
  const mgravisArr: (IThymusCT | IThymusMRI)[] = sortedArr?.filter(
    (item: TDocument) => item._type === 'thymusCt' || item._type === 'thymusMri',
  );

  const imagingStudy = (document: TDocument): React.JSX.Element => (
    <React.Fragment>
      <PlatformConditional platform={'ms'}>
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={<FormattedMessage id={`imaging.${document?._type}`} />}
          bigHeaderValue={isPartialDate(document?.date) ? formatPartialDate(document?.date) : ''}
          data={[
            ...('tesla' in document
              ? makeMRIData(<FormattedMessage id={'imaging.tesla'} />, document?.tesla, 'tesla')
              : []),
            ...('brainT2Flair' in document
              ? makeMRIData(
                  <FormattedMessage id={'imaging.brainT2FlairInfraShort'} />,
                  document.brainT2Flair?.infratentorial?.lesions,
                  'brainT2FlairInfraShort',
                  convertTrendToIcon(document.brainT2Flair?.infratentorial?.lesionsTrend),
                )
              : []),
            ...('brainT2Flair' in document && document.brainT2Flair?.supratentorial
              ? makeMRIData(
                  <FormattedMessage id={'imaging.brainT2FlairSupraShort'} />,
                  document.brainT2Flair.supratentorial.lesions,
                  'brainT2FlairSupraShort',
                  convertTrendToIcon(document.brainT2Flair.supratentorial.lesionsTrend),
                )
              : []),
            ...('brainT1' in document && document.brainT1?.total
              ? makeMRIData(
                  <FormattedMessage id={'imaging.brainT1TotalShort'} />,
                  document.brainT1.total.lesions,
                  'brainT1TotalShort',
                  convertTrendToIcon(document.brainT1.total.lesionsTrend),
                )
              : []),
            ...('brainT1' in document && document.brainT1?.gadoliniumEnhanced
              ? makeMRIData(
                  <FormattedMessage id={'imaging.brainT1GadoliniumShort'} />,
                  document.brainT1.gadoliniumEnhanced.lesions,
                  'brainT1GadoliniumShort',
                  convertTrendToIcon(document.brainT1.gadoliniumEnhanced.lesionsTrend),
                )
              : []),
            ...('spinalCordT2' in document && document.spinalCordT2?.total
              ? makeMRIData(
                  <FormattedMessage id={'imaging.spinalCordT2TotalShort'} />,
                  document.spinalCordT2.total.lesions,
                  'spinalCordT2TotalShort',
                  convertTrendToIcon(document.spinalCordT2.total.lesionsTrend),
                )
              : []),
          ]}
        />
      </PlatformConditional>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <PlatformConditional platform={'sma'}>
        <TileContentMaker
          type="recentList"
          data={smaArr.map((s) => ({
            date: formatPartialDate(s.date),
            title: <FormattedMessage id={`imaging.${s?._type}`} />,
            key: s._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform={'dmd'}>
        <TileContentMaker
          type="recentList"
          data={dmdArr.map((s) => ({
            date: formatPartialDate(s.date),
            title: <FormattedMessage id={`imaging.${s?._type}`} />,
            key: s._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform={'ms'}>
        <>{msArr?.length > 0 && imagingStudy(msArr[0])}</>
      </PlatformConditional>
      <PlatformConditional platform={'parkinson'}>
        <TileContentMaker
          type="recentList"
          data={parkinsonArr.map((s) => ({
            date: isPartialDate(s.date) ? formatPartialDate(s.date) : '',
            title: <FormattedMessage id={`imaging.${s?._type}`} />,
            key: s._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform="ninmt">
        <TileContentMaker
          type="recentList"
          data={ninmtMriArr.map((s) => ({
            date: formatPartialDate(s.date),
            title: <FormattedMessage id={`imaging.${s?._type}`} />,
            key: s._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform="mgravis">
        <TileContentMaker
          type="recentList"
          data={mgravisArr.map((s) => ({
            date: isPartialDate(s.date) ? formatPartialDate(s.date) : '',
            title: <FormattedMessage id={`imaging.${s?._type}`} />,
            value: (
              <React.Fragment>
                {Array.isArray(s.finding) && s.finding.length > 0 ? (
                  s.finding.map((f, i, arr) => (
                    <span key={i}>
                      <FormattedMessage id={`imaging.opts.finding.${f}`} />
                      {i < arr.length - 1 ? ', ' : ''}
                    </span>
                  ))
                ) : s.finding ? (
                  <FormattedMessage id={`imaging.opts.finding.${s.finding}`} />
                ) : (
                  '-'
                )}
              </React.Fragment>
            ),
            key: s._id,
          }))}
        />
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ImagingDash;
