import * as React from 'react';
import { styled } from '@mui/system';
import { Paper, PaperProps } from '@mui/material';
import { Error, CheckCircle } from '@mui/icons-material';
import { useIntl } from 'react-intl';

import { Container, Item } from 'Components/Grid';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { useAppSelector as useSelector } from 'Store/index';
import { equals } from 'ramda';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import colors from '../../../config/theme/colors';
import theme from '../../../config/theme/muiTheme';
import Icon from '../../_Dashboard/Icon/index';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';
import { surveysByPlatform } from '../Document/config';
import { getMySQUsageStatus } from '../util';

const Tile = styled(({ id, ...other }: { id: string } & PaperProps) => <Paper id={id} {...other} />)(({ theme }) => ({
  width: theme.customComponents.tile.width,
  height: '29rem',
  margin: theme.customComponents.tile.margin,
  padding: `0 ${theme.customComponents.tile.padding}`,
  '&:hover': {
    backgroundColor: '#f8f8f8',
  },
  overflow: 'hidden',
  userSelect: 'none',
}));

const HeaderText = styled('div')({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 600,
  fontSize: '1.6rem',
  textTransform: 'uppercase' as const,
});

const MyServTile = ({ isClickable, fm }: { isClickable: boolean; fm: (id: string) => string }): React.JSX.Element => {
  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);
  const inviteAndTaskData: Array<IData> = [];
  const [dashData, setDashData] = React.useState<IData[]>([]);
  const patientMySQStatus = useSelector((s: IState) => s.myapp.mysqUserId);
  const delegates = useSelector((s: IState) => s.patient.delegates);

  const mysqStatus = getMySQUsageStatus(patientMySQStatus, delegates);

  const taskLists = useSelector((s: IState) => s.myapp.taskLists) || [];
  const platform = useSelector((s: IState) => s.session.platforms?.selected);
  const locale = useSelector((s: IState) => s.settings?.userSettings?.uiLanguage);
  const taskUiSettings = useSelector((s: IState) => s.settings?.orgSettings?.settings?.taskUiSettings);
  const platformSettings = platform ? taskUiSettings?.[platform] : undefined;

  if (sentInvites && sentInvites.length > 0) {
    sentInvites.forEach((si, i) => {
      inviteAndTaskData.push({
        date: si.timestamp ? formatPartialDate(partialDateFromDate(new Date(si.timestamp))) : '-',
        title: fm('myService.inviteSentDash'),
        key: `inv-${i}`,
      });
    });
  }

  React.useEffect(() => {
    if (taskLists.length > 0) {
      const currPlatformTasks = taskLists.filter(
        (t) => t?.tasks?.every((t) => platform && surveysByPlatform[platform]?.includes(t)),
      );
      currPlatformTasks.forEach((t, i) => {
        const taskListDashData = {
          date: t.deadline ? formatPartialDate(t.deadline) : '-',
          title: `${fm('myService.taskList')}: ${t.title ?? '-'}`,
          key: `${t.title}-${i}`,
        };
        if (inviteAndTaskData.includes(taskListDashData)) return;
        inviteAndTaskData.push({
          date: t.deadline ? formatPartialDate(t.deadline) : '-',
          title: `${fm('myService.taskList')}: ${
            t.title && locale
              ? platformSettings?.[t.title]?.titleLocalized[locale]
                ? platformSettings[t.title]?.titleLocalized[locale]
                : platformSettings?.[t.title]?.titleLocalized['fi']
                  ? platformSettings[t.title]?.titleLocalized['fi']
                  : t.title === 'other'
                    ? fm('myService.opts.other')
                    : t.title
              : '-'
          }`,
          key: `${t.title}-${i}`,
        });
      });
    }
  }, [taskLists]);

  React.useEffect(() => {
    if (equals(dashData, inviteAndTaskData) || dashData.length > inviteAndTaskData.length) return;
    setDashData(
      inviteAndTaskData.sort((a, b) => {
        if (a.date && b.date && a.date !== '-' && b.date !== '-') {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        } else if (a.date === '-') {
          return 1;
        } else if (b.date === '-') {
          return -1;
        } else return 0;
      }),
    );
  }, [inviteAndTaskData]);

  return (
    <Container
      direction={'column'}
      style={{
        paddingTop: theme.customComponents.tile.padding,
        paddingBottom: theme.customComponents.tile.padding,
      }}
      rowSpacing={'0rem'}
      height={'100%'}
    >
      <Item xs={2} style={{ color: colors.myms.primary }}>
        <HeaderText>
          <div
            style={{
              marginRight: '1rem',
              background: `linear-gradient(${colors.primary}, #048B82)`,
              width: '3.8rem',
              height: '3.8rem',
              borderRadius: '5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon name={'myServiceV2'} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1.6rem' }}>
            <span style={{ textTransform: 'none', fontSize: theme.typography.fontSize }}>
              {fm('myService.appName')}
            </span>
            <span>{fm('myService.titleShort')}</span>
          </div>
        </HeaderText>
      </Item>
      {mysqStatus ? (
        <Item
          xs={8}
          style={
            !isClickable
              ? { textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }
              : { paddingTop: '1rem' }
          }
        >
          {!isClickable ? (
            <>
              <div style={{ fontWeight: 600, color: colors.tertiaryText, fontSize: '2rem' }}>
                {fm(`myService.${platform}.tileNotClickableTitle`)}
              </div>
              <div style={{ color: colors.tertiaryText, lineHeight: '1.6rem' }}>
                {fm(`myService.${platform}.tileNotClickableInfo`)}
              </div>
            </>
          ) : dashData.length > 0 ? (
            <TileContentMaker type="recentList" data={dashData?.slice(0, 4)} />
          ) : (
            <Container justifyContent="center" alignItems="center">
              <Item style={{ fontSize: 36, color: colors.lightGray, marginTop: '3.7rem' }}>{fm('general.noData')}</Item>
            </Container>
          )}
        </Item>
      ) : (
        <Item
          xs={8}
          style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
        >
          <div style={{ fontWeight: 600, color: colors.tertiaryText, fontSize: '2rem' }}>
            {fm(!isClickable ? `myService.${platform}.tileNotClickableTitle` : 'myService.sendInvite')}
          </div>
          <div style={{ color: colors.tertiaryText, lineHeight: '1.6rem' }}>
            {fm(!isClickable ? `myService.${platform}.tileNotClickableInfo` : 'myService.sendInviteInfo')}
          </div>
        </Item>
      )}
      <Item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        {!mysqStatus ? (
          <>
            <Error style={{ color: colors.warning.default, margin: '0 0.5rem 0 0.3rem' }} />
            <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>
              {fm('myService.patientNotUsingMyService')}
            </span>
          </>
        ) : (
          <>
            <CheckCircle style={{ color: '#7BA015', margin: '0 0.5rem 0 0.3rem' }} />
            <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>
              {fm(`myService.${mysqStatus}UsingMyService`)}
            </span>
          </>
        )}
      </Item>
    </Container>
  );
};

const MyDash = ({ isClickable }: { isClickable: boolean }): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  return (
    <>
      <Tile id="myService" elevation={1} square={true}>
        <MyServTile isClickable={isClickable} fm={fm} />
      </Tile>
    </>
  );
};

export default MyDash;
