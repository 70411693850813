import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { styled } from '@mui/material/styles';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';
import { ellipsis } from 'Components/DashboardTile/TileContentMaker/components/common/commonStyles';

const MainTitle = styled('div')({
  ...ellipsis(17),
  fontSize: '1.8rem',
  fontWeight: 600,
});

const MainValue = styled('div')({
  fontSize: '1.8rem',
  fontWeight: 600,
});
const Title = styled('div')({
  marginTop: '0.5rem',
});
const Value = styled('div')({
  marginTop: '0.5rem',
});

const makeData = (test: IGeneTest): IData => {
  const commonData = {
    date: isPartialDate(test.date) ? formatPartialDate(test.date) : '',
    key: test._id,
  };

  const testType = test.geneTestType ? <FormattedMessage id={`geneTest.opts.${test.geneTestType}`} /> : null;
  let secondaryTitle: React.JSX.Element | null = null;
  let secondaryValue: React.JSX.Element | null = null;

  if (test.geneTestType === 'singleGeneTest' && test.gene) {
    secondaryTitle = (
      <Title>
        <FormattedMessage id="geneTest.gene" />
      </Title>
    );
    secondaryValue = (
      <Value>
        <FormattedMessage id={`geneTest.opts.${test.gene}`} />
      </Value>
    );
  }

  if (
    test.geneTestType === 'genePanelResearch' &&
    test.panelGeneMutationPairs &&
    test.panelGeneMutationPairs.length > 0
  ) {
    secondaryTitle = (
      <Title>
        <FormattedMessage id="geneTest.numberOfMutations" />
      </Title>
    );
    secondaryValue = <Value>{test.panelGeneMutationPairs.length}</Value>;
  }

  if (test.geneTestType === 'wes') {
    secondaryTitle = (
      <>
        {test.wesGeneMutationPairs && test.wesGeneMutationPairs.length > 0 && (
          <Title>
            <FormattedMessage id="geneTest.numberOfMutations" />
          </Title>
        )}
        {test.wesGeneVusFindingPairs && test.wesGeneVusFindingPairs.length > 0 && (
          <Title>
            <FormattedMessage id="geneTest.numberOfVUS" />
          </Title>
        )}
      </>
    );
    secondaryValue = (
      <>
        {test.wesGeneMutationPairs && test.wesGeneMutationPairs.length > 0 && (
          <Value>{test.wesGeneMutationPairs.length}</Value>
        )}
        {test.wesGeneVusFindingPairs && test.wesGeneVusFindingPairs.length > 0 && (
          <Value>{test.wesGeneVusFindingPairs.length}</Value>
        )}
      </>
    );
  }

  return {
    ...commonData,
    title: testType ? (
      <>
        <MainTitle>{testType}</MainTitle>
        {secondaryTitle || null}
      </>
    ) : (
      ''
    ),
    value: testType ? (
      <>
        <MainValue>&nbsp;</MainValue>
        {secondaryValue || null}
      </>
    ) : (
      ''
    ),
  };
};

const GeneTestDash = ({ documents }: IOwnProps): React.JSX.Element | null => {
  const tests = documents.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

  return <TileContentMaker type="recentListMultipleRows" itemsCanFitAmount={2} data={tests.map((t) => makeData(t))} />;
};

interface IOwnProps {
  documents: IGeneTest[];
}

export default GeneTestDash;
