import * as React from 'react';
import { DesktopTimePicker as MuiTimePicker } from '@mui/x-date-pickers'; // Use DesktopTimepicker specifically to that E2E tests work
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { formatTime, isTime } from 'neuro-utils';
import MyHooks from '../../../utility/customHooks';
import { Container, Item } from '../../Grid';
import ToolTip from '../../ToolTip';
import colors from '../../../config/theme/colors';
import { field, historyValue } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div')({
  width: '100%',
});

const ClearIconStyled = styled(ClearIcon)({
  color: colors.secondaryText,
  paddingTop: '0.8rem',
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const leadingZero = (n?: number): string => ((n || n === 0) && n < 10 ? `0${n}` : n ? `${n}` : '');

const sleep = (milliseconds: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const timeOnChange =
  (name: string, onChange: TOwnProps['onChange']) =>
  (date: unknown): void => {
    const newTime = date as DateTime;
    if (onChange && newTime && newTime.isValid) {
      onChange({ [name]: [newTime.hour, newTime.minute] });
    }
  };

const mapStateToProps = (state: { session: ISessionStore }): IStateToProps => ({
  visitreason: state.session.data?.visitreason ?? '',
});

const TimePicker = ({
  editing = false,
  name,
  placeholder,
  value,
  onChange,
  width,
  disabled,
  visitreason,
  timeDefault,
  isNotCancellable,
  minTime,
}: TOwnProps): React.JSX.Element => {
  const thisDate = new Date();
  const values =
    isTime(value) && (value[0] || value[0] === 0) && (value[1] || value[1] === 0)
      ? [leadingZero(value[0] as number), leadingZero(value[1] as number)]
      : isTime(timeDefault)
        ? [leadingZero(timeDefault[0]), leadingZero(timeDefault[1])]
        : timeDefault === 'now' && !value
          ? [leadingZero(thisDate.getHours()), leadingZero(thisDate.getMinutes())]
          : null;
  const formattedDate = values && DateTime.fromISO(`${values[0]}:${values[1]}`);

  // Save initial time (the time when document is created) after mount
  const mountRoutine = (): void => {
    if (timeDefault) {
      visitreason !== 'retrospectiveDataTransfer' &&
        formattedDate &&
        onChange &&
        onChange({ [name]: [formattedDate?.hour, formattedDate?.minute] });
    }
  };

  MyHooks.useWhenMounted(mountRoutine, []);

  const [clearOpen, setClearOpen] = React.useState<boolean>(false);

  // Handles clicking of the x-button, sets date to undefined
  const handleDeleteClick = (onChange?: TOwnProps['onChange']) => (): void => {
    if (!clearOpen) {
      setClearOpen(true);
      sleep(4000)
        .then(() => {
          setClearOpen(false);
        })
        .catch(() => {
          setClearOpen(false);
        });
    } else if (clearOpen) {
      //setValues(undefined);
      if (onChange) {
        onChange({ [name]: [undefined, undefined] });
      }
      setClearOpen(false);
    }
  };

  const { formatMessage } = useIntl();

  return !editing ? (
    <StyledDiv style={historyValue}>
      {isTime(value) && (value[0] || value[0] === 0) && (value[1] || value[1] === 0) ? formatTime(value as Time) : ''}
    </StyledDiv>
  ) : (
    <Container>
      <Item>
        <MuiTimePicker
          // slots={{
          //   textField: (props: any) => {
          //     const inputProps = mergeDeepRight(props, {
          //       inputProps: {
          //         placeholder: placeholder ? placeholder : formatMessage({ id: 'general.enterTime' }),
          //       },
          //     });
          //     return <TextField style={{ width: width ? `${width}rem` : `${field.width}` }} {...inputProps} />;
          //   },
          // }}
          slotProps={{
            textField: {
              style: { width: width ? `${width}rem` : `${field.width}` },
              placeholder: placeholder ? placeholder : formatMessage({ id: 'general.enterTime' }),
            },
          }}
          ampm={false}
          value={visitreason === 'retrospectiveDataTransfer' ? (value ? formattedDate : null) : formattedDate}
          onChange={timeOnChange(name, onChange)}
          disabled={disabled}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          minTime={minTime ? DateTime.fromJSDate(minTime) : undefined}
        />
      </Item>
      {!isNotCancellable ? (
        <Item style={{ marginLeft: '2rem' }}>
          <ToolTip
            title={formatMessage({ id: 'general.erase' })}
            description={formatMessage({ id: 'general.clearTimeMessage' })}
            content={<ClearIconStyled onClick={handleDeleteClick(onChange)} />}
            open={clearOpen}
          />
        </Item>
      ) : null}
    </Container>
  );
};

const ConnectedTimePicker = connect(mapStateToProps, null)(TimePicker);

interface IStateToProps {
  visitreason?: string;
}
type TOwnProps = IInputBasics & ITimePicker & IStateToProps;

export default ConnectedTimePicker;
