import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  large?: boolean;
  active?: boolean;
  addon?: boolean;
}

export const IconAdverseEffect = ({
  strokeColor,
  strokeWidth,
  fillColor,
  large = false,
  active = false,
  addon = false,
}: IOwnProps): React.JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  return (
    <g onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <circle
        cx={addon ? 7 : 0}
        cy={addon ? 7 : 0}
        r={addon ? 6 : 5.5}
        transform={addon ? undefined : `scale(${large ? (hover ? 1.33 * 1.1 : 1.33) : 1})`}
        stroke={strokeColor}
        strokeWidth={addon ? strokeWidth : strokeWidth * 0.8}
        fill={fillColor}
      />
    </g>
  );
};
