import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

/**
 * Platform checker which checks the condition against the selected platform in session store
 * @returns {React.JSX.Element | null} Child element if platform match, or null if not
 */
const PlatformConditional = ({ platform, children }: IOwnProps): React.JSX.Element | null => {
  const enabledPlatform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  return typeof platform === 'string' ? (
    enabledPlatform === platform ? (
      <>{children}</>
    ) : null
  ) : Array.isArray(platform) ? (
    platform.some((p) => enabledPlatform === p) ? (
      <>{children}</>
    ) : null
  ) : null;
};

interface IOwnProps {
  platform?: Platform | Platform[];
  children: React.JSX.Element | React.JSX.Element[];
}

export default PlatformConditional;
