import { styled } from '@mui/system';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';
import { fm } from 'Components/FormatMessage';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import * as React from 'react';

const MainTitle = styled('div')({
  fontSize: '1.8rem',
  fontWeight: 600,
});
const MainValue = styled('div')({
  fontSize: '1.8rem',
  fontWeight: 600,
});
const Title = styled('div')({
  marginTop: '0.5rem',
});
const Value = styled('div')({
  marginTop: '0.5rem',
});

const ThalamotomyHifuDash = ({ documents }: IOwnProps): React.JSX.Element | undefined => {
  const dashData: IData[] = [];
  documents.forEach((d) => {
    dashData.push({
      date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
      title: (
        <>
          <MainTitle>{fm(`thalamotomyHifu.${d._type}.title`)}</MainTitle>
          <Title>
            {d._type === 'thalamotomy'
              ? fm('thalamotomyHifu.thalamotomy.treatmentType')
              : d._type === 'hifu'
                ? fm('thalamotomyHifu.hifu.treatmentIndication')
                : ''}
          </Title>
          <Title>
            {d._type === 'hifu' && (d as IHifuTreatment).treatmentTarget
              ? fm('thalamotomyHifu.hifu.treatmentTarget')
              : ''}
          </Title>
        </>
      ),
      value: (
        <>
          <MainValue>&nbsp;</MainValue>
          <Value>
            {d._type === 'thalamotomy' && (d as IThalamotomy).typeOfOperation
              ? fm(`thalamotomyHifu.thalamotomy.opts.${(d as IThalamotomy).typeOfOperation}`)
              : d._type === 'hifu' && (d as IHifuTreatment).treatmentIndication
                ? fm(`thalamotomyHifu.hifu.opts.${(d as IHifuTreatment).treatmentIndication}`)
                : '-'}
          </Value>
          <Value>
            {d._type === 'hifu' && (d as IHifuTreatment).treatmentTarget
              ? fm(`thalamotomyHifu.hifu.opts.${(d as IHifuTreatment).treatmentTarget}`)
              : ''}
          </Value>
        </>
      ),
      key: 'thalamotomyHifu',
    });
  });

  return <TileContentMaker type="specificListMultipleRows" itemsCanFitAmount={2} data={dashData} />;
};

interface IOwnProps {
  documents: Array<IThalamotomy | IHifuTreatment>;
}

export default ThalamotomyHifuDash;
