import * as React from 'react';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { useIntl } from 'react-intl';

const ProceduresDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const plasmaChangeDocs = documents
    ?.filter((d) => d?.code?.code === '19647005')
    .sort((d1, d2) => sortPartialDate(d2.date, d1.date));
  const thymectomyDocs = documents
    ?.filter((d) => d?.code?.code === '399750009')
    .sort((d1, d2) => sortPartialDate(d2.date, d1.date));

  return (
    <TileContentMaker
      type="specificListNoDate"
      data={[
        ...(plasmaChangeDocs && plasmaChangeDocs.length > 0
          ? [
              {
                value: isPartialDate(plasmaChangeDocs?.[0]?.date) ? formatPartialDate(plasmaChangeDocs?.[0]?.date) : '',
                title: fm('procedure.latestPlasmaChange'),
                key: 'latestPlasmaChange',
              },
              {
                title: fm('procedure.totalPlasmaChanges'),
                value: plasmaChangeDocs.length,
                key: 'plasmaChangeTotal',
              },
            ]
          : []),
        ...(thymectomyDocs && thymectomyDocs.length > 0
          ? [
              {
                value: isPartialDate(thymectomyDocs?.[0]?.date) ? formatPartialDate(thymectomyDocs?.[0]?.date) : '',
                title: fm('procedure.latestThymectomy'),
                key: 'latestThymectomy',
              },
              {
                title: fm('procedure.totalThymectomies'),
                value: thymectomyDocs.length,
                key: 'thymectomyTotal',
              },
            ]
          : []),
      ]}
    />
  );
};

interface IOwnProps {
  documents: Array<IProcedure>;
}

export default ProceduresDash;
