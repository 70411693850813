import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import colors from '../../../config/theme/colors';
import { styleDocument } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div')({});

const StyledRadio = styled(Radio)({
  padding: '0.4rem 0.75rem 0.4rem 0.75rem !important',
  margin: 0,
});

const StyledLabel = styled('div')({
  fontSize: styleDocument.fontSize,
  color: colors.primaryText,
});

const radioOnChange =
  (onChange: IInputBasics['onChange'], name: string) =>
  (event: { currentTarget: { value: boolean | string } }): void => {
    const value =
      event.currentTarget.value === 'true'
        ? true
        : event.currentTarget.value === 'false'
          ? false
          : event.currentTarget.value;
    onChange && onChange({ [name]: value });
  };

const textElements = (
  value?: string | number | boolean,
  optionFormatter?: (id: string | boolean | number) => React.JSX.Element,
): React.JSX.Element => (
  <StyledDiv>{value !== undefined && optionFormatter ? optionFormatter(value) : value ? value : ''}</StyledDiv>
);

const radioElements = (
  value: IRadioRow['value'],
  options: IRadioRow['options'],
  optionFormatter: IRadioRow['optionFormatter'],
  onChange?: (event: any) => void,
  disabledOptions?: Array<string | number | boolean>,
  hover?: (trueOrFalse: boolean, i: number | string | boolean) => void,
): React.JSX.Element => {
  return (
    <RadioGroup row style={{ marginLeft: '-0.75rem' }}>
      {options.map((o) => (
        <StyledDiv key={`${o}`}>
          <FormControlLabel
            style={{ margin: '0' }}
            control={
              <StyledRadio
                checked={o === value}
                value={o}
                onChange={onChange}
                color="primary"
                disabled={disabledOptions ? disabledOptions.includes(o) : undefined}
              />
            }
            label={<StyledLabel>{optionFormatter ? optionFormatter(o) : o}</StyledLabel>}
            labelPlacement="bottom"
            onMouseEnter={(): void => {
              hover ? hover(true, o) : undefined;
            }}
            onMouseLeave={(): void => {
              hover ? hover(false, o) : undefined;
            }}
          />
        </StyledDiv>
      ))}
    </RadioGroup>
  );
};

const RadioRows = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  disabledOptions,
  hover,
}: IInputBasics & IRadioRow): React.JSX.Element | React.JSX.Element[] =>
  !editing ? (
    <div>{textElements(value, optionFormatter)}</div>
  ) : (
    radioElements(value, options, optionFormatter, radioOnChange(onChange, name), disabledOptions, hover)
  );

export default RadioRows;
