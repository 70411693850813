import * as React from 'react';

import colors from '../../../config/theme/colors';
import { field } from '../../../config/theme/componentTheme';
import Tooltip from '../../ToolTip';
import { styled } from '@mui/system';

const StyledDiv = styled('div')({
  width: '100%',
});

const StyledSelectArea = styled('div')({
  clear: 'both',
});

const StyledSelect = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }: { selected?: true }) => ({
  float: 'left',
  margin: '0.5rem',
  padding: '0.3rem 1.4rem',
  fontSize: '1.6rem',
  borderRadius: `${field.borderRadius}`,
  cursor: 'pointer',
  backgroundColor: selected ? colors.primary : colors.lightestGray,
  color: selected ? colors.white : colors.primaryText,
  fontWeight: selected ? '600' : 'normal',
  border: selected ? 'solid 0.5px #959595' : `solid 0.5px ${colors.primary}`,
  '&:hover': {
    backgroundColor: colors.primary,
    color: colors.white,
  },
}));

const onChangeValues =
  (opt: string | number, name: string, values: Array<string | number>, onChange?: IInputBasics['onChange']) =>
  (): void => {
    // Need to map these values as they need to be separate and somehow they were inhering from newValues to values
    let newValues = values.map((s) => s);
    if (onChange) {
      if (values.filter((v: string | number) => opt === v).length > 0) {
        newValues = newValues.filter((v: string | number) => opt !== v);
      } else {
        newValues.push(opt);
      }
      onChange({ [name]: newValues });
    }
  };

const MultiSelect = ({
  editing = false,
  name,
  value,
  onChange,
  options,
  optionFormatter,
  width,
  autoSelectSingle = false,
  tooltips,
  style,
}: IInputBasics & IMultiSelect): React.JSX.Element => {
  const [values, setValues] = React.useState<string[]>(value === '' ? [] : (value as string[]));

  const isMountRender = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (isMountRender.current) {
      // Automatically select the value if only one value, and prop is enabled
      if (editing && autoSelectSingle && (!value || value.length === 0) && options.length === 1) {
        onChangeValues(options[0], name, values, onChange)();
      }

      isMountRender.current = false;
      return;
    }

    setValues(value === '' ? [] : (value as string[]));
  }, [options, value]);

  return !editing ? (
    <StyledDiv style={{ width: `${width ?? 100}%` }}>{`${value}`}</StyledDiv>
  ) : (
    <StyledSelectArea style={{ width: `${width ?? 100}%` }}>
      {options.map((o: string | number, i: number) => (
        <Tooltip
          key={i}
          title={''}
          description={Array.isArray(tooltips) ? tooltips[i]?.message : undefined}
          content={
            <div style={{ display: 'inline-flex' }}>
              <StyledSelect
                key={o}
                style={style}
                onClick={onChangeValues(o, name, values, onChange)}
                selected={values.filter((v: string) => o === v).length > 0 ? true : undefined}
              >
                {optionFormatter ? optionFormatter(o) : o}
              </StyledSelect>
            </div>
          }
          hover={true}
          disabled={!Array.isArray(tooltips) || !tooltips[i] || tooltips[i].index !== i}
        />
      ))}
    </StyledSelectArea>
  );
};

export default MultiSelect;
