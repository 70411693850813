import { styled } from '@mui/system';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../config/theme/colors';
import CircularProgressLocal from '../CircularProgress';
import { Container, Item } from '../Grid';
import ToolTip from '../ToolTip';

interface IStyleProps {
  width?: number | 'auto';
  height?: number;
  fontSize?: number;
  colorscheme: string;
  alternate?: boolean;
  disabled?: boolean;
  filled?: boolean;
  uppercase?: boolean;
  border?: boolean;
  customButtonStyles?: React.CSSProperties;
}

const colorSchemes: { [key: string]: { [key: string]: string } } = {
  default: {
    text: colors.primary,
    border: colors.primary,
    backgroundHover: colors.primary + '1A',
    backgroundHoverFilled: colors.primary + 'D9',
  },
  dark: {
    text: colors.secondary,
    border: colors.secondary,
    backgroundHover: colors.secondary + '1A',
    backgroundHoverFilled: colors.secondary + 'D9',
  },
  cancel: {
    text: colors.primary,
    border: colors.appBlue.lightest,
    backgroundHover: colors.primary + '1A',
    backgroundHoverFilled: colors.primary + 'D9',
  },
  error: {
    text: colors.error.default,
    border: colors.error.lightest,
    backgroundHover: colors.error.default + '1A',
    backgroundHoverFilled: colors.error.default + 'D9',
  },
  warning: {
    text: colors.warning.dark,
    border: colors.warning.dark,
    backgroundHover: colors.warning.dark + '1A',
    backgroundHoverFilled: colors.warning.dark + 'D9',
  },
};

const dontForwardProps: Array<string | number | symbol> = [
  'height',
  'width',
  'alternate',
  'disabled',
  'colorscheme',
  'filled',
  'fontSize',
  'uppercase',
  'border',
  'customButtonStyles',
];

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => !dontForwardProps.includes(prop),
})(({ height, width, alternate, disabled, colorscheme, filled, border, customButtonStyles }: IStyleProps) => ({
  height: `${height || '5'}rem`,
  width: width === 'auto' ? width : `${width || '25'}rem`,
  border: !border
    ? '1px solid transparent'
    : `1px solid ${alternate ? colors.white : colorSchemes[colorscheme].border}`,
  cursor: disabled === true ? 'default' : 'pointer',
  lineHeight: 1,
  backgroundColor: filled ? colorSchemes[colorscheme].text : 'inherit',
  '&:hover': border
    ? {
        backgroundColor: filled
          ? colorSchemes[colorscheme].backgroundHoverFilled
          : alternate
            ? 'rgba(255, 255, 255, 0.1)'
            : colorSchemes[colorscheme].backgroundHover,
      }
    : undefined,
  padding: width === 'auto' ? '0 2rem' : '0 1rem',
  opacity: disabled === true ? '0.4' : '1',
  boxSizing: 'content-box' as const,
  fontFamily: 'inherit',
  borderRadius: '0.4rem',
  ...customButtonStyles,
}));

const StyledRowItem = styled(Item, {
  shouldForwardProp: (prop) => !dontForwardProps.includes(prop),
})(({ fontSize, filled, colorscheme, alternate, uppercase }: IStyleProps) => ({
  fontSize: `${fontSize || '24'}px`,
  fontWeight: 600,
  color: filled
    ? colors.white
    : alternate
      ? colors.white
      : colorscheme
        ? colorSchemes[colorscheme].text
        : colors.primary,
  textAlign: 'center' as const,
  textTransform: uppercase ? ('uppercase' as const) : undefined,
  userSelect: 'none' as const,
}));

const attachTooltip = (element: React.JSX.Element, tip?: React.JSX.Element): React.JSX.Element => (
  <ToolTip title={tip} content={element} hover={true} />
);

const ButtonElement = ({
  text,
  onClick,
  hover,
  width,
  height,
  fontSize,
  disabled,
  colorScheme = 'default',
  alternate = false,
  loading = false,
  filled = false,
  uppercase = true,
  border = true,
  customButtonStyles,
}: IActionButtonRounded) => (
  <StyledButton
    width={width}
    height={height}
    colorscheme={colorScheme}
    alternate={alternate}
    border={border}
    onClick={onClick && !disabled && !loading ? onClick : undefined}
    disabled={disabled && disabled === true ? true : false}
    onMouseEnter={(): void => {
      hover ? hover(true) : undefined;
    }}
    onMouseLeave={(): void => {
      hover ? hover(false) : undefined;
    }}
    filled={filled}
    customButtonStyles={customButtonStyles}
  >
    <Container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      {loading ? (
        <Item>
          <CircularProgressLocal
            size={(height || 6) / 2 + 'rem'}
            style={{ display: 'block', color: alternate ? colors.white : colors.primary }}
          />
        </Item>
      ) : (
        <StyledRowItem
          xs={12}
          fontSize={fontSize}
          colorscheme={colorScheme}
          alternate={alternate}
          filled={filled}
          uppercase={uppercase}
        >
          {typeof text === 'string' ? <FormattedMessage id={text} /> : text}
        </StyledRowItem>
      )}
    </Container>
  </StyledButton>
);

const ActionButtonRounded = ({ disabled, disabledTooltip, toolTip, ...other }: IActionButtonRounded) => (
  // Need this flex to align tooltips properly
  <div style={{ display: 'flex' }} id={'actionButton'}>
    {disabled && disabledTooltip ? (
      attachTooltip(
        <div>
          <ButtonElement disabled={disabled} {...other} />
        </div>,
        disabledTooltip,
      )
    ) : toolTip ? (
      attachTooltip(
        <div>
          <ButtonElement {...other} />
        </div>,
        toolTip,
      )
    ) : (
      <ButtonElement disabled={disabled} {...other} />
    )}
  </div>
);

export interface IActionButtonRounded {
  text: string | React.JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
  hover?: (trueOrFalse: boolean) => void;
  width?: number | 'auto';
  height?: number;
  fontSize?: number;
  disabled?: boolean;
  disabledTooltip?: React.JSX.Element;
  colorScheme?: 'default' | 'dark' | 'cancel' | 'error' | 'warning';
  alternate?: boolean;
  loading?: boolean;
  filled?: boolean;
  uppercase?: boolean;
  border?: boolean;
  customButtonStyles?: React.CSSProperties;
  toolTip?: React.JSX.Element;
}

export default ActionButtonRounded;
