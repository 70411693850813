import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const RelapseDash = ({ documents }: IOwnProps): React.JSX.Element => {
  const sortedDocuments = documents?.sort((n1, n2) => sortPartialDate(n2.startDate, n1.startDate));

  const lastRelapse: IRelapse = sortedDocuments?.[0] || null;

  const relapses12Months = sortedDocuments.filter((d) => {
    if (!d.startDate || !isPartialDate(d.startDate)) return false;
    const thisDate = new Date();
    const date12MonthsAgo = thisDate.setFullYear(thisDate.getFullYear() - 1);
    return partialDateToValue(d.startDate) > date12MonthsAgo.valueOf();
  });

  return (
    <>
      <TileContentMaker
        type="recentListMultipleRows"
        itemsCanFitAmount={1}
        data={[
          ...(lastRelapse
            ? [
                {
                  date: isPartialDate(lastRelapse.startDate) ? formatPartialDate(lastRelapse.startDate) : '',
                  title: (
                    <div
                      style={{
                        maxHeight: '12rem',
                        overflow: 'hidden',
                      }}
                    >
                      {Array.isArray(lastRelapse?.symptoms) && lastRelapse?.symptoms.length > 0 ? (
                        <>
                          <span style={{ fontWeight: 600 }}>
                            <FormattedMessage
                              id="relapse.additionalSymptoms"
                              values={{ n: Number(lastRelapse.symptoms?.length) }}
                            />
                            :
                          </span>{' '}
                          <span style={{ fontWeight: 400 }}>
                            {lastRelapse.symptoms.map((item, i) => (
                              <React.Fragment key={item}>
                                {fm(`relapse.symptoms.opts.${item}`)}
                                {`${i < (lastRelapse.symptoms?.length || 0) - 1 ? ', ' : ''}`}
                              </React.Fragment>
                            ))}
                          </span>
                        </>
                      ) : null}
                    </div>
                  ),
                  key: 'lastRelapse',
                },
              ]
            : []),
        ]}
      />

      <TileContentMaker
        type="specificListNoDate"
        data={[
          { title: fm(`relapse.relapses12Months`), key: '12months', value: relapses12Months.length },
          { title: fm(`relapse.relapsesAll`), key: 'allRelapses', value: sortedDocuments.length },
        ]}
      />
    </>
  );
};

interface IOwnProps {
  documents: IRelapse[];
}

export default RelapseDash;
