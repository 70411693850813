import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from '../../../components/Grid';
import { isIE } from '../../../utility/isIE';

const IconArea = styled('div')({
  width: '3rem',
  height: '3rem',
});

const mapIconToName = {
  background: { name: 'background', type: 'svg' },
  clinicalstudy: { name: 'clinicalstudy', type: 'svg', sizingFactor: 1 },
  cognition: { name: 'cognition', type: 'svg' },
  comorbidity: { name: 'comorbidity', type: 'svg' },
  dbs: { name: 'dbs', type: 'svg' },
  vns: { name: 'updrs', type: 'svg' },
  diagnosis: { name: 'diagnosis', type: 'svg' },
  examination: { name: 'examination', type: 'svg' },
  generalhealth: { name: 'generalhealth', type: 'svg' },
  geneTest: { name: 'genetest', type: 'svg' },
  imaging: { name: 'imaging', type: 'svg' },
  inflammatoryDiseaseActivity: { name: 'inflammatoryDiseaseActivity', type: 'svg' }, // Same as dbs
  lifestyle: { name: 'lifestyle', type: 'svg' },
  medication: { name: 'medication', type: 'svg' },
  motor: { name: 'motor', type: 'svg' },
  motorFunctionAndMobilityAid: { name: 'motorfunction', type: 'svg' },
  neurologicalStatusAndEDSS: { name: 'neurological', type: 'svg' },
  nutrition: { name: 'nutrition', type: 'svg' },
  profile: { name: 'profile', type: 'svg' },
  pulmonary: { name: 'pulmonary', type: 'svg' },
  relapse: { name: 'relapse', type: 'svg' },
  therapy: { name: 'therapy', type: 'svg' },
  updrs: { name: 'updrs', type: 'svg' },
  seizure: { name: 'seizure', type: 'svg' },
  otherSymptoms: { name: 'generalhealth', type: 'svg' },
  dietTherapy: { name: 'dietTherapy', type: 'svg' },
  functionalPerformance: { name: 'functionalPerformance', type: 'svg' },
  motorFunctionHuntington: { name: 'motorfunctionHuntington', type: 'svg' },
  behaviour: { name: 'behaviour', type: 'svg' },
  surgicalTreatment: { name: 'surgicalTreatment', type: 'svg' },
  graph: { name: 'graph', type: 'svg' },
  clpAndCnpTests: { name: 'clpAndCnpTests', type: 'svg' },
  treatment: { name: 'treatment', type: 'svg' },
  diagnosticSurvey: { name: 'diagnosticSurvey', type: 'svg' },
  checklist: { name: 'checklist', type: 'svg' },
  myService: { name: 'myService', type: 'svg' },
  myServiceV2: { name: 'myServiceV2', type: 'svg', sizingFactor: 0.9 },
  notes: { name: 'notes', type: 'svg' },
  thalamotomyHifu: { name: 'seizure', type: 'svg' },
  rtms: { name: 'clinicalstudy', type: 'svg' },
  tdcs: { name: 'updrs', type: 'svg' },
  doctorsOrders: { name: 'generalhealth', type: 'svg' },
  interviewSurveys: { name: 'interviewSurveys', type: 'svg' },
  functionalPerformanceMgravis: { name: 'functionalPerformance', type: 'svg' },
  procedure: { name: 'surgicalTreatment', type: 'svg' },
  diagnosticTests: { name: 'diagnosticSurvey', type: 'svg' },
} as { [key: string]: { name: string; type: string; sizingFactor?: number } };

const Icon = ({ name }: { name: string }): React.JSX.Element => {
  const image = (svgName?: string): string =>
    require(`./${svgName ? svgName : mapIconToName[name].name}.${mapIconToName[name].type}`);

  const size = `${100 * (mapIconToName[name]?.sizingFactor || 1)}%`;

  if (mapIconToName[name]) {
    return (
      <IconArea>
        <Container justifyContent="center" alignItems="center" style={{ width: 'inherit', height: 'inherit' }}>
          {/** Little trick here to make the icon on top of graph scale properly */}
          <Item style={isIE() && name === 'graph' ? {} : { height: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={image()} style={{ display: 'block', height: size, width: 'auto', maxWidth: '3rem' }} />
          </Item>
        </Container>
      </IconArea>
    );
  } else return <IconArea></IconArea>;
};

export default Icon;
