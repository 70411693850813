import { alpha, Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';

import endDate from '../assets/endDate.svg';
import { IData, TValue } from '../definitions';

const DescriptionText = styled('div')(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.customComponents.tile.fonts.normalText.color,
  marginTop: '2rem',
  padding: '0 0.6rem',
}));

const SubheaderContainer = styled(Container)({
  maxHeight: '4.8rem',
  minHeight: '3.2rem',
  padding: '0 0.6rem 0.3rem 0.6rem',
});

const Subheader = styled('div')(({ theme }) => ({
  fontSize: theme.customComponents.tile.fonts.smallText.size,
  color: theme.customComponents.tile.fonts.smallText.color,
}));

const TileSubheader = ({ title }: { title: IData['title'] }) => {
  return (
    <SubheaderContainer alignContent={'flex-end'}>
      <Item xs={true}>
        <Subheader>{title}</Subheader>
      </Item>
    </SubheaderContainer>
  );
};

const EndingDateContainer = styled(Container)({
  height: '100%',
  fontWeight: 400,
  width: '10rem',
  minWidth: '10rem',
});

const EndIcon = styled('img')({
  paddingRight: '0.6rem',
  marginBottom: '-0.1rem', // Align icon with date text
});

const EndDateText = styled('div')(({ theme }) => ({
  fontSize: theme.customComponents.tile.fonts.smallText.size,
  color: theme.customComponents.tile.fonts.smallText.color,
}));

const EndingDate = ({ date }: { date: string }) => (
  <Container style={{ width: '100%' }} justifyContent="flex-end">
    {/* Align ending date container with the table cell which can be bigger than the fixed width of the ending date */}
    <Item>
      <EndingDateContainer alignItems="baseline" justifyContent="flex-end">
        <Item>
          <EndIcon src={endDate} width={12.75} height={14.17} />
        </Item>
        <Item>
          <EndDateText>{date}</EndDateText>
        </Item>
      </EndingDateContainer>
    </Item>
  </Container>
);

const DocumentLengthContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'fontSize',
})(({ theme, size, fontSize }: { theme?: Theme; size: number; fontSize: number }) => ({
  width: `${size}rem`,
  height: `${size}rem`,
  fontSize: `${fontSize}rem`,
  lineHeight: '1rem',
  fontWeight: 600,
  backgroundColor: theme?.palette.primary.light && alpha(theme?.palette.primary.light, 0.2),
  color: theme?.palette.primary.main,
  border: '1px solid ' + theme?.palette.primary.main,
  borderRadius: 50,
}));

const DocumentLengthWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fontSize' && prop !== 'size',
})(({ theme, fontSize, size }: { theme?: Theme; size: number; fontSize: number }) => ({
  fontSize: `${fontSize}rem`,
  fontWeight: 600,
  backgroundColor: theme?.palette.primary.light && alpha(theme?.palette.primary.light, 0.2),
  color: theme?.palette.primary.main,
  border: '1px solid ' + theme?.palette.primary.main,
  borderRadius: `${fontSize}rem`,
  lineHeight: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${(size - fontSize) / 2}rem`,
}));

const DocumentsLengthIndicator = ({
  length,
  size = 3.1,
  fontSize = 1.6,
  ofX,
}: {
  length: number;
  size?: number;
  fontSize?: number;
  ofX?: number;
}) => {
  return ofX ? (
    <DocumentLengthWrapper fontSize={fontSize} size={size}>
      {length + ' / ' + ofX}
    </DocumentLengthWrapper>
  ) : (
    <DocumentLengthContainer size={size} fontSize={fontSize} alignItems="center" justifyContent="center">
      <Item>{length}</Item>
    </DocumentLengthContainer>
  );
};

const RenderValue = ({ value, endDate }: { value?: TValue; endDate?: string | null }) => (
  <>{endDate ? <EndingDate date={endDate} /> : value || value === 0 ? value : '-'}</>
);

export { TileSubheader, EndingDate, DocumentsLengthIndicator, DescriptionText, RenderValue };
