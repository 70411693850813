import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { calculatePartialDateDiffenceDuration, formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { TDocument } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const formatValue = (value: string): React.JSX.Element => {
  switch (value) {
    case 'veryActiveMS':
      return fm('inflammatoryDiseaseActivity.veryActive');
    case 'activeMS':
      return fm('inflammatoryDiseaseActivity.active');
    case 'stableMS':
      return fm('inflammatoryDiseaseActivity.stable');
    default:
      return fm('inflammatoryDiseaseActivity.opts.unknown');
  }
};

const sortDocumentsAndReturnLast2Years = (documents?: IOwnProps['documents']): TDocument[] | undefined => {
  if (documents) {
    let docs = documents.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
    docs = docs.filter((d) => d.date && calculatePartialDateDiffenceDuration('months', d.date).months <= 24);
    return docs;
  } else return undefined;
};

const InflammatoryDiseaseActivityDash = ({ documents }: IOwnProps): React.JSX.Element => {
  let idaDocs = documents?.filter(
    (d) => d._type === 'inflammatoryDiseaseActivityStart' || d._type === 'inflammatoryDiseaseActivityMonitoring',
  );
  idaDocs = sortDocumentsAndReturnLast2Years(idaDocs);

  return idaDocs && idaDocs?.length > 0 ? (
    <>
      <TileContentMaker
        type="recentList"
        data={idaDocs.map((d) => ({
          date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
          title: '',
          value: d.inflammatoryDiseaseActivity && formatValue(d.inflammatoryDiseaseActivity),
          key: d._id,
        }))}
      />
    </>
  ) : (
    <div style={{ fontSize: '1.6rem' }}>{fm('inflammatoryDiseaseActivity.noData')}</div>
  );
};

interface IOwnProps {
  documents?: Array<TDocument>;
}

export default InflammatoryDiseaseActivityDash;
