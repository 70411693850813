import getCm from '../net/ConnectionManager';

const cm = getCm();

/**
 * Basic JS fetch with some default settings
 * @param {string} url - Url string
 * @param {object} headers - Custom headers
 * @param {IFetchOptions} options - Options and body
 */
export const fetchWithOptions = (url: string, headers: object = {}, options: IFetchOptions = {}): Promise<Response> =>
  cm.fetch(url, {
    method: options.method || 'POST',
    mode: options.mode || 'same-origin',
    cache: options.cache || 'no-store',
    credentials: options.credentials || 'same-origin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    },
    redirect: options.redirect || 'follow',
    referrer: options.referrer || 'client',
    body: options.body,
  });

interface IFetchOptions {
  method?: string;
  mode?: 'same-origin' | 'no-cors' | 'cors';
  cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
  credentials?: 'same-origin' | 'omit' | 'include';
  headers?: any;
  redirect?: 'follow' | 'error' | 'manual';
  referrer?: string;
  body?: string | File;
}
