import { IAddon, IData, IDataPoint, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import {
  dateFromPartial,
  exists,
  formatPartialDate,
  formatTime,
  isPartialDate,
  isTime,
  sortPartialDate,
  sortTime,
} from 'neuro-utils';
import { convertTrendToIcon } from 'Routes/Imaging/utils';
import { getDescription } from '../../../../utility/randomUtil';
import { equals } from 'ramda';
import { calculateBMI, fieldNameToCodeString, isLatestHeightRecent } from 'Routes/Background/utils';
import { IMgravisBackground } from 'neuro-schemas';

export const convertDiagnosisToTimeline = (
  docs: Array<IDiagnosis>,
  platform: Platform | null,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (name: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    if (platform === 'ms') {
      const msTypeDocs = Array.isArray(doc?.typeOfDisease) ? doc?.typeOfDisease : [];
      if (msTypeDocs.some((msTypeDoc) => equals(doc.date, (msTypeDoc ?? {})?.date))) return;
    }
    const dxCode = doc.diagnosis ?? '';
    const title: string = [
      'unspecified',
      'sleepApneaSuspicion',
      'respiratoryFailureSuspicion',
      'radiologicallyIsolatedSyndrome',
      'nonInvasiveNeuroModulationTherapy',
    ].includes(dxCode)
      ? fm(`diagnosis.${dxCode === 'radiologicallyIsolatedSyndrome' ? 'msTitle' : 'opts'}.${dxCode}`)
      : ['unspecifiedDiagnosis'].includes(dxCode)
        ? fm('diagnosis.opts.unspecifiedDiagnosis')
        : dxCode;
    const events: IEvent[] = [];

    // For MGravis G70.0, generate diagnosis entries from formOfDisease items unless no forms exist
    if (
      dxCode === 'G70.0' &&
      Array.isArray(doc.formOfDisease) &&
      doc.formOfDisease.some((form) => form.formOfDisease)
    ) {
      // If diagnosis date is not on the same date as any of the forms of disease, add dg separately
      if (!doc.formOfDisease.some((fod) => equals(fod.date, doc.date))) {
        events.push({
          date: dateFromPartialUpdateTimeframe(doc.date, undefined, true),
          eventType: 'text',
          title: title,
        });
      }
      // Add diagnosis event for each of the forms of disease
      doc.formOfDisease.forEach((form) => {
        form.date &&
          form.formOfDisease &&
          events.push({
            date: dateFromPartialUpdateTimeframe(form.date, undefined, true),
            eventType: 'text',
            title:
              dxCode +
              ` (${
                form.formOfDisease === 'generalized'
                  ? fm('diagnosis.mgravis.formOfDisease.generalizedShort')
                  : form.formOfDisease === 'ocular'
                    ? fm('diagnosis.mgravis.formOfDisease.ocularShort')
                    : ''
              })`,
          });
      });
    } else {
      doc.date &&
        events.push({
          date: dateFromPartialUpdateTimeframe(doc.date, undefined, true),
          eventType: 'text',
          title: title,
          description: doc.diagnosis === 'unspecifiedDiagnosis' ? doc.unspecifiedDetails : undefined,
        });
    }
    addons.push({
      id: 'diagnosis',
      title: fm('diagnosis.title').toLocaleUpperCase(),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertAppointmentToTimeline = (
  docs: Array<IAppointment>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  const addons: Array<IAddon> = [];
  const events: IEvent[] = [];
  docs.forEach((doc: IAppointment) => {
    if (!isPartialDate(doc.startDate)) return;
    const description = [
      {
        title: fm('appointments.startTime'),
        values: formatTime(doc.startTime),
        condition: isTime(doc.startTime),
      },
      {
        title: fm('appointments.status'),
        values: doc.status ? fm(`appointments.opts.${doc.status}`) : undefined,
        condition: exists(doc.status),
      },
      {
        title: fm('appointments.externaAppointmentType'),
        values: doc.externaAppointmentType ?? '-',
        condition: exists(doc.externaAppointmentType),
      },
      {
        title: fm('appointments.ended'),
        values: `${formatPartialDate(doc.endDate ?? undefined)}${
          isTime(doc.endTime) ? ` (${formatTime(doc.endTime)})` : ''
        }`,
        condition: isPartialDate(doc.endDate),
      },
      {
        title: fm('appointments.comments'),
        values: doc.comments ?? '-',
        condition: exists(doc.comments),
      },
    ];
    doc.startDate &&
      events.push({
        date: dateFromPartialUpdateTimeframe(doc.startDate),
        title: fm('appointments.appointment'),
        eventType: 'appointment',
        description: description,
      });
  });
  addons.push({
    id: 'appointments',
    title: fm('appointments.appointments'),
    titleDescription: undefined,
    items: [],
    events: events,
  });
  return events.length > 0 ? addons : undefined;
};

export const convertTherapyToTimeline = (
  docs: Array<ITherapy>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    switch (doc.therapyTypes) {
      case 'physiotherapySessions': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.physiotherapySessions'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.physiotherapySessions'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'respiratoryPhysiotherapySessions': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.respiratoryPhysiotherapySessions'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.respiratoryPhysiotherapySessions'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'ridingTherapy': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.ridingTherapy'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.ridingTherapy'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'homeProgramme': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.homeProgramme'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.homeProgramme'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'hydroTherapyWaterBasedTherapy': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.hydroTherapyWaterBasedTherapy'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.hydroTherapyWaterBasedTherapy'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'ManagementOfContracturesUsingOrthotics': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.ManagementOfContracturesUsingOrthotics'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.ManagementOfContracturesUsingOrthotics'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'brace': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.brace'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.brace'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'OccupationalTherapySessions': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.OccupationalTherapySessions'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.OccupationalTherapySessions'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'SpeechLanguageTherapySessions': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('therapy.opts.SpeechLanguageTherapySessions'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.SpeechLanguageTherapySessions'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'others': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: doc.otherTherapyType
            ? `${fm('therapy.opts.others')}: ${doc.otherTherapyType}`
            : fm('therapy.opts.others'),
        });
        addons.push({
          id: doc.therapyTypes,
          title: fm('therapy.opts.others'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
    }
  });
  return addons.length > 0 ? addons : undefined;
};

const docIsImagingIntegration = (
  d: IImagingIntegrationFields & IControlProps,
): d is IImagingIntegrationFields & IControlProps => {
  if (d.procedureCode || d.statement) return true;
  return false;
};

export const convertImagingToTimeline = (
  docs: Array<
    IDEXA | ICMAP | IMuscleImaging | IScoliosisRontgen | IPelvicRontgen | IMRI | IScintigraphy | ITT | IEchocardiography
  >,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  platform: Platform | null,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc, index, array) => {
    const showProcedureCode = docIsImagingIntegration(doc) && (doc.procedureCode || '').length > 0;

    const showStatement = docIsImagingIntegration(doc) && (doc.statement || '').length > 0;

    switch (doc._type) {
      case 'dexa': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const dexaDoc = doc as IDEXA;
          const events: IEvent[] = [];
          dexaDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(dexaDoc.date),
              eventType: 'study',
              title: fm('imaging.dexa'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: dexaDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: dexaDoc.statement,
                  condition: !!showStatement,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'cmap': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('imaging.cmap'),
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'muscleImaging': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const muscleDoc = doc as IMuscleImaging;
          const events: IEvent[] = [];
          muscleDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(muscleDoc.date),
              eventType: 'study',
              title: fm('imaging.muscleImaging'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: muscleDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: muscleDoc.statement,
                  condition: !!showStatement,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'echocardiography': {
        if (platform === 'dmd') {
          if (!isPartialDate(doc.date)) return undefined;
          const echoDoc = doc as IEchocardiography;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('imaging.echocardiography'),
              description: [
                {
                  title: fm('imaging.lvef'),
                  values: echoDoc.lvef,
                },
                {
                  title: fm('imaging.fs'),
                  values: echoDoc.fs,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'scoliosis': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const scoliosisDoc = doc as IScoliosisRontgen;
          const events: IEvent[] = [];
          scoliosisDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(scoliosisDoc.date),
              eventType: 'study',
              title: fm('imaging.scoliosis'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: scoliosisDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: scoliosisDoc.statement,
                  condition: !!showStatement,
                },
                ...[
                  'proximalThoracicCobb',
                  'thoracicCobb',
                  'thoracolumbarCobb',
                  'kyphosist2t12',
                  'kyphosist5t12',
                  'lordosist12s1',
                ].map((name) => {
                  return {
                    title: fm(`imaging.${name}`),
                    values: exists(scoliosisDoc[name as keyof IScoliosisRontgen])
                      ? `${scoliosisDoc[name as keyof IScoliosisRontgen]}°`
                      : undefined,
                  };
                }),
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'pelvis': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const pelvicDoc = doc as IPelvicRontgen;
          const events: IEvent[] = [];
          pelvicDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(pelvicDoc.date),
              eventType: 'study',
              title: fm('imaging.pelvis'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: pelvicDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: pelvicDoc.statement,
                  condition: !!showStatement,
                },
                {
                  title: fm('imaging.hipSubluxationLuxation'),
                  values: pelvicDoc.hipSubluxationLuxation
                    ? fm(`general.${pelvicDoc.hipSubluxationLuxation}`)
                    : undefined,
                },
                {
                  title: fm('imaging.migrationPercentage'),
                  values: exists(pelvicDoc.migrationPercentage) ? `${pelvicDoc.migrationPercentage} %` : undefined,
                  condition: pelvicDoc.hipSubluxationLuxation === 'yes',
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'mri': {
        const mriDoc = doc as IMRI;
        const events: IEvent[] = [];
        if (platform === 'parkinson') {
          mriDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(mriDoc.date),
              eventType: 'study',
              title: fm('imaging.mri'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: mriDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: mriDoc.statement,
                  condition: !!showStatement,
                },
                {
                  title: fm('imaging.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous'),
                  values: mriDoc.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous
                    ? fm(
                        `imaging.opts.yesFazekas0.${mriDoc.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous}`,
                      )
                    : undefined,
                },
                {
                  title: fm('imaging.wasFazekasClassificationDone'),
                  values: mriDoc.wasFazekasClassificationDone
                    ? fm(`imaging.opts.yesNo.${mriDoc.wasFazekasClassificationDone}`)
                    : undefined,
                  condition: mriDoc.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes',
                },
                {
                  title: fm('imaging.fazekasGrade'),
                  values: mriDoc.fazekasGrade ? fm(`imaging.opts.fazekas.${mriDoc.fazekasGrade}`) : undefined,
                  condition:
                    mriDoc.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes' &&
                    mriDoc.wasFazekasClassificationDone === 'yes',
                },
                {
                  title: fm('imaging.atrophy'),
                  values: mriDoc.atrophy ? fm(`imaging.opts.atrophy.${mriDoc.atrophy}`) : undefined,
                },
                {
                  title: fm('imaging.otherClinicallyRelevantFindingFound'),
                  values: mriDoc.otherClinicallyRelevantFindingFound
                    ? fm(`imaging.opts.yesNo.${mriDoc.otherClinicallyRelevantFindingFound}`)
                    : undefined,
                },
                {
                  title: fm('imaging.whatFinding'),
                  values: mriDoc.otherClinicallyRelevantFinding,
                  condition: mriDoc.otherClinicallyRelevantFindingFound === 'yes',
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        } else if (platform === 'ms') {
          let lesionsKey: 'lesions' | 'lesionsDelta';
          let lesionsTitle = fm('imaging.lesions');
          if (index === array.length - 1) {
            lesionsKey = 'lesions';
          } else {
            lesionsKey = 'lesionsDelta';
            lesionsTitle += ' Δ';
          }
          const hideLesionsTrend = lesionsKey === 'lesions';
          mriDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(mriDoc.date),
              eventType: 'study',
              priority: 'low',
              title: fm('imaging.mri'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: mriDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: mriDoc.statement,
                  condition: !!showStatement,
                },
                { values: hideLesionsTrend ? [lesionsTitle] : [lesionsTitle, fm('imaging.trend')] },
                {
                  title: fm('imaging.brainT2FlairTotalShort'),
                  values: [
                    mriDoc.brainT2Flair?.total?.[lesionsKey],
                    convertTrendToIcon(mriDoc.brainT2Flair?.total?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
                {
                  title: fm('imaging.brainT2FlairInfraShort'),
                  values: [
                    mriDoc.brainT2Flair?.infratentorial?.[lesionsKey],
                    convertTrendToIcon(mriDoc.brainT2Flair?.infratentorial?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
                {
                  title: fm('imaging.brainT2FlairSupraShort'),
                  values: [
                    mriDoc.brainT2Flair?.supratentorial?.[lesionsKey],
                    convertTrendToIcon(mriDoc.brainT2Flair?.supratentorial?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
                {
                  title: fm('imaging.brainT1GadoliniumShort'),
                  values: [
                    mriDoc.brainT1?.gadoliniumEnhanced?.[lesionsKey],
                    convertTrendToIcon(mriDoc.brainT1?.gadoliniumEnhanced?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
                {
                  title: fm('imaging.spinalCordT2TotalShort'),
                  values: [
                    mriDoc.spinalCordT2?.total?.[lesionsKey],
                    convertTrendToIcon(mriDoc.spinalCordT2?.total?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
                {
                  title: fm('imaging.spinalCordT1GadoliniumShort'),
                  values: [
                    mriDoc.spinalCordT1?.gadoliniumEnhanced?.[lesionsKey],
                    convertTrendToIcon(mriDoc.spinalCordT1?.gadoliniumEnhanced?.lesionsTrend) ?? '-',
                  ].slice(0, hideLesionsTrend ? 1 : undefined),
                },
              ],
            });

          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        } else if (platform === 'sma' || platform === 'ninmt') {
          mriDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(mriDoc.date),
              eventType: 'study',
              title: fm('imaging.mri'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: mriDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: mriDoc.statement,
                  condition: !!showStatement,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'scintigraphy': {
        if (platform === 'parkinson') {
          const scintiDoc = doc as IScintigraphy;
          const events: IEvent[] = [];
          scintiDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(scintiDoc.date),
              eventType: 'study',
              title: fm('imaging.scintigraphy'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: scintiDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: scintiDoc.statement,
                  condition: !!showStatement,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'tt': {
        if (platform === 'parkinson') {
          const ttDoc = doc as ITT;
          const events: IEvent[] = [];
          ttDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(ttDoc.date),
              eventType: 'study',
              title: fm('imaging.tt'),
              description: [
                {
                  title: fm('imaging.procedureCode'),
                  values: ttDoc.procedureCode,
                  condition: !!showProcedureCode,
                },
                {
                  title: fm('imaging.statementText'),
                  values: ttDoc.statement,
                  condition: !!showStatement,
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'thymusCt':
      case 'thymusMri': {
        if (platform === 'mgravis') {
          const thymusDoc = doc as IThymusCT | IThymusMRI;
          const events: IEvent[] = [];
          thymusDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(thymusDoc.date),
              eventType: 'study',
              title: fm(`imaging.${doc._type}`),
              description: [
                {
                  title: fm('imaging.finding'),
                  values:
                    Array.isArray(thymusDoc.finding) && thymusDoc.finding.length > 0
                      ? thymusDoc.finding
                          .map((f, i, arr) => `${fm(`imaging.opts.finding.${f}`)}${i < arr.length - 1 ? ', ' : ''}`)
                          .join('')
                      : thymusDoc.finding
                        ? fm(`imaging.opts.finding.${thymusDoc.finding}`)
                        : '-',
                },
              ],
            });
          addons.push({
            id: 'imaging',
            title: fm('imaging.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
    }
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertClinicalStudyToTimeline = (
  docs: Array<IENMG>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  platform: Platform | null,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    switch (doc._type) {
      case 'enmg': {
        if (['sma', 'dmd'].includes(platform || '')) {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.enmg'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'nocturnalPolygraphy': {
        if (['sma', 'dmd'].includes(platform || '')) {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.nocturnalPolygraphy'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'fvc': {
        if (['sma', 'dmd'].includes(platform || '')) {
          const fvcDoc = doc as IFVC;
          if (!fvcDoc) return;
          if (!isPartialDate(fvcDoc.date)) return undefined;
          const events: IEvent[] = [];
          fvcDoc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(fvcDoc.date),
              eventType: 'study',
              title: fm('clinicalstudy.fvc'),
              description: [
                { title: fm('clinicalstudy.fvcLitre'), values: fvcDoc.litres },
                { title: fm('clinicalstudy.fvcPredictedPercentage'), values: fvcDoc.predicted },
                {
                  title: fm('clinicalstudy.sitOrLieDown'),
                  values: fvcDoc.sittingOrLyingDown ? fm(`clinicalstudy.opts.${fvcDoc.sittingOrLyingDown}`) : undefined,
                },
              ],
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'vep': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.vep'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'sep': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.sep'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'baep': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.baep'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'octscan': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.octscan'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'ecg': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.ecg'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'echocardiography': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.echocardiography'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'bloodPressure': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.bloodPressure'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
      case 'heartRate': {
        if (platform === 'ms') {
          if (!isPartialDate(doc.date)) return undefined;
          const events: IEvent[] = [];
          doc.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'study',
              title: fm('clinicalstudy.heartRate'),
            });
          addons.push({
            id: 'clinicalStudy',
            title: fm('clinicalstudy.title'),
            titleDescription: undefined,
            items: [],
            events: events,
          });
        }
        break;
      }
    }

    return;
  });
  if (addons.length > 0) {
    const allEvents: Array<IEvent> = [];
    addons?.forEach((addon) => addon.events?.forEach((event) => allEvents.push(event)));
    const addonsMerged = { ...addons?.[0], events: allEvents } as IAddon;
    return [addonsMerged];
  }
  return undefined;
};

export const convertExitusToTimeline = (
  exitusdata: TExitusUI,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  ageMonths: number,
  fm: (id: string) => string,
): IAddon | undefined => {
  const events: IEvent[] = [];
  const deadAtAge = ageMonths < 60 ? Math.floor(ageMonths) : Math.floor(ageMonths / 12);
  if (exitusdata?.dateOfDeath) {
    events.push({
      date: dateFromPartialUpdateTimeframe(exitusdata.dateOfDeath),
      eventType: 'text',
      title: 'EXITUS',
      description: [
        { title: `${formatPartialDate(exitusdata.dateOfDeath)}` },
        { title: `${fm(`graph.${ageMonths < 60 ? 'ageOfDeathMonths' : 'ageOfDeath'}`)}: ${deadAtAge}` },
      ],
    });
  }
  return events.length > 0
    ? {
        title: '',
        id: 'topdata',
        events: events,
      }
    : undefined;
};

const keyDiagnoses: string[] = ['M41', 'M41.4', 'M40', 'M40.0', 'M40.1', 'M40.2', 'Q65', 'M24.5'];

export const convertComorbidityToTimeline = (
  docs: Array<IComorbidity>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    // If a new key diagnosis needs to be added
    if (doc.classification?.icd10code && new RegExp(keyDiagnoses.join('|')).test(doc.classification?.icd10code)) {
      const events: IEvent[] = [];
      doc.date &&
        events.push({
          date: dateFromPartialUpdateTimeframe(doc.date),
          eventType: 'text',
          title: doc.classification.icd10code,
          description: getDescription(doc.classification?.icd10code).then(
            (v) => `${doc.classification?.icd10code} - ${v}`,
          ),
        });
      addons.push({
        id: 'keyDiagnoses',
        title: fm('comorbidity.keyDiagnoses.title'),
        items: [],
        events: events,
      });
    }

    // If new corset treatments need to be added
    if (doc.corsetTreatments && doc.corsetTreatments.length > 0) {
      const items: IItem[] = [];
      doc.corsetTreatments.forEach((ct) => {
        ct.startDate &&
          items.push({
            start: dateFromPartialUpdateTimeframe(ct.startDate),
            end: ct.endDate ? dateFromPartialUpdateTimeframe(ct.endDate) : undefined,
            title: fm('comorbidity.corsetTreatment'),
            description: [
              {
                title: fm('comorbidity.corsetType'),
                values: ct.corsetType ? fm(`comorbidity.opts.corsetType.${ct.corsetType}`) : undefined,
              },
            ],
          });
      });
      addons.push({
        id: 'corsetTreatment',
        title: fm('comorbidity.corsetTreatment'),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    }

    // If new back surgeries need to be added
    if (doc.backSurgeries && doc.backSurgeries.length > 0) {
      const events: IEvent[] = [];
      doc.backSurgeries.forEach((bs) => {
        bs.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(bs.date),
            eventType: 'treatment',
            title: fm('comorbidity.backSurgery'),
            description: [
              {
                title: fm('comorbidity.surgeryTechnique'),
                values: bs.surgeryTechnique
                  ? fm(`comorbidity.opts.surgeryTechnique.${bs.surgeryTechnique}`)
                  : undefined,
              },
              {
                title: fm('comorbidity.instrumentation'),
                values: bs.instrumentation ? fm(`comorbidity.opts.instrumentation.${bs.instrumentation}`) : undefined,
              },
              {
                title: fm('comorbidity.additionalInformation'),
                values: bs.instrumentationAdditionalInformation,
                condition: bs.instrumentation === 'other',
              },
              { title: fm('comorbidity.surgeryLevel'), values: bs.surgeryLevel },
            ],
          });
      });
      addons.push({
        id: 'backSurgery',
        title: fm('comorbidity.backSurgery'),
        titleDescription: undefined,
        items: [],
        events: events,
      });
    }

    // If new hip surgeries need to be added
    if (doc.hipSurgeries && doc.hipSurgeries.length > 0) {
      const events: IEvent[] = [];
      doc.hipSurgeries.forEach((hs) => {
        hs.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(hs.date),
            eventType: 'treatment',
            title: fm('comorbidity.hipSurgery'),
            description: [
              {
                title: fm('comorbidity.surgeryType'),
                values: hs.surgeryType ? fm(`comorbidity.opts.surgeryType.${hs.surgeryType}`) : undefined,
              },
            ],
          });
      });
      addons.push({
        id: 'hipSurgery',
        title: fm('comorbidity.hipSurgery'),
        titleDescription: undefined,
        items: [],
        events: events,
      });
    }

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertSdmtToGraph = (
  docs: Array<ISDMT>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string, values?: { [key: string]: string }) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const value = d.correctAnswers || d.correctAnswers === 0 ? Number(d.correctAnswers) : undefined;
    if ((value || value === 0) && !Number.isNaN(value)) {
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'sdmt',
        title: value.toString(),
        description: [
          {
            title: fm('cognition.sdmt.correctAnswers'),
            values: <span style={{ fontWeight: 600 }}>{d.correctAnswers ?? '-'}</span>,
            style: { minWidth: '20rem' },
          },
          {
            title: fm('cognition.sdmt.allAnswers'),
            values: d.allAnswers,
          },
          {
            title: fm('cognition.sdmt.testType'),
            values: d.testType ? fm(`cognition.sdmt.${d.testType}`) : undefined,
          },
        ],
        hideValue: true,
      });
    }
  });
  return [
    {
      dataPoints: dataPoints,
      type: 'lineGraph',
      legend: 'SDMT',
      id: 'sdmt',
    },
  ];
};

export const convertMeasurementsToGraph = (
  docs: Array<IMeasurement>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string, values?: { [key: string]: string }) => string,
  type: 'weights' | 'heights' | 'bmi' | 'ybocs',
  measurementDocs?: Array<IMeasurement>, // (Re)declared measurement documents here as this function might be called outside the measurement type case
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];

  const weightCode = fieldNameToCodeString('weight');
  const heightCode = fieldNameToCodeString('height');
  const bmiCode = fieldNameToCodeString('bmi');
  const yboPACode = fieldNameToCodeString('ybopa');
  const yboPTCode = fieldNameToCodeString('ybopt');
  const ybocsCode = fieldNameToCodeString('ybocs');

  if (type === 'bmi') {
    const measurementsData = docs.sort(
      (a, b) => sortPartialDate(b.date, a.date) || sortTime(b.time, a.time),
    ) as IMeasurement[];
    measurementsData.forEach((data) => {
      if (data.code === bmiCode && data.date && data.value) {
        dataPoints.push({
          date: dateFromPartialUpdateTimeframe(data.date),
          value: parseFloat(data.value) || 0,
          id: 'bmi',
          title: fm('background.bmiUnit', { value: data.value }),
        });
      } else if (data.code === weightCode && data.date && data.value) {
        if (dataPoints.find((pt) => equals(pt.date, isPartialDate(data.date) && dateFromPartial(data.date)))) return;
        // Find valid height from this date or before
        const validHeight = measurementsData.find(
          (doc) =>
            doc.code === heightCode &&
            sortPartialDate(data.date, doc.date) >= 0 &&
            isLatestHeightRecent(doc, data.date),
        );
        validHeight &&
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(data.date),
            value: calculateBMI(parseFloat(validHeight.value ?? '0'), parseFloat(data.value)),
            id: 'bmi',
            title: fm('background.bmiUnit', {
              value: `${calculateBMI(parseFloat(validHeight.value ?? '0'), parseFloat(data.value))}`,
            }),
            description: fm('background.bmiCalculated'),
          });
        return;
      }
    });
  } else {
    docs.forEach((d) => {
      if (!isPartialDate(d.date)) return;

      if (d.value && d.date) {
        if (type === 'weights' && d.code === weightCode) {
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(d.date),
            value: parseFloat(d.value) || 0,
            id: 'weight',
            title: fm('background.kg', { value: d.value }),
          });
        } else if (type === 'heights' && d.code === heightCode) {
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(d.date),
            value: parseFloat(d.value) || 0,
            id: 'height',
            title: fm('background.cm', { value: d.value }),
          });
        } else if (type === 'ybocs' && d.code === ybocsCode) {
          const paPoints =
            measurementDocs && measurementDocs.find((m) => m?.code === yboPACode && equals(m.date, d.date))?.value;
          const ptPoints =
            measurementDocs && measurementDocs.find((m) => m?.code === yboPTCode && equals(m.date, d.date))?.value;

          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(d.date),
            value: parseFloat(d.value) || 0,
            id: 'ybocs',
            title: d.value,
            description: (
              <div>
                {paPoints && <div>{fm('graph.ybopa') + ': ' + paPoints}</div>}
                {ptPoints && <div>{fm('graph.ybopt') + ': ' + ptPoints}</div>}
              </div>
            ),
          });
        }
      }
    });
  }
  return [
    {
      dataPoints: dataPoints,
      id: type,
      type: 'lineGraph',
      legend:
        type === 'weights'
          ? fm('background.weight')
          : type === 'heights'
            ? fm('background.height')
            : type === 'bmi'
              ? fm('background.bmi')
              : type === 'ybocs'
                ? fm('graph.ybocsWithScale')
                : '',
    },
  ];
};

export const convertPregnancyToTimeline = (
  docs: Array<IBackground> | Array<IMgravisBackground & IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];

  const pregnancies = docs.find((d) => d.pregnancies && d.pregnancies.length > 0)?.pregnancies;

  pregnancies &&
    pregnancies.forEach((preg) => {
      if (!isPartialDate(preg.startDate)) return;
      const items: IItem[] = [];
      items.push({
        start: dateFromPartialUpdateTimeframe(preg.startDate),
        end: isPartialDate(preg.endDate) ? dateFromPartialUpdateTimeframe(preg.endDate) : undefined,
        title: fm('graph.pregnancy'),
      });
      addons.push({
        id: 'pregnancy',
        title: fm('graph.pregnancy'),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    });
  return addons.length > 0 ? addons : undefined;
};
