import * as React from 'react';
import { IEventStepper } from '../definitions';
import FormRow from 'Components/FormRow';
import { HeaderControls, StandAloneContent, StandAloneHeader } from '.';
import { EventStepperContext } from '..';
import { onChangeEvent } from '../utils';
import EditingEvent from './EditingEvent';
import { omit } from 'Utility/ramdaReplacement';
import { Container, Item } from 'Components/Grid';

const StandAloneView = ({ normalizedProps, propsByType, types }: IOwnProps): React.JSX.Element => {
  const eventStepperContext = React.useContext(EventStepperContext);
  const {
    thisEvents,
    anchor,
    editingEvent,
    setEditing,
    editingIndex,
    setEditingIndex,
    editingEvents,
    editingEventType,
    save,
    toggleMenu,
    openDeleteDialog,
  } = eventStepperContext;

  const isGroup = propsByType && Array.isArray(types) && types.length > 0;

  const propsToUse = (type?: string) => {
    if (!isGroup) return normalizedProps;
    return type ? propsByType[type] : propsByType[types[0]];
  };

  const { viewing, previousEventsTextHeader, latestEventTextHeader } = propsToUse();

  const editing = !!(editingEvent || editingEvent === 0);

  return (
    <FormRow title={latestEventTextHeader ?? previousEventsTextHeader}>
      <StandAloneHeader>
        <Container alignItems="center" style={{ height: '100%' }}>
          <Item xs={6}>{propsToUse(thisEvents[0]._type).stepLabelText(thisEvents?.[0])}</Item>
          <Item xs={6}>
            {!editing && !viewing && (
              <HeaderControls
                index={0}
                type={thisEvents[0]._type}
                setEditing={setEditing}
                toggleMenu={toggleMenu(0)}
                anchor={anchor}
                openDeleteDialog={openDeleteDialog}
              />
            )}
          </Item>
        </Container>
      </StandAloneHeader>
      <StandAloneContent editing={editing && editingEvent === 0}>
        {editingEvent !== 0 ? (
          <div style={{ margin: '' }}>
            {propsToUse(thisEvents[0]._type).stepContent(omit(['_type'], thisEvents[0]))}
          </div>
        ) : (
          <EditingEvent
            thisIndex={editingIndex ?? 0}
            editingElements={propsToUse(editingEventType).editingElements}
            thisOnChange={onChangeEvent(
              editingIndex ?? 0,
              editingEventType ?? propsToUse(editingEventType).name,
              propsToUse(editingEventType).dateFieldName,
              propsToUse(editingEventType).formData,
              editingEvents.current,
              setEditingIndex,
              propsToUse(editingEventType).mutators?.onChangeMutator,
            )}
            save={() => save(editingEventType)}
            saveDisabled={propsToUse(editingEventType).saveDisabled}
            saveDisabledMessage={propsToUse(editingEventType).saveDisabledMessage}
          />
        )}
      </StandAloneContent>
    </FormRow>
  );
};

interface IOwnProps {
  normalizedProps: IEventStepper;
  propsByType?: {
    [key: string]: IEventStepper;
  };
  types?: string[];
}

export default StandAloneView;
